import PageTitle from 'Components/Pagetitle'
import React, {useEffect, useState} from 'react'
import { Row, Tab, Tabs } from 'react-bootstrap'
import Charity from './Charity'
import FreeToPlay from './FreeToPlay'
import PlayToPlay from './PlayToPlay'
import WinnerList from "./FreeToPlay";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import withAuth from "../../withAuth";

const Winners = () => {
    const [data, setData] = useState([]);

    const mapsData = async () => {



        await AuthService.getMethod(ENDPOINT.maps.winners, true)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

    };

    useEffect(() => {
        mapsData();
    }, [
    ]);


    return (
        <>
            <PageTitle title={"Winners"} />

            <section>

                <Tabs
                    defaultActiveKey="Freeplay"
                    transition={false}
                >
                    <Tab  eventKey="Freeplay" title="Free To Play ">
                        <WinnerList data={data.filter(item => ["free to play"].includes(item.map_type))}/>
                    </Tab>
                    <Tab eventKey="PlayToPlay" title="Pay to Play">
                        <WinnerList data={data.filter(item => ["pay to play"].includes(item.map_type))}/>
                    </Tab>
                    <Tab eventKey="charity" title="Charity">
                        <WinnerList data={data.filter(item => ["charity map"].includes(item.map_type))}/>
                    </Tab>
                </Tabs>
            </section>
        </>
    )
}

export default withAuth(Winners)