import Search from 'Components/Search'
import { Button, Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import img from "Images/PrizeMiner.png";
import EditExp from './EditExp';
import EditGems from "../GemsAndDimonds/EditGems";
import {useEffect, useState} from "react";
const EXP = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [row, setRow] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.data){
      setData(props.data)
    }
  }, [props.data]);
  const editRecord = (row) => {
    setRow(row)
    setModalShow(true)
  }
  const updateData = (object) => {
    setData(object)

  }
  const columns = [
    {
      name: "Image",
      selector: (row) => row.Images,
      cell: (row) =>(
          <div className="img-box p-3">
            <img src={img} alt="img" />
          </div>
      ),
    },
    {
      name: "Hours",
      selector: (row) => row.hours,
    },
    {
      name: "EXP Points",
      selector: (row) => row.qty,
    },
    {
      name: "Coin Cost",
      selector: (row) => row.coin_value,
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      cell: row => (
          <ul className={"action-List"}>
            <li><Button  onClick={() => {
              editRecord(row)
            }} variant={"actionBtn text-success"}><i className={"fal fa-pen"}></i> </Button></li>
          </ul>
      ),
    },

  ];

  return (
      <>
        <Row className={'searchbox'}>
          <Col md={12} className={"my-2"}>  <Search placeholder="Search" data={data}  default={props.data} searchColumns={['type','qty','coin_value'
          ]} result={updateData}/></Col>
        </Row>
        <DataTable
            columns={columns}
            data={data}
            pagination
            striped
        />
        {row && <EditExp show={modalShow} data={row} refresh={props.refresh()}  onHide={() => setModalShow(false)}/>}

      </>
  )
}

export default EXP