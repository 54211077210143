import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import BanUserS1 from '../BanUserS1/Index';

const BanUserMadal = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [id, setId] = React.useState(props.id);
    const closePreviousPopup = () => {
        setModalShow(true)
        props.onHide()
    }
    
  return (
    <>
    <Modal
    show={props.show}
    size='md'
    centered
>
    <Modal.Body>
        <Row className='text-center py-3  '>
            <Col>
                <h5 className='mb-4'>Ban This Account?</h5>
                <Button onClick={props.onHide} variant={"outline-success"} className="me-3 px-5">No</Button>
                <Button className='btn  btn-delete px-5'  onClick={closePreviousPopup}>Yes</Button>
            </Col>
        </Row>
    </Modal.Body>
</Modal>

        {id && <BanUserS1 show={modalShow} id={id} onHide={() => setModalShow(false)}/>}

</>

  );
}

export default BanUserMadal