import {Direction} from "react-data-table-component";
import {useCallback, useEffect, useRef, useState} from "react";
import Grid, {useEditable, useSelection, useSizer, Cell as DefaultCell} from "react-konva-grid";
import {Image, Layer, Rect, Transformer, Text, Stage} from "react-konva";
import {toast} from "react-toastify";
import classes from "../index.module.scss"
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import {useNavigate} from "react-router-dom";

export const EditableGridLayerTwoThree: React.FC = (props) => {
    const width = 980;
    const height = 700;
    const navigate = useNavigate();

    const [zoomLevel, setZoomLevel] = useState(100);

    const [mapData, setMapData] = useState({});
    const [data, setData] = useState(new Map());
    const [layerHistory, setLayerHistory] = useState(new Map());
    const [row, setRow] = useState(20);
    const [col, setCol] = useState(20);
    const [layerName, setLayerName] = useState(props.layerName);
    const [currentLayer, setCurrentLayer] = useState(props.layer);
    const [assetRecord, setAssetRecord] = useState({});
    const [tool, setTool] = useState({});
    const [rewardTool, setRewardTool] = useState([]);
    const [mapRandomRewards, setMapRandomRewards] = useState({coins:0,gems:0,diamonds:0,layer:currentLayer,mapTools:[]});
    const [defaultLayerColor, setDefaultLayerColor] = useState("");
    const [colorObject, setColorObject] = useState({
        Mud: {background: "#41322B", textColor: "#6B4F4F"},
        Sand: {background: "#FFC961", textColor: "#6B4F4F"},
        Water: {background: "#3080AE", textColor: "#15425C"},
        Coal: {background: "#403E56", textColor: "#767676"},
        Lava: {background: "#FF6736", textColor: "#292929"},
        Limestone: {background: "#78B429", textColor: "#588819"},
        Salt: {background: "#DFDFDF", textColor: "#8D8181"},
        "Black rock": {background: "#000000", textColor: "#5B5B5B"},
        Diamond: {background: "#85E2FF", textColor: "#316E82"},
        Iron: {background: "#AEAEAE", textColor: "#747420"},
        Bones: {background: "#ABAB55", textColor: "#747420"},
        Sandstone: {background: "#FFB178", textColor: "#A45B26"},
        Gold: {background: "#FFD600", textColor: "#292929"},
        Silver: {background: "#F1F1F1", textColor: "#292929"},
        Obsidian: {background: "#6F6F6F", textColor: "#292929"},
        Quartz: {background: "#DADADA", textColor: "#666666"},
        Marble: {background: "#FFC692", textColor: "#AE7642"},
        Basalt: {background: "#B7B7B7", textColor: "#696969"},
        brown: {background: "#221813", textColor: "#696969"},
        Tool: {background: "#221813", textColor: "#696969"},
        "light brown": {background: "#8A613C", textColor: "#292929"},
    });

    const handleSubmit = async (draft) => {
        props.setLoading(true)
        // convert the map to a 2D array
        const size = mapData.map_size.split("x");
        const numRows = size[0];
        const numCols = size[1];
        const tableArray = [];
        for (let row = 0; row < numRows; row++) {
            let rowArray = [];
            for (let col = 0; col < numCols; col++) {
                rowArray.push(data.get(row + "," + col));
            }
            tableArray.push(rowArray);
        }


        let mapRewards = {};
        let mapRewardsArray =  [];


            mapRewards.coins = mapRandomRewards?.coins ?? 0;
            mapRewards.gems = mapRandomRewards?.gems ??0;
            mapRewards.diamonds = mapRandomRewards?.diamonds ??0;
            mapRewards.is_random = mapRandomRewards?.is_random ?? false;
            mapRewards.layer = layerName;
            mapRewards.mapTools = mapRandomRewards.mapTools?mapRandomRewards.mapTools.concat(rewardTool) : rewardTool
        if(mapData.mapRewards){
            mapRewardsArray = mapData.mapRewards.filter(item => item.layer !== layerName)
        }
        mapRewardsArray.push(mapRewards)
        mapData.mapAllowedTools = []
        mapData.map_layer_data = tableArray
        mapData.mapRewards = mapRewardsArray
        ENDPOINT.maps.edit.id = mapData.id;

        return await AuthService.patchMethod(ENDPOINT.maps.edit.url + ENDPOINT.maps.edit.id, true, mapData)
            .then((res) => {
                props.setLoading(false)

                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 200) {


                    if (draft === 'true') {
                        toast("Maps updated successfully!")

                        setTimeout(() => {

                            navigate('/maps');

                        }, 1000);
                    } else if (draft === 'false') {
                        props.onSubmit(res.data)
                    }
                }
            })
            .catch((err) => {


                console.log('errors')
                console.log(err)
                toast(err.response.data.errors[0])
            });
    }

    function undoSelections() {
        if (layerHistory) {
            const keysArray = Array.from(layerHistory.keys());
            const lastKey = keysArray.slice(-1)[0];

            const keysToUpdate = layerHistory.get(lastKey);

            if (keysToUpdate) {
                keysToUpdate.forEach(({row, col}) => {
                    if (data.get(`${row},${col}`)[currentLayer]?.assets?.name.includes("(Tool)")) {
                        rewardTool.pop()
                    }
                    data.get(`${row},${col}`)[currentLayer].assets = {
                        "id": "029c82b8-1a68-476a-9fd1-5468910542fb",
                        "createdDate": "2023-01-31T08:00:48.699Z",
                        "updatedDate": "2023-01-31T08:00:48.699Z",
                        "name": defaultLayerColor,
                        "version": '#BFC1BF',
                        "color": "#221813",
                        "asset_type": "substrates",
                        "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                        "strength": 2,
                        "covered_area": "1x1",
                        "experience_gained": 10,
                        "coin_gained": 1,
                        "stake": true,
                        "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                        "assetActions": [


                            {


                                "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                "createdDate": "2023-02-02T08:17:58.784Z",


                                "updatedDate": "2023-02-02T08:17:58.784Z",


                                "action": "SMASH"


                            },


                            {


                                "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                "createdDate": "2023-02-02T08:17:58.784Z",


                                "updatedDate": "2023-02-02T08:17:58.784Z",


                                "action": "DIG"


                            }


                        ],
                        "imageEntity": {


                            "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                            "createdDate": "2023-01-31T07:59:40.238Z",


                            "updatedDate": "2023-01-31T07:59:40.238Z",


                            "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                            "size": 166810,


                            "mimetype": "image/jpeg",


                            "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                            "metaData": null


                        },
                        "assetAllowedLayers": [


                            {


                                "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                "createdDate": "2023-02-02T08:17:58.793Z",


                                "updatedDate": "2023-02-02T08:17:58.793Z",


                                "layer": 1


                            }


                        ]


                    };
                });
                layerHistory.delete(lastKey)
            }


        }
    }

    useEffect(() => {

        if (props.data) {
            setMapData(props.data)
        }

    }, [props.data])

    useEffect(() => {

        if (props.mapRandomRewards) {
            setMapRandomRewards(props.mapRandomRewards)
        }

    }, [props.mapRandomRewards])

    useEffect(() => {

        if (props.defaultColor) {
            setDefaultLayerColor(props.defaultColor)
        }

    }, [props.defaultColor])

    useEffect(() => {

        if (props.asset) {
            setAssetRecord(props.asset)
        }

    }, [props.asset])

    useEffect(() => {

        if (props.tool) {
            setTool(props.tool)
        }

    }, [props.tool])
    useEffect(() => {

        if (props.undo) {
            undoSelections()
        }

    }, [props.undo])
    useEffect(() => {

        if (props.draft) {
            handleSubmit(props.draft)
        }

    }, [props.draft])
    useEffect(() => {

        if (props.zoomLevel) {
            setZoomLevel(props.zoomLevel)
        }

    }, [props.zoomLevel])
    useEffect(() => {

        const tableArray = mapData.map_layer_data;
        const size = mapData?.map_size?.split("x");
        const numRows = mapData?.map_size && size[0];
        const numCols = mapData?.map_size && size[1];
        setRow(numRows)
        setCol(numCols)
        console.log('tableArray')
        console.log(tableArray)
        if (mapData?.map_size && tableArray && tableArray.length > 0) {
            for (let i = 0; i < numRows * numCols * 4; i++) {
                // let mapRow = Math.floor(i / (4 * numCols));
                // let mapCol = Math.floor((i / 4) % numCols);
                for (let mapRow = 0; mapRow < numRows; mapRow++) {
                    for (let mapCol = 0; mapCol < numCols; mapCol++) {
                        // console.log('tableArrayData')

                        // console.log(tableArray[mapRow].value)

                        data.set(mapRow + "," + mapCol, tableArray[mapRow][mapCol]);
                    }
                }
            }
        }
        console.log('map grid')
        console.log(data)

    }, [mapData])

    const gridSize = Math.min(width / col, height / row);

    const getXLabels = () => {
        const labels = [];

        for (let x = 0; x < col; x++) {
            labels.push(
                <Text
                    key={`x-label-${x}`}
                    x={x * zoomLevel}
                    y={height -20}
                    text={x + 1}
                    fontSize={14}
                    fontStyle="bold"
                    align="center"
                />
            );
        }

        return labels;
    };
    const getYLabels = () => {
        const labels = [];

        for (let y = 0; y < row; y++) {
            labels.push(
                <Text
                    key={`y-label-${y}`}
                    x={50}
                    y={y * zoomLevel}
                    text={y + 1}
                    fontSize={14}
                    fontStyle="bold"
                    align="center"
                    rotation={-90}
                />
            );
        }

        return labels;
    };
    const [selectedCell, setSelectedCell] = useState(null);

    const Resizable = ({scrollTop, scrollLeft, isSelected, onSelect, color}) => {
        const trRef = useRef(null);
        const shapeRef = useRef();
        useEffect(() => {
            if (!isSelected) return;
            trRef.current.attachTo(shapeRef.current);
            trRef.current.getLayer().batchDraw();
        }, [isSelected]);
        const [dimensions, setDimensions] = useState({
            x: 100,
            y: 100,
            width: 100,
            height: 100,
        });
        const x = dimensions.x - scrollLeft;
        const y = dimensions.y - scrollTop;
        return (
            <>
                <Rect
                    onMouseDown={onSelect}
                    ref={shapeRef}
                    draggable
                    onDragEnd={(e) => {
                        setDimensions((prev) => {
                            return {
                                ...prev,
                                x: e.target.x() + scrollLeft,
                                y: e.target.y() + scrollTop,
                            };
                        });
                    }}
                    onTransformEnd={(e) => {
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);
                        setDimensions({
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(node.height() * scaleY),
                        });
                    }}
                    fill={color}
                    stroke="#000"
                    strokeWidth={1}
                    x={x}
                    y={y}
                    width={dimensions.width}
                    height={dimensions.height}
                    image={"https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"}
                />
                <Image
                    x={x}
                    y={y}
                    width={dimensions.width}
                    height={dimensions.height}
                    image={"https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"}
                />

                {isSelected && (
                    <Transformer
                        ref={trRef}
                        rotateEnabled={false}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </>
        );
    };


    const Heatmap = () => {

        const rowCount = row;
        const columnCount = col;
        const gridRef = useRef(null);
        const allowMultipleSelection = true;
        const persistantSelectionMode = true;
        const allowDeselectSelection = true;
        const cellRenderer = (rowIndex, columnIndex, cellData, x, y, width, height) => {
            return (
                <Text text={`${rowIndex + 1}`} x={x + 5} y={y + 5}/>
            );
        }
        const getCellValue = useCallback(
            ({rowIndex, columnIndex}) => data.get(rowIndex + "," + columnIndex),
            [data]
        );
        const {
            activeCell,
            selections,
            setActiveCell,
            ...selectionProps
        } = useSelection({
            gridRef,
            rowCount,
            columnCount,
            allowMultipleSelection,
            persistantSelectionMode,
            allowDeselectSelection,
            onFill: (activeCell, fillSelection) => {

                // if (!fillSelection) return;
                const {bounds} = fillSelection;


                let array = [];
                for (let i = bounds.top; i <= bounds.bottom; i++) {
                    for (let j = bounds.left; j <= bounds.right; j++) {
                        if (assetRecord) {
                            var uniqueId = Math.random();
                            data.get(i + "," + j)[currentLayer].uniqueId = uniqueId.toString();
                            data.get(i + "," + j)[currentLayer].assets = assetRecord;
                            array.push({row: i, col: j})
                        }

                    }
                }
                if (array.length > 0) {
                    props.addToConsole('You have added multiple ' + assetRecord.name)

                    layerHistory.set(layerHistory.size === 0 ? 0 : layerHistory.size + 1, array)
                }


                // setData(data)
                // setData((prev) => ({...prev, ...changes}));
            },
        });
        // const { getTextMetrics, ...autoSizerProps } = useSizer({
        //     gridRef,
        //     getValue: getCellValue,
        //     resizeStrategy: "lazy",
        //     rowCount,
        //     autoResize: false,
        // });
        const [isSelected, setIsSelected] = useState(false);
        const handleMouseDown = (e) => {
            // Handle it better, maybe using `ids`
            console.log('mouse mouse')
            console.log(e)
            const clickedOnEmpty = e.target.attrs.draggable !== true;
            if (clickedOnEmpty) setIsSelected(false);
        };
        const {editorComponent, isEditInProgress, ...editableProps} = useEditable(
            {
                gridRef,
                getValue: getCellValue,
                selections,
                getEditor: ({rowIndex, columnIndex}) => {
                    let array = [];
                    if (tool?.tool) {
                        data.get(rowIndex + "," + columnIndex)[currentLayer].uniqueId = uniqueId.toString();
                        data.get(rowIndex + "," + columnIndex)[currentLayer].assets = {
                            "id": "029c82b8-1a68-476a-9fd1-5468910542fb",
                            "createdDate": "2023-01-31T08:00:48.699Z",
                            "updatedDate": "2023-01-31T08:00:48.699Z",
                            "name": tool.tool.name + '(Tool)',
                            "version": '#BFC1BF',
                            "color": "#221813",
                            "asset_type": "substrates",
                            "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                            "strength": 2,
                            "covered_area": "1x1",
                            "experience_gained": 10,
                            "coin_gained": 1,
                            "stake": true,
                            "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                            "assetActions": [


                                {


                                    "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                    "createdDate": "2023-02-02T08:17:58.784Z",


                                    "updatedDate": "2023-02-02T08:17:58.784Z",


                                    "action": "SMASH"


                                },


                                {


                                    "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                    "createdDate": "2023-02-02T08:17:58.784Z",


                                    "updatedDate": "2023-02-02T08:17:58.784Z",


                                    "action": "DIG"


                                }


                            ],
                            "imageEntity": {


                                "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                                "createdDate": "2023-01-31T07:59:40.238Z",


                                "updatedDate": "2023-01-31T07:59:40.238Z",


                                "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                                "size": 166810,


                                "mimetype": "image/jpeg",


                                "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                                "metaData": null


                            },
                            "assetAllowedLayers": [


                                {


                                    "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                    "createdDate": "2023-02-02T08:17:58.793Z",


                                    "updatedDate": "2023-02-02T08:17:58.793Z",


                                    "layer": 1


                                }


                            ]


                        };

                        props.addToConsole('You have added tool ' + tool.tool.name)

                        rewardTool.push({
                            "tool_id": tool.tool.id,
                            "qty": tool.qty,
                            "coordinates": rowIndex + "x" + columnIndex
                        })
                        setRewardTool(rewardTool)
                        console.log(rewardTool)
                        array.push({row: rowIndex, col: columnIndex})
                    } else if (assetRecord) {
                        var uniqueId = Math.random();
                        data.get(rowIndex + "," + columnIndex)[currentLayer].uniqueId = uniqueId.toString();
                        data.get(rowIndex + "," + columnIndex)[currentLayer].assets = assetRecord;

                        array.push({row: rowIndex, col: columnIndex})
                        props.addToConsole('You have added ' + assetRecord.name)

                    }
                    if (array.length > 0) {
                        console.log('layerHistory.size2')
                        console.log(layerHistory.size)
                        console.log(array)
                        layerHistory.set(layerHistory.size + 1, array)
                    }

                },
                activeCell,
                onDelete: (activeCell, selections) => {
                    if (selections.length) {
                        const newValues = selections.reduce((acc, {bounds: sel}) => {
                            for (let i = sel.top; i <= sel.bottom; i++) {
                                for (let j = sel.left; j <= sel.right; j++) {
                                    acc[[i, j]] = "";
                                }
                            }
                            return acc;
                        }, {});
                        console.log('sdas')
                        // setData((prev) => ({...prev, ...newValues}));
                        const selectionBounds = selections[0].bounds;

                        gridRef.current.resetAfterIndices(
                            {
                                columnIndex: selectionBounds.left,
                                rowIndex: selectionBounds.top,
                            },
                            true
                        );
                    } else if (activeCell) {
                        // setData((prev) => {
                        //     return {
                        //         ...prev,
                        //         [[activeCell.rowIndex, activeCell.columnIndex]]: "",
                        //     };
                        // });
                        gridRef.current.resetAfterIndices(activeCell);
                    }
                },
                onBeforeEdit: ({rowIndex, columnIndex}) => {
                    let array = [];
                    var uniqueId = Math.random();

                    if (tool?.tool) {
                        console.log('tool')
                        data.get(rowIndex + "," + columnIndex)[currentLayer].uniqueId = uniqueId.toString();
                        data.get(rowIndex + "," + columnIndex)[currentLayer].assets = {
                            "id": "029c82b8-1a68-476a-9fd1-5468910542fb",
                            "createdDate": "2023-01-31T08:00:48.699Z",
                            "updatedDate": "2023-01-31T08:00:48.699Z",
                            "name": tool.tool.name + '(Tool)',
                            "version": '#BFC1BF',
                            "color": "#221813",
                            "asset_type": "substrates",
                            "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                            "strength": 2,
                            "covered_area": "1x1",
                            "experience_gained": 10,
                            "coin_gained": 1,
                            "stake": true,
                            "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                            "assetActions": [


                                {


                                    "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                    "createdDate": "2023-02-02T08:17:58.784Z",


                                    "updatedDate": "2023-02-02T08:17:58.784Z",


                                    "action": "SMASH"


                                },


                                {


                                    "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                    "createdDate": "2023-02-02T08:17:58.784Z",


                                    "updatedDate": "2023-02-02T08:17:58.784Z",


                                    "action": "DIG"


                                }


                            ],
                            "imageEntity": {


                                "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                                "createdDate": "2023-01-31T07:59:40.238Z",


                                "updatedDate": "2023-01-31T07:59:40.238Z",


                                "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                                "size": 166810,


                                "mimetype": "image/jpeg",


                                "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                                "metaData": null


                            },
                            "assetAllowedLayers": [


                                {


                                    "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                    "createdDate": "2023-02-02T08:17:58.793Z",


                                    "updatedDate": "2023-02-02T08:17:58.793Z",


                                    "layer": 1


                                }


                            ]


                        };


                        rewardTool.push({
                            "tool_id": tool.tool.id,
                            "qty": tool.qty,
                            "coordinates": rowIndex + "x" + columnIndex
                        })
                        setRewardTool(rewardTool)
                        props.addToConsole('You have added tool ' + tool.tool.name)

                        console.log(rewardTool)
                        array.push({row: rowIndex, col: columnIndex})
                    } else if (assetRecord) {
                        data.get(rowIndex + "," + columnIndex)[currentLayer].uniqueId = uniqueId.toString();
                        data.get(rowIndex + "," + columnIndex)[currentLayer].assets = assetRecord;
                        array.push({row: rowIndex, col: columnIndex})
                        props.addToConsole('You have added ' + assetRecord.name)

                    }
                    if (array.length > 0) {
                        console.log('layerHistory.size1')
                        console.log(layerHistory.size)
                        console.log(array)

                        layerHistory.set(layerHistory.size === 0 ? 0 : layerHistory.size + 1, array)
                    }
                },

                onSubmit: (value, {rowIndex, columnIndex}, nextActiveCell) => {
                    // setData((prev) => ({...prev, [[rowIndex, columnIndex]]: value}));
                    gridRef.current.resizeColumns([columnIndex]);

                    /* Select the next cell */
                    if (nextActiveCell) {
                        setActiveCell(nextActiveCell);
                    }
                },
            }
        );

        return (
            <div className={classes.mapContainer} id={layerName === 2?"myDiv2":"myDiv1"}>
                {/*<div width={window.innerWidth} height={window.innerHeight} ref={stageRef}>*/}

                {/*    {Array(row)*/}
                {/*        .fill()*/}
                {/*        .map((_, i) => (*/}
                {/*            <Text key={`row-${i}`} text={`${i + 1}`} x={10} y={i  + 30} />*/}
                {/*        ))}*/}

                {/*    /!* Show labels on the top *!/*/}
                {/*    {Array(col)*/}
                {/*        .fill()*/}
                {/*        .map((_, i) => (*/}
                {/*            <Text key={`col-${i}`} text={`${i + 1}`} x={i + 30} y={10} />*/}
                {/*        ))}*/}
                <Grid
                    width={width}
                    height={height}
                    columnCount={col}
                    rowCount={row}
                    cellPadding={5} // add 5 pixels of padding around each cell

                    ref={gridRef}
                    activeCell={activeCell}
                    selections={selections}
                    columnWidth={(index) => {
                        return zoomLevel;
                    }}
                    x={100}
                    y={100}

                    // showFillHandle={!isEditInProgress}

                    itemRenderer={(props) => (
                        // console.log(props)
                        <>
                            {/*<Text text={'TEST'} x={props.rowIndex + 50} y={props.columnIndex + 50} />*/}

                            {/*<Text*/}
                            {/*    key={`y-label-${props.columnIndex}`}*/}
                            {/*    x={30}*/}
                            {/*    y={props.columnIndex * zoomLevel}*/}
                            {/*    text={props.columnIndex + 1}*/}
                            {/*    fontSize={14}*/}
                            {/*    fontStyle="bold"*/}
                            {/*    align="center"*/}
                            {/*    rotation={-90}*/}
                            {/*/>*/}
                            {/*<Text*/}
                            {/*    key={`x-label-${props.rowIndex}`}*/}
                            {/*    x={props.rowIndex * zoomLevel}*/}
                            {/*    y={20}*/}
                            {/*    text={props.rowIndex + 1}*/}
                            {/*    fontSize={14}*/}
                            {/*    fontStyle="bold"*/}
                            {/*    align="center"*/}
                            {/*/>*/}

                            <DefaultCell

                                fill={data.get(props.rowIndex + "," + props.columnIndex) && (colorObject[data.get(props.rowIndex + "," + props.columnIndex)[currentLayer]?.assets?.name]?.background ?? colorObject[defaultLayerColor].background)}
                                value={data.get(props.rowIndex + "," + props.columnIndex) && (data.get(props.rowIndex + "," + props.columnIndex)[currentLayer]?.assets.name === 'brown' || data.get(props.rowIndex + "," + props.columnIndex)[currentLayer]?.assets.name === 'light brown' ? "" : data.get(props.rowIndex + "," + props.columnIndex)[currentLayer]?.assets.name)}

                                // value={[props.rowIndex, props.columnIndex]}
                                align="center"
                                textColor={data && data.get(props.rowIndex + "," + props.columnIndex) && (colorObject[data.get(props.rowIndex + "," + props.columnIndex)[currentLayer]?.assets?.name]?.textColor ?? colorObject[defaultLayerColor].textColor)}
                                {...props}
                            />
                        </>


                    )}
                    rowHeight={(index) => {
                        return zoomLevel;
                    }}
                    {...selectionProps}
                    {...editableProps}

                    onKeyDown={(...args) => {
                        selectionProps.onKeyDown(...args);
                        editableProps.onKeyDown(...args);
                    }}
                    onMouseDown={(...args) => {
                        selectionProps.onMouseDown(...args);
                        editableProps.onMouseDown(...args);
                    }}
                >
                    {({scrollTop, scrollLeft}) => {
                        return (

                            <Layer>

                                <Rect x={0} y={0} width={width} height={height} stroke='black' strokeWidth={1}/>
                                { /* Add x-axis component */}
                                {/*<Text {...xAxisProps} />*/}
                                {/*<Text {...yAxisProps} />*/}


                                { /* Add y-axis component */}
                                {/*{isBlue &&*/}
                                {/*    <Resizable*/}
                                {/*        scrollTop={scrollTop}*/}
                                {/*        scrollLeft={scrollLeft}*/}
                                {/*        isSelected={isSelected}*/}
                                {/*        onSelect={() => {*/}
                                {/*            setIsSelected(true);*/}
                                {/*        }}*/}
                                {/*        color={'lightblue'}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*{isRed &&*/}
                                {/*    <ResizableRed*/}
                                {/*        scrollTop={scrollTop}*/}
                                {/*        scrollLeft={scrollLeft}*/}
                                {/*        isSelected={isSelected}*/}
                                {/*        onSelect={() => {*/}
                                {/*            setIsSelected(true);*/}
                                {/*        }}*/}
                                {/*        color={'#FF1919'}*/}

                                {/*    />*/}
                                {/*}*/}
                                {/*{getXLabels()}*/}
                                {/*{getYLabels()}*/}
                            </Layer>
                        );
                    }}
                </Grid>

                {/*{editorComponent}*/}
                {/*</div>*/}
            </div>
        );
    };

    return <Heatmap/>;
};
