import PageTitle from 'Components/Pagetitle'
import React, {useState} from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import upload from "Images/arrowUp.png";
import { useNavigate } from 'react-router-dom';
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import {toast} from "react-toastify";
import withAuth from "../../../withAuth";

const AddExchange = () => {
    const navigate = useNavigate();
    const AddMusic = () => {
        navigate('/ads');
    };
    const [formData, setFormData] = useState({});
    const validateUrl = (url) => {
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        return urlPattern.test(url);
    }
    const isNumberKey = (evt) =>{
        let value = evt.target.value;
        let name = evt.target.name;
        const re = /^[0-9]*\.?[0-9]*$/;
        if (re.test(evt.target.value))
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })

    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'link') {
            // Validate the value as a URL
            const isValidUrl = validateUrl(value);

            if (!isValidUrl) {
                toast('Invalid url')
                return;
            }
        }

        setFormData((prevValue) => ({
            ...prevValue,
            [name]: value
        }));
    }
    const handleSubmit = async () => {

        if (!formData.link || !formData.ad_from || !formData.gems){
            toast("Fill all fields to continue!")
            return ;
        }
        return await AuthService.postMethod(ENDPOINT.ads.add_ads, true, formData)
            .then((res) => {

                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 201) {
                    toast("Ads added successfully!")
                    AddMusic()
                }
            })
            .catch((err) => {


                console.log('errors')
                toast(err.response.data.errors[0])
            });
    }

    return (
        <>

            <PageTitle title="Ads Exchange" />
            <section>
                <Button onClick={AddMusic} variant={"transparent font-18 fw-bold ps-0"}><i className={"fas fa-arrow-left  mx-2"}></i>Add Ads Exchange</Button>

                <Row className={'pt-4'} style={{
                    height: "calc(100vh - 220px)"
                }}>
                    <Col md={6}>
                        <Form className='pb-3'>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label> <strong>Ads Link</strong> </Form.Label>
                                <Form.Control type="url"  name={'link'} placeholder="Enter link" onChange={handleChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label> <strong>Ads From</strong> </Form.Label>
                                <Form.Control type="text" name={'ad_from'} placeholder="Farm Heroes" onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label> <strong>Gem Value</strong> </Form.Label>
                                <Form.Control type="text" name={'gems'} placeholder="10" onChange={isNumberKey} />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <hr />
                <Col className=" d-flex justify-content-end ">
                    <Button className='btn  btn-delete px-4 ' onClick={()=>handleSubmit()}>SAVE</Button>
                </Col>
            </section>
        </>
    )
}

export default withAuth(AddExchange)