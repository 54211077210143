import React, {useEffect, useState} from 'react'
import {Button, Modal, Row, Col, Form} from "react-bootstrap";
import {toast} from "react-toastify";

const TitlePopUp = (props) => {
    const [toolData, setToolData] = useState(null);
    const [rewardTool, setRewardTool] = useState([]);
    const [formData, setFormData] = useState({});

    useEffect(() => {

        if (props.tools) {

            setToolData(props.tools)
        }

    }, [props.tools])
    useEffect(() => {

        setFormData({coins:"",gems:"",diamonds:"",qty:""})
        setRewardTool([])

    }, [])
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })

    }

    const handleSubmit = () => {
        formData.is_random = true;
        formData.mapTools = rewardTool
        props.saveRewards(formData)

    }
    const isNumberKey = (evt) =>{
        let value = evt.target.value;
        let name = evt.target.name;
        const re = /^[0-9\b]+$/;
        toast(re.test(evt.target.value.toString()))
        if (re.test(evt.target.value.toString())) {
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }else if (evt.target.value === ''){
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }

    }

    const handleToolChange = () => {
        const tool = toolData.filter(data => [formData.tool_id].includes(data.id))[0];
        if (!tool) {
            toast('Select Tool to continue')

        }
        if (tool && formData.qty && parseInt(formData.qty) > 0) {
            const updatedData = [...rewardTool, {
                "tool_id": tool.id,
                "qty": formData.qty,
                "coordinates": ""
            }];
            rewardTool.push({
                "tool_id": tool.id,
                "qty": formData.qty,
                "coordinates": ""
            })
            setRewardTool(updatedData)
        } else {
            toast('Quantity is required and must be greater than 0')
        }
    }


    return (
        <div>
            <Modal
                show={props.show}
                size='lg'
                centered
            >
                <Modal.Body>
                    <div className=' py-3  '>
                        <h5 className='mb-4 text-center'>Reward System</h5>
                        <Form>
                            <Form.Group className="mb-3" controlId="subAdminName">
                                <Form.Label>Coin</Form.Label>
                                <Form.Control name={'coins'} type="text"  placeholder="coins" value={formData.coins}
                                              onChange={isNumberKey}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="subAdminName">
                                <Form.Label>Diamond</Form.Label>
                                <Form.Control name={'diamonds'} type="text" placeholder="Diamond" value={formData.diamonds}
                                              onChange={isNumberKey}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="subAdminName">
                                <Form.Label>Gems</Form.Label>
                                <Form.Control name={'gems'} type="text" placeholder="Gems" onChange={isNumberKey} value={formData.gems}/>
                            </Form.Group>
                            <Row className='align-items-end'>
                                <Col md={6}>
                                    <Form.Group className={'mb-3'}>
                                        <Form.Label>Tool</Form.Label>
                                        <Form.Select aria-label="Default select example" name={'tool_id'}
                                                     onChange={handleChange}>
                                            <option disabled selected>Select to add</option>

                                            {toolData && toolData.map((field, index) => (
                                                <option value={field.id}>{field.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group  className={'mb-3'} controlId="subAdminName">
                                        <Form.Label>Qty</Form.Label>
                                        <Form.Control name={'qty'} type="text" value={formData.qty} onChange={isNumberKey}/>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group  className={'mb-3'}>
                                        <Button variant={"outline-success fw-bold"} onClick={() => handleToolChange()}>ADD
                                            MORE</Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <table className={'table'}>
                                        <thead>
                                        <tr>
                                            <th>Tool Name</th>
                                            <th>Qty</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {rewardTool && rewardTool.map((field, index) => (
                                            <>
                                                <tr>
                                                    <td>{toolData.filter(data => [field.tool_id].includes(data.id))[0]?.name}</td>
                                                    <td>{field.qty}</td>
                                                </tr>

                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Form>

                        <div className="text-center pt-4">
                            <Button onClick={props.onHide} variant={"outline-success"}>CANCEL</Button>
                            <Button className='btn  btn-delete text-center ms-2' onClick={()=>handleSubmit()}>SUBMIT</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default TitlePopUp
