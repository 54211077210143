import React, {useEffect, useState} from "react";
import PageTitle from "../../Components/Pagetitle";
import {Button, Col, Form, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {useNavigate} from "react-router-dom";
import Search from "../../Components/Search";
import AddNewSubAdmins from "../Dashboard/AddNewSubAdmins/AddNewSubAdmins";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import {toast} from "react-toastify";
import withAuth from "../../withAuth";

const AllSubAdmin = () => {
    const [modalShow, setModalShow] = useState(false);
    const [data, setData] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [defaultData, setDefaultData] = useState([]);
    const updateData = (object) => {
        setData(object)

    }
    const navigate = useNavigate();
    const back = () => {
        navigate('/dashboard');
    };

    const adminData = async () => {
        await AuthService.getMethod(ENDPOINT.sub_admin.listing, true)
            .then((res) => {
                setData(res.data);
                setDefaultData(res.data)

            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };
    const setAdmin = async (admin) => {
        setSelectedAdmin(admin)
        setModalShow(true)
    };
    const blockAdmin = async (id,status) => {
        // const newData =  data.filter(item => item.id !==id)
        //
        // setData(newData);
        ENDPOINT.sub_admin.edit_user.id = id.toString();
        return await AuthService.patchMethod(ENDPOINT.sub_admin.edit_user.url + ENDPOINT.sub_admin.edit_user.id, true, {block:status,id:id})
            .then((res) => {

                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 200) {
                    adminData();
                    if (status == true) {
                        toast("Sub-admin blocked successfully!")
                    }else{
                        toast("Sub-admin un-blocked successfully!")

                    }
                }

            })
            .catch((err) => {


                console.log('errors')
                toast(err.response.data.errors[0])
            });


    };
    const refreshRecords = async (admin) => {
        adminData()
        setModalShow(false)
    };

    useEffect(() => {
        adminData();
    }, []);
    const columns = [
        {
            name: <strong>Name of Sub Admin</strong>,
            selector: row => row.username,
            sortable: true,
        },
        {
            name: <strong>Email Address</strong>,
            selector: row => row.email,
            sortable: true,
        },
        {
            name: <strong>Phone</strong>,
            selector: row => row.phoneNo,
            sortable: true,
        },
        {
            name: <strong>Temporary Password</strong>,
            selector: row => row.temporary_password,
            sortable: true,
        },
        {
            name: <strong>Action</strong>,
            selector: row => row.action,
            cell: row => (
                <ul className={"action-List"}>
                    <li><Button variant={"actionBtn text-success"} onClick={()=>setAdmin(row)}><i className={"fal fa-pen"}></i> </Button></li>
                    {row.status.statusName === 'active'?<li><Button variant={"actionBtn text-danger"} onClick={()=>blockAdmin(row.id,true)}><i className={"fal fa-ban"}></i></Button></li>:<li><Button variant={"actionBtn text-danger"} onClick={()=>blockAdmin(row.id,false)}><i className={"fal fa-user"}></i></Button></li>}

                </ul>
            ),
        },



    ];
    return(
        <>
            <PageTitle title={"Sub Admins"} />
            <section>
                <Button onClick={back} variant={"transparent font-18 fw-bold ps-0"}><i className={"fas fa-arrow-left  mx-2"}></i> Sub Admins</Button>
                <Row className="justify-content-between py-3 align-items-center">
                    <Col md={8} sm={6} className="my-2">
                        <Search placeholder="Search Sub Admin" data={data}  default={defaultData} searchColumns={['username',
                            'email',
                            'phone',
                            'temporary_password',

                        ]} result={updateData}/>                    </Col>
                    <Col md={4} sm={6} className="text-right">
                        <Button className={"m-1"} onClick={() => setModalShow(true)}>ADD SUB ADMIN</Button>
                    </Col>
                </Row>
                <div className={"TableBox"}>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped
                    />
                </div>
            </section>
            <AddNewSubAdmins
                show={modalShow}
                onHide={refreshRecords}
                data={selectedAdmin}
            />
        </>
    )
}

export default withAuth(AllSubAdmin);