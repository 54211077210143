import React, {useEffect} from 'react'
import { Modal, Row, Col } from "react-bootstrap";
import profile from 'Images/img1.png'

const WinnerPopUp = (props) => {

    const [DelModalShow, setDelModalShow] = React.useState(false);
    const [winner, setWinner] = React.useState(null);
    useEffect(() => {
        setDelModalShow(props.show);
    }, []);

    useEffect(() => {
        if (props.winner) {
            setWinner(props.winner);
        }
    }, [props.winner]);
    return (
    <div>
          <Modal
          {...props}
            size='md'
            centered
          show={DelModalShow}

          >
            <Modal.Body>
                <Row className='text-center py-3  '>
                    <Col>
                    <h3>Winner</h3>
                    <img className='px-2 card-img w-25' src={winner?.user?.image?.path} alt="" />
                    <p className='my-2'>{winner?.user.username}</p>
                    </Col>
                    </Row>
                    <Row className='text-center py-2 rounded-4 mx-3'  >
                    <Col  md={4}>
                   <p> Date</p>
                 <h4>{winner?.winning_date}</h4>
                    </Col>
                    <Col  md={4}>
                    <p>Time</p>
                 <h4>{winner?.winning_time}</h4>
                    </Col>
                    <Col  md={4}>
                    <p>Coordinates</p>
                 <h4>{winner?.winning_coordinates}</h4>
                    </Col>
                    </Row>

            </Modal.Body>
        </Modal>
    </div>
  )
}

export default WinnerPopUp
