import React, {useEffect, useState} from 'react'
import { Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Search from 'Components/Search';

const WinnerList = (props) => {
    const navigate = useNavigate();
    const UserProfile = () => {
        navigate('/User-Profile');
    };
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data){
console.log('props.data')
console.log(props.data)
            setData(props.data)
        }
    }, [props.data]);


    const updateData = (object) => {
        setData(object)

    }
    const columns = [

        {
            name: 'Winner Name',
            selector: row => row.user?.username,
        },
        {
            name: 'Stakes',
            selector: row => row.mapUsers?.find(item => item.user?.id === row.user?.id)?.stake,
        },
        {
            name: 'Start Date',
            selector: row => row.start_date,


        },
        {
            name: 'End Date',
            selector: row => row.end_date,
        },

        {
            name: 'Time',
            selector: row => row.winning_time,
        },

        {
            name: 'Coordinates',
            selector: row => row.winning_coordinates,
        },


    ];

    return (
        <>
            <Row className="searchbox align-items-center">
                <Col md={12} className={'my-2'}>
                    <Search placeholder="Search" data={data}  default={props.data} searchColumns={['name','map_type','user.username']} result={updateData}/>
                </Col>
            </Row>
            <DataTable
                // show={props.show}
                columns={columns}
                data={data}
                pagination
                striped
            />
        </>
    )
}

export default WinnerList