import React, {useEffect, useState} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import BanFinalStep from "../BannerUserFinalS";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
const BanUserS1 = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState(null);
  const closePreviousPopup = () => {
    if (!startDate || !time){
      toast("Please fill all fields!")
      return ;
    }
    setModalShow(true)
    props.onHide()
  }
  const handleChange = (event) => {
    let value = event.target.value;
    setTime(value)
  }


  return (
    <>
      <Modal show={props.show} size="md" centered>
        <Modal.Body>
          <Row className=" py-3 text-center ">
            <Col>
              <h5 className="mb-4 ">Ban This Account?</h5>
              <Form>
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicDate"
                >
                  <Form.Label>
                    {" "}
                    <strong>Enter Date of resume</strong>{" "}
                  </Form.Label>
                  <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date()}

                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicTime"
                >
                  <Form.Label>
                    {" "}
                    <strong>Enter Time To Resume Gameplay</strong>{" "}
                  </Form.Label>
                  <Form.Control type="time" placeholder="8:00 PM" onChange={handleChange}/>
                </Form.Group>
              </Form>
              <Button
                onClick={props.onHide}
                variant={"outline-success"}
                className="me-3 px-5"
              >
                CANCEL
              </Button>
              <Button className="btn  btn-delete px-5" onClick={closePreviousPopup}>CONTINUE</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {time && <BanFinalStep show={modalShow} id={props.id} date={startDate} time={time} onHide={() => setModalShow(false)}/>}
    </>
  );
};

export default BanUserS1;
