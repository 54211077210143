import React, {useEffect, useState} from 'react'
import {Modal, Row, Col, Button, Form} from "react-bootstrap";
import Calendar from "react-calendar";
import {toast} from "react-toastify";
import 'react-calendar/dist/Calendar.css';
import AuthService from "../../../../services/auth.service";
import {ENDPOINT} from "../../../../config/constants";
import DatePicker from "react-datepicker";

const PopUp = (props) => {
    const [date, setDate] = useState(null);
    const [mapData, setMapData] = useState(null);
    const [value, onChange] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const today = new Date();

    function handleDateChange(date) {
        setSelectedDate(date);
    }
    async function saveSchedule() {
        let form = {}
        form.map_id = mapData.id.toString()
        form.schedule_date = selectedDate.toLocaleDateString()
        return await AuthService.postMethod(ENDPOINT.maps.schedule, true, form)
            .then((res) => {
                console.log('res')
                console.log(res)
                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 201) {

                    props.draft('later')
                }
            })
            .catch((err) => {


                console.log('errors')
                console.log(err)
                toast(err.response.data.errors[0])
            });
    }


    useEffect(() => {

        if (props.mapData) {
            setMapData(props.mapData)
        }}, [props.mapData])

    // useEffect(() => {
    //     toast(value)
    //     }, [value])
    return (
        <div>
            <Modal
                show={props.show}
                size='md'
                centered
            >
                <Modal.Body>
                    <Row className='text-center py-3  '>
                        <Col>

                            <strong><p>Are You Sure You Want To Complete <br /> The Map Build? </p></strong>

                            <Button onClick={()=>setDate(true)} variant={"outline-success m-2 fw-bold "}>MAYBE LATER</Button>
                            <Button className='btn  btn-delete m-2 fw-bold' onClick={()=>props.draft('publish')}>YES</Button>
                            {date &&
                                <>
                                    <div>
                                        <Form.Label className={'py-3'}>Publish Later</Form.Label>
                                        <div className={'d-flex justify-content-center mb-3'}>
                                            <Calendar
                                                onChange={handleDateChange}
                                                value={selectedDate}
                                                minDate={mapData.startDate}
                                            />
                                            {/*<DatePicker*/}
                                            {/*    onChange={handleDateChange}*/}
                                            {/*    // minDate={new Date()}*/}
                                            {/*    minDate={mapData.startDate}*/}
                                            {/*/>*/}
                                        </div>

                                        <Button className='btn  btn-delete m-2 fw-bold' onClick={()=>saveSchedule()}>SAVE</Button>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PopUp
