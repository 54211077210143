//export const API_URL = "http://127.0.0.1:8000/api";
// export const API_URL = "http://localhost:5000/api/v1";
export const API_URL = "https://api.prizeminer.appscorridor.com/api/v1";
export const BASE_URL = "https://api.prizeminer.appscorridor.com";
// export const BASE_URL = "http://localhost:5000";

export const KEY = "YW1Gb1lXNTZZV2xpTG1GemJHRnRMbTFsYUdGeVFHZHRZV2xzTG1OdmJUb3lZV2RoYVc0PQ==";

export const SOCKET_URL = "https://chat.prizeminer.appscorridor.com/";

export const ENDPOINT = {
    login: `${API_URL}/auth/login`,
    forgetpassword: `${API_URL}/auth/forgot/password`,
    user_me: `${API_URL}/auth/me`,
    dashboard: `${API_URL}/dashboard/users-info`,


    admin_user: {
        listing: `${API_URL}/admin/users`,
        delete: {
            url: `${API_URL}/admin/`,
            id: null,
        },
        add_user: `${API_URL}/admin`,
        edit_user: {
            url: `${API_URL}/admin/`,
            id: null
        },
        reject: {
            url: `${API_URL}/admin/`,
            id: null,
            type: `/reject`

        },
        approve: {
            url: `${API_URL}/admin/`,
            id: null,
            type: `/approve`
        }
    },
    support :{
        tickets : `${API_URL}/ticket`,
        chats : `${API_URL}/ticket/ticket/chats`,
        ticket_messages : `${API_URL}/ticket-messages/:id?limit=200`,
        mark_as_complete: `${API_URL}/ticket/:id/status`,
        upload_attachment: `${API_URL}/ticket-messages/update-file/:id`,
        socket_url: SOCKET_URL,
        emit_message:'msgToAdmin',
        listen_message:'msgSupport'
    },

    sub_admin: {
        listing: `${API_URL}/sub-admin`,
        active: `${API_URL}/sub-admin/active-inActive/true`,
        inactive: `${API_URL}/sub-admin/active-inActive/false`,
        delete: {
            url: `${API_URL}/sub-admin/`,
            id: null
        },
        add_user: `${API_URL}/sub-admin`,
        edit_user: {
            url: `${API_URL}/sub-admin/`,
            id: null
        },

    },


    admin_route: {
        listing: `${API_URL}/admin/route`,
    },

    admin_guidelines: {
        terms_conditions: `${API_URL}/guidelines`,
        edit: {
            url: `${API_URL}/guidelines/`,
            id: null
        },
    },

    users_route: {
        listing: `${API_URL}/users/listing`,
        getById: {
            url: `${API_URL}/users/`,
            id: null
        },
        edit: {
            url: `${API_URL}/users/`,
            id: null
        },
        addFreeTool: `${API_URL}/Users-purchased-item`,
    },
    treasure_chests: {
        listing: `${API_URL}/admin/treasure-chest`,
        delete: {
            url: `${API_URL}/admin/treasure-chest/`,
            id: null
        },
        edit_user: {
            url: `${API_URL}/admin/treasure-chest/`,
            id: null
        },
    },
    treasure_wild: {
        listing: `${API_URL}/treasure-wild/listings`,
        delete: {
            url: `${API_URL}/treasure-wild/listings/`,
            id: null
        },
    },
    assets: {
        add_user: `${API_URL}/Asset/create-asset`,
        getLayerTwoThreeData: `${API_URL}/Asset/layerTwoThreeAssets`,
        listing: `${API_URL}/Asset`,
        delete: {
            url: `${API_URL}/Asset/`,
            id: null
        },
        edit: {
            url: `${API_URL}/Asset/update-asset`,
            id: null
        },
    },
    music: {
        add_music: `${API_URL}/music`,
        listing: `${API_URL}/music`,
        delete: {
            url: `${API_URL}/music/`,
            id: null
        },
        edit: {
            url: `${API_URL}/music/`,
            id: null
        },
    }, tutorial: {
        add_tutorial: `${API_URL}/tutorials`,
        listing: `${API_URL}/tutorials`,
        delete: {
            url: `${API_URL}/tutorials/`,
            id: null
        },
        edit: {
            url: `${API_URL}/tutorials/`,
            id: null
        },
    }, ads: {
        add_ads: `${API_URL}/ads`,
        listing: `${API_URL}/ads`,
        delete: {
            url: `${API_URL}/ads/`,
            id: null
        },
        edit: {
            url: `${API_URL}/ads/`,
            id: null
        },
    }, notification: {
        add_notification: `${API_URL}/notification`,
        listing: `${API_URL}/notification`,
        delete: {
            url: `${API_URL}/notification/`,
            id: null
        },
        edit: {
            url: `${API_URL}/notification/`,
            id: null
        },
    },
    tool_exchange_rate: {
        add_tool_exchange_rate: `${API_URL}/Tool-exchange-rate`,
        listing: `${API_URL}/Tool-exchange-rate`,
        delete: {
            url: `${API_URL}/Tool-exchange-rate/`,
            id: null
        },
        edit: {
            url: `${API_URL}/Tool-exchange-rate/`,
            id: null
        },
    },
    exchange_rate: {
        listing: `${API_URL}/exchange-rate`,
        edit: {
            url: `${API_URL}/exchange-rate/`,
            id: null
        },
    },
    tools: {
        add_tool: `${API_URL}/tool`,
        listing: `${API_URL}/tool`,
        delete: {
            url: `${API_URL}/tool/`,
            id: null
        },
        edit: {
            url: `${API_URL}/tool/`,
            id: null
        },
    },
    maps: {
        add_map: `${API_URL}/map`,
        listing: `${API_URL}/map`,
        totalRecords: `${API_URL}/map/all`,
        schedule: `${API_URL}/map-schedules`,
        leaderboard: `${API_URL}/map/leaderboard`,
        winners: `${API_URL}/map/winners`,
        dashboard: `${API_URL}/map/dashboard`,
        delete: {
            url: `${API_URL}/map/`,
            id: null
        },
        edit: {
            url: `${API_URL}/map/`,
            id: null
        },

    },
    prize_info: {
        add_prize: `${API_URL}/prize-info`,
        listing: `${API_URL}/prize-info`,
        delete: {
            url: `${API_URL}/prize-info/`,
            id: null
        },
        edit: {
            url: `${API_URL}/prize-info/`,
            id: null
        },
    },
    files: {
        add_image: `${API_URL}/files/upload`,
        delete: {
            url: `${API_URL}/Asset/`,
            id: null
        },
    },

};