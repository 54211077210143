import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import img from "Images/searchIcon.svg";
import filterImg from "Images/Filter-icon.png";
import DataTable from "react-data-table-component";
import BanUserMadal from "./BanUser";
import Search from "../../Components/Search";
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import withAuth from "../../withAuth";



const ModalCLose =(()=>{

})
const Users = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState([]);
  const [record, setRecord] = useState([]);
  const [id, setId] = useState(null);

  const navigate = useNavigate();

  const setUserId = (id) => {
    setId(id)
    setModalShow(true)
  }

  const usersData = async () => {



    await AuthService.getMethod(ENDPOINT.admin_user.listing, true)
        .then((res) => {
          setData(res.data);
          setRecord(res.data);
        })
        .catch((err) => {
          //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
          // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
        });

  };

  useEffect(() => {
    usersData();
  }, [
  ]);
  const updateData = (object) => {
    setData(object)

  }

  const columns = [
    {
      name: "Name of User",
      selector: (row) => row.username ?? "N/A",
    },
    {
      name: "Email Address",
      selector: (row) => row.email ?? "N/A",
    },
    {
      name: "Phone",
      selector: (row) => row.social_Login_type,
    },

    {
      name: "Age",
      selector: (row) => row.age ?? "N/A",
    },

    {
      name: "Level",
      selector: (row) => row.level ?? 0,
    },

    {
      name: <strong>Action</strong>,
      selector: row => row.action,
      cell: row => (
          <ul className={"action-List"}>
            <li className={"btn btn-actionBtn text-success"} onClick={() => navigate('/users/User-Profile',{state:row.id})}><i className={"fal fa-eye"}></i></li>
            <li><Button  onClick={()=>setUserId(row.id)}  variant={"actionBtn text-danger"}><i className={"fal fa-ban"}></i></Button></li>
          </ul>
      ),
    },




  ];

  return (
      <>
        <PageTitle title="Users" />

        <section>
          <Row className="searchbox px-0 pt-0">
            <Col md={12} className="my-2">
              <Search placeholder="Search" data={data}  default={record} searchColumns={['username']} result={updateData}/>
            </Col>
          </Row>

          <DataTable
              columns={columns}
              data={data}
              pagination
              striped
          />
        </section>

        {id && <BanUserMadal show={modalShow} id={id} onHide={() => setModalShow(false)}/>}



      </>
  )
};

export default withAuth(Users);
