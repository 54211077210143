import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import {Button, Col, Dropdown, Form, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import filterImg from "Images/Filter-icon.png";
import Search from "Components/Search";
import {useNavigate} from "react-router-dom";
import Delete from "../PrizeInfo/Delete";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import {toast} from "react-toastify";
import Loader from "../../Components/Loader";
import withAuth from "../../withAuth";

const PrizeInfo = () => {
    const [isLoader, setIsLoader] = useState(false);
    const [data, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [DelModalShow, setDelModalShow] = React.useState(false);
    const [id, setId] = React.useState("");
    const navigate = useNavigate();


    const updateData = (object) => {
        setData(object)

    }

    const addPrize = () => {
        navigate('/prize-info/add-prize');
    };

    const prizeData = async () => {
        // setIsLoader(true)
        await AuthService.getMethod(ENDPOINT.prize_info.listing, true)
            .then((res) => {
                setData(res.data)
                setDefaultData(res.data)
            })
            .catch((err) => {

            });
    };

    const handleChange = async (event) => {
        const id = event.target.id;
        const checked = event.target.checked;
        const newData = data.map((row) =>
            row.id === id ? { ...row, is_live: checked } : row
        );
        setData(newData);
        editPrize({is_live: checked,id:id})

    };

const editPrize = async (data = {}) => {
    ENDPOINT.prize_info.edit.id = data.id;

    await AuthService.patchMethod(ENDPOINT.prize_info.edit.url + ENDPOINT.prize_info.edit.id, true,data )
        .then((res) => {

            if (res.status === 422) {

                for (var i = 0; i < res.data.errors.length; i++) {
                    var error = res.data.errors[i];
                    for (var key in error) {
                        // console.log(key + ": " + error[key]);
                        toast(error[key])
                    }
                }
            } else if (res.status === 200) {
                toast("Prize live status updated successfully!")
            }
        })
        .catch((err) => {


            console.log('errors')
            toast(err.response.data.errors[0])
        });

    };


    const deleteRouteList = async (id) => {

      setIsLoader(true)

        ENDPOINT.prize_info.delete.id = id;
        await AuthService.deleteMethod(ENDPOINT.prize_info.delete.url + ENDPOINT.prize_info.delete.id, true)
            .then((res) => {
                prizeData();
                // console.log(res.data);
              setIsLoader(false);

              toast('Prize deleted successfully!')

            })
            .catch((err) => {
                // console.log(" ~ file: index.js:38 ~ deleteRouteList ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

        setDelModalShow(false)


    };




    const deleteRouteList2 = async (id) => {

        setDelModalShow(true)
        setId(id)


    };




/*Testing Pipeline*/
    const columns = [
        {
            name: <strong>Live</strong>,
            selector: (row) => row.is_live,
            sortable: true,
            cell: (row) => <Form.Check type="switch" checked={row.is_live === true?true:false}  onChange={handleChange} id={row.id}/>,
            grow: 1,
        },
        {
            name: <strong>Prize Image</strong>,
            selector: (row) => row.imageEntity?.path,
            sortable: true,
            cell: (row) => <img style={{width:'50px'} }src={row.imageEntity?.path} alt="img"/>,
        },
        {
            name: <strong>Prize Name</strong>,
            selector: (row) => row.real_prize_name,
            sortable: true,
        },
        {
            name: <strong>DESCRIPTION</strong>,
            selector: (row) => row.description,
            sortable: true,
            maxWidth: "150px",
        },
        {
            name: <strong>Price</strong>,
            selector: (row) => row.price,
            sortable: true,
        },
        {
            name: <strong>Required Level</strong>,
            selector: (row) => row.required_level,
            sortable: true,
        },
        {
            name: <strong>Age Restriction</strong>,
            selector: (row) => row.age_required,
            sortable: true,
        },

        {
            name: "Action",
            cell: (row) => (
                <div className="action-list">
                    <Button variant="actionBtn text-green" onClick={() => navigate('/prize-info/add-prize',{state:{row}})}>
                        {" "}
                        <i className="far fa-pen"></i>
                    </Button>
                    <Button variant="actionBtn text-danger" onClick={() => {
                        deleteRouteList2(row.id)
                    }}>
                        <i className="fal fa-trash"></i>
                    </Button>
                </div>
            ),
        },
    ];
    useEffect(() => {
        prizeData()
    }, []);
    useEffect(() => {
        setIsLoading(false)
        setIsLoader(false)
    }, [data]);
    // if (!isLoader) {
    //     return (
    //         <Loader isLoading={isLoading}/>
    //     );
    // }

    return (
        <>
            <PageTitle title="Prize Information"/>

            <section>
                <div className={'tab-pane'}>
                    <Row className="searchbox  align-items-center">
                        <Col md={3} sm={6} className='my-2'>
                            <Search placeholder="Search" data={data}  default={defaultData} searchColumns={['real_prize_name',
                                'description',
                                'required_level',
                                'age_required',

                            ]} result={updateData}/>
                        </Col>
                        <Col md={6} sm={3} xs={6} className={'my-2'}>
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="filterDropDown"
                                    variant="outline-light"
                                    className="drop-btn"
                                >
                                    Filter <img src={filterImg} alt="img"/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col md={3} sm={3} xs={6} className="text-end my-2">
                            <Button onClick={addPrize} type="button">
                                ADD A PRIZE
                            </Button>
                        </Col>
                    </Row>

                    {/*<div className="cust-data">*/}

                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            striped
                        />

                    {/*</div>*/}
                </div>
            </section>

            {DelModalShow && <Delete show={DelModalShow} onHide={() => setDelModalShow(false)} refreshRecord={() => deleteRouteList(id)}/>}

        </>
    );
};

export default withAuth(PrizeInfo);
