import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./configureStore";
import "Style/global.scss";
import ClientRoutes from "./Routes";

const AppContainer = () => {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const handleOnlineStatus = () => setIsOnline(window.navigator.onLine);
        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);
        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);

    return (
        <React.Fragment>
            {!isOnline && <p>No internet connection. Please check your network connection and try again.</p>}
            {isOnline && (
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <ClientRoutes />
                    </PersistGate>
                </Provider>
            )}
        </React.Fragment>
    );
};

ReactDOM.render(<AppContainer />, document.getElementById("root"));
