import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import img from "Images/searchIcon.svg";
import filterImg from "Images/Filter-icon.png";
import DataTable from "react-data-table-component";
import Search from "../../Components/Search";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import withAuth from "../../withAuth";

const LeaderBoard = () => {
  const [data, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const updateData = (object) => {
        setData(object)

    }
  const mapsData = async () => {
    await AuthService.getMethod(ENDPOINT.maps.leaderboard, true)
        .then((res) => {
          setData(res.data.data);
            setDefaultData(res.data.data);
        })
        .catch((err) => {
          //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
          // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
        });
  };

    const columns = [
        {
          name: "Name of User",
          selector: (row) => row.user_username,
        },
        {
          name: "Hours Played",
          selector: (row) => row.total_hours_played,
        },
        // {
        //   name: "Email Address",
        //   selector: (row) => row.email,
        // },
        {
          name: "Rank",
          selector: (row) => row.rank,
        },
    
        {
          name: "Age",
          selector: (row) => row.user_age,
        },
    
        {
          name: "Level",
          selector: (row) => row.level ?? "N/A",
        },
      ];
  useEffect(() => {
    mapsData();
  }, []);

  return (
    <>
    <PageTitle title="Leaderboard" />

    <section>
        <Row className="searchbox align-items-center pt-0 px-0">
          <Col md={3} xs={6} className={'my-2'}>
              <Search placeholder="Search" data={data}  default={defaultData} searchColumns={['user_username',
                  'rank',
                  'user_age',
                  'level',
                  'total_hours_played',
                  'total_stake',

              ]} result={updateData}/>
          </Col>
          <Col md={6} xs={6} className={'my-2'}>
            <Dropdown>
              <Dropdown.Toggle
                id="filterDropDown"
                variant="outline-light"
                className="drop-btn"
              >
                Filter <img src={filterImg} alt="img" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <DataTable
            columns={columns}
            data={data}
            pagination
            striped
        />
        </section>

        </>
  )
}

export default withAuth(LeaderBoard)