import PageTitle from 'Components/Pagetitle';
import React, {useEffect, useState} from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import PopUp from './PopUp';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import {toast} from "react-toastify";
import withAuth from "../../withAuth";

const PushNotif = (props) => {

    const formattedDate = new Date();
    const [modalShow, setModalShow] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [formData, setFormData] = useState({});
    const [data, setData] = useState([]);

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        let checked = event.target.checked;

        if (event.target.type === 'checkbox'){
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: checked
                }
            })
        }else {
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }
    }
    const handleSubmit = async () => {

        formData.date = startDate ?? ''
        return await AuthService.postMethod(ENDPOINT.notification.add_notification, true, formData)
            .then((res) => {

                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 201) {
                    window.location.reload()
                    toast("Notification added successfully!")
                    notificationData()
                }
            })
            .catch((err) => {


                console.log('errors')
                toast(err.response.data.errors[0])
            });
    }
    const notificationData = async () => {
        await AuthService.getMethod(ENDPOINT.notification.listing, true)
            .then((res) => {
                setData(res.data);

            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };

    const postData = async () => {

        if(!formData.title){
            toast('Title is required')
        }else if (!formData.send_to_all_users && !formData.send_to_all_calendars && !formData.is_announcement){
            toast("Check at least one checkbox!")
        }else if (formData.send_to_all_users && formData.send_to_all_users === true){
            setModalShow(true)
        }else{
            handleSubmit()
        }
    };


    useEffect(() => {
        setStartDate(formattedDate)
        notificationData();
    }, []);
    return (
        <>
            <PageTitle title={"Push Notification"} />
            <section>
                <Row>
                    <Col md={9}>
                        <Card className={'p-4'}>
                            <Form>
                                <Row className={'py-2'}>
                                    <div className="col-md-4">
                                        <Form.Check
                                            inline
                                            label="Send to all players"
                                            name="send_to_all_users"
                                            type='checkbox'
                                            id={`Standard`}
                                            onChange={handleChange}

                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Check
                                            inline
                                            label="Send to  all calendars"
                                            name="send_to_all_calendars"
                                            type='checkbox'
                                            id={`Proximity`}
                                            onChange={handleChange}

                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Check
                                            inline
                                            label="Send as announcements"
                                            name="is_announcement"
                                            type='checkbox'
                                            id={`RaceMode`}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                                            <Form.Label> Notification Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Here" name={'title'} onChange={handleChange} />
                                        </Form.Group>
                                        <Form.Group className="mt-3" controlId="formBasicDec" >
                                            <Form.Label>
                                                Details
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter description here"
                                                style={{ height: "400px" }}
                                                name={'description'}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="" controlId="exampleForm.ControlInput1">
                                            <Form.Label> Select Date</Form.Label>
                                            <DatePicker  minDate={new Date()} selected={startDate} onChange={(date) => setStartDate(date)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className={'justify-content-end'}>
                                    <Col md={6} className={"text-center py-3"}>
                                        <Button onClick={postData}  className='btn  btn-delete'>SEND NOTIFICATION</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='my-3' style={{maxHeight: "690px", overflowY: 'scroll'}}>
                            <Form className='m-3'>
                                <Col className='my-3'>
                                    <h5>History</h5>
                                </Col>
                                {data && data.map((field, index) => (
                                    <Col  className='my-2'>
                                        <span>{field.date?new Date(field.date).toLocaleDateString():new Date(field.createdDate).toLocaleDateString()}</span>
                                        <p>{field.title} {field.date?new Date(field.date).toLocaleTimeString():new Date(field.createdDate).toLocaleTimeString()}</p>
                                        <hr />
                                    </Col>
                                ))}

                            </Form>
                        </Card>
                    </Col>
                </Row>
            </section>
            <PopUp show={modalShow} onHide={()=>setModalShow(false)} onSubmit={handleSubmit} />
        </>
    )
}

export default withAuth(PushNotif)