import React, { Component } from 'react';
import {Navigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import {toast} from "react-toastify";

const withAuth = (WrappedComponent) => {

    return class extends Component {


        /**
         *
         * @returns {boolean}
         */
        isAuthenticated = () => {
            const token = Cookies.get('token');

            return Boolean(token);
        };

        /**
         *
         * @returns {JSX.Element}
         */
        render() {
            if (this.isAuthenticated()) {
                return <WrappedComponent {...this.props} />;
            } else {
               return <Navigate to="/" replace />
            }
        }
    };
};

export default withAuth;