import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import upload from "Images/uploadImg.svg";
import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import {toast} from "react-toastify";
import volume from "../../../Images/VolumeUp.png";

import {useDropzone} from 'react-dropzone'


const PrizeInfo = (props) => {

    const [formData, setFormData] = useState({});
    const [mapData, setMapData] = useState({});
    const [selectedValue, setSelectedValue] = useState('');
    const [prizeData, setPrizeData] = useState([]);
    const [data, setData] = useState([]);
    const [prizeIds, setPrizeIds] = useState([]);
    const [file, setFile] = useState(null);
    const onDrop = useCallback(acceptedFiles => {

        // eslint-disable-next-line no-mixed-operators
            if (acceptedFiles[0] && acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/JPEG" || acceptedFiles[0].type === "image/svg") {
                setFile(acceptedFiles[0])
            }else{
                toast('File type not supported')
            }
           }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const uploadSingleFile = (e: ChangeEvent<HTMLInputElement>) => {
        let acceptedFiles = e.target.files
        // if (e.target.files) {
        //     setFile(e.target.files[0]);
        // }
        if (acceptedFiles[0] && acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/JPEG" || acceptedFiles[0].type === "image/svg") {
            setFile(acceptedFiles[0])
        }else{
            toast('File type not supported')
        }
    };
    // const handleChange = (event) => {
    //     let value = event.target.value;
    //     let name = event.target.name;
    //     setFormData((prevalue) => {
    //         return {
    //             ...prevalue,   // Spread Operator
    //             [name]: value
    //         }
    //     })
    // }

    const handleChange = (event) => {
        // Step 3: Update State
        setSelectedValue(event.target.value);
    };

    const prizeListing = async () => {
        // setIsLoader(true)
        await AuthService.getMethod(ENDPOINT.prize_info.listing, true)
            .then((res) => {
                setData(res.data)
                setSelectedValue(res.data[0].id)
                console.log('Data Prize Info',res.data)
            })
            .catch((err) => {

            });
    };
     useEffect(() => {

         prizeListing()
        if (props.data) {
            console.log('props.data')
            setMapData(props.data)
        }

    }, [props.data])

    useEffect(() => {

        if (props.data) {
            setPrizeData(mapData.mapPrizes)
            setPrizeIds(mapData?.mapPrizes?.map(prize => ({ prizeInfoId: prize.id })));
        }

    }, [mapData])
    const addPrize = async (id) => {

        // if (!formData.real_prize_name || !formData.description || !formData.qty ){
        //     toast("Please fill all fields to continue!")
        //     return ;
        // }
        const exists = prizeIds.some(prize => prize.id === id);

        if (exists) {
            // If the ID already exists, log an error or handle it as needed
            console.error('Error: Prize ID already exists.');
            return; // Stop execution to prevent adding the duplicate ID
        }
        const foundRecord = data.find(record => record.id === id);
        let updatedData = [...prizeData];
                updatedData = [...updatedData, {
                    image: id,
                    real_prize_name:foundRecord.real_prize_name,
                    description: foundRecord.description,
                    price: foundRecord.price
                }];
                prizeData.push({
                    image: id,
                    real_prize_name:foundRecord.real_prize_name,
                    description: foundRecord.description,
                    price: foundRecord.price
                })
        prizeIds.push({ prizeInfoId:id})
            // console.log(prizeData)
        setFormData({})

        setPrizeIds(prizeIds)
        setPrizeData(updatedData)
    }
    const handleSubmit = async (draft = true) => {

        props.setLoading(true)
        formData.mapAllowedTools = []

        mapData.mapPrizes = prizeIds
        ENDPOINT.maps.edit.id = mapData.id;

        if (mapData.mapPrizes && mapData.mapPrizes.length === 0) {
            toast('Please add at least one prize!')
            props.setLoading(false)
        } else {
            return await AuthService.patchMethod(ENDPOINT.maps.edit.url + ENDPOINT.maps.edit.id, true, mapData)
                .then((res) => {
                    props.setLoading(false)

                    if (res.status === 422){

                        for (var i = 0; i < res.data.errors.length; i++) {
                            var error = res.data.errors[i];
                            for (var key in error) {
                                // console.log(key + ": " + error[key]);
                                toast(error[key])
                            }
                        }
                    }else if(res.status === 200) {
                        if (draft) {
                            toast("Maps updated successfully!")

                            setTimeout(() => {

                                navigate('/maps');

                            }, 1000);
                        }else{
                            props.onSubmit(res.data)
                            props.nextKey();
                        }
                    }
                })
                .catch((err) => {
                    console.log('errors')
                    toast(err.response.data.errors[0])
                });
        }

    }
    const saveImage = async () => {

        if (!file) {
            toast("Image is required")
            return;
        }
        const fileData = new FormData();
        fileData.append("file", file);
        return await AuthService.postMethod(ENDPOINT.files.add_image, true, fileData)
            .then((res) => {
                addPrize(res.data.id)
                setFile(null)
                setTimeout(() => {
                    // setAddAdmin(props.onHide);
                    // props.subAdminAllData();
                }, 1000);
            })
            .catch((err) => {
                toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    }
    useEffect(() => {

        // if (props.data) {
        //     console.log(props.data)
            setFormData(props.data)
        // }

    }, [])
    const isNumberKey = (evt) =>{
        let value = evt.target.value;
        let name = evt.target.name;
        const re = /^[0-9\b]+$/;
        toast(re.test(evt.target.value.toString()))
        if (re.test(evt.target.value.toString())) {
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }else if (evt.target.value === ''){
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }

    }

    const navigate = useNavigate();
    return (

        <section >

            <Form>
                <Row>
                        {/*<Col md={4}>*/}
                        {/*    <Form.Group className="mb-3" controlId="formBasicName" {...getRootProps()}>*/}

                        {/*        <Form.Control type={"file"} {...getInputProps()} id="fileUpload" className={"position-absolute opacity-0"} accept={"images/*"} name={"audioFile"} onChange={uploadSingleFile} />*/}
                        {/*        <label for={"fileUpload"}  className="upload-btn w-100 bg-light">*/}

                        {/*            {file && file.name ?<span>{ file.name}</span> : <>*/}
                        {/*                <span>drag image/upload</span></>}*/}

                        {/*        </label>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}


                        <Col md={6}>
                            <Form.Group controlId="exampleForm.SelectCustom">
                                <Form.Label>Select your option</Form.Label>
                                <Form.Select value={selectedValue} onChange={handleChange}>
                                    {data.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.real_prize_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            {/*<Form.Group className="mb-3" controlId="formBasicName">*/}
                            {/*    <Form.Label>*/}
                            {/*        Real Prize (name)*/}
                            {/*    </Form.Label>*/}
                            {/*    <Form.Control*/}
                            {/*        type="text"*/}
                            {/*        placeholder="Ex. Trip to singapore"*/}
                            {/*        name={'real_prize_name'}*/}
                            {/*        value={formData.real_prize_name ?? ''}*/}
                            {/*        onChange={handleChange}*/}

                            {/*    />*/}
                            {/*</Form.Group>*/}
                            {/*<Form.Group className="mb-3" controlId="formBasicName">*/}
                            {/*    <Form.Label>*/}
                            {/*        qty*/}
                            {/*    </Form.Label>*/}
                            {/*    <Form.Control*/}
                            {/*        type="text"*/}
                            {/*        placeholder="1"*/}
                            {/*        name={'qty'}*/}
                            {/*        value={formData.qty ?? ''}*/}

                            {/*        onChange={isNumberKey}*/}
                            {/*    />*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group className="mb-3" controlId="formBasicDec">*/}
                            {/*    <Form.Label>*/}
                            {/*        Prize Description*/}
                            {/*    </Form.Label>*/}
                            {/*    <Form.Control*/}
                            {/*        as="textarea"*/}
                            {/*        rows={3}*/}
                            {/*        placeholder="Enter your prize description..."*/}
                            {/*        name={'description'}*/}
                            {/*        // value={formData.description ?? ''}*/}

                            {/*        onChange={handleChange}*/}
                            {/*    />*/}
                            {/*</Form.Group>*/}
                            <Form.Group className={"text-right py-5"}>
                                <Button onClick={() => addPrize(selectedValue)}>Add More</Button>
                            </Form.Group>

                        </Col>
                    {prizeData?.length > 0 &&
                    <Table>
                        <thead>
                            <th>Prize Name</th>
                            <th>Price</th>
                            <th>Prize Description</th>
                        </thead>
                        <tbody>
                            {prizeData.map(prize =>
                                <>
                                    <tr>
                                        <td width={15}>{prize.real_prize_name}</td>
                                        <td width={15}>{prize.price}</td>
                                        <td width={70}>{prize.description}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </Table>
                    }

                        <hr />
                        <div className="col-md-12 d-flex justify-content-end">
                            <Button onClick={()=>handleSubmit(true)} variant={"outline-success"} className='me-2'>SAVE AS DRAFT</Button>
                            <Button className='btn  btn-delete ' onClick={()=>handleSubmit(false)}>NEXT</Button>
                        </div>
                    </Row>

            </Form>
        </section>

    );
};

export default PrizeInfo;