import Search from "Components/Search";
import React, {useEffect, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import ExchangeDigs from "./Digs";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";

const ExchangeTool = () => {
    const [data, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const toolsData = async () => {


        await AuthService.getMethod(ENDPOINT.tools.listing, true)
            .then((res) => {

                setData(res.data)
                setDefaultData(res.data)


            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

    };

    useEffect(() => {
        toolsData();
    }, []);
    const updateData = (object) => {
        setData(object)

    }
    const refresh = () => {
        toolsData()

    }
    return (
        <>
            <Row className="searchbox">
                <Col md={12} className={'my-2'}>
                    {/*<Search placeholder={"Search Tools"} />*/}
                    <Search placeholder="Search" data={data} default={defaultData} searchColumns={['name',
                        'time_limit',
                        'tool_type',
                        'level',
                        'coin_cost',
                        'attack',
                        'area_of_effect',
                        'description',
                        'user_limit',
                        'covered_area'
                    ]} result={updateData}/>
                </Col>
            </Row>
            <Tabs
                defaultActiveKey="DIG"
                transition={false}
            >
                <Tab eventKey="DIG" title="DIG">
                    <ExchangeDigs data={data.filter(data => ["dig"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="SMASH" title="SMASH">
                    <ExchangeDigs data={data.filter(data => ["smash"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="CUT" title="CUT">
                    <ExchangeDigs data={data.filter(data => ["cut"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>

                </Tab>
                <Tab eventKey="EXCAUATE" title="EXCAVATE">
                    <ExchangeDigs data={data.filter(data => ["excavate"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="COLLECT" title="COLLECT">
                    <ExchangeDigs data={data.filter(data => ["collect"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="PUMP" title="PUMP">
                    <ExchangeDigs data={data.filter(data => ["pump"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="SHOOING" title="SHOOING">
                    <ExchangeDigs data={data.filter(data => ["shoo"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="DESTROY" title="DESTROY">
                    <ExchangeDigs data={data.filter(data => ["destroy"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="FREEZE" title="FREEZE">
                    <ExchangeDigs data={data.filter(data => ["freeze"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="DROP" title="DROP">
                    <ExchangeDigs data={data.filter(data => ["drop"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
                <Tab eventKey="ACTIVATE" title="ACTIVATE">
                    <ExchangeDigs data={data.filter(data => ["activate"].includes(data.tool_type?.toLowerCase()))} default={data} refresh={refresh}/>
                </Tab>
            </Tabs>
        </>
    );
};

export default ExchangeTool;
