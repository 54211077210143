import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import Coins from "./Coin";
import EXP from "./Exp";
import GemsAndDimonds from "./GemsAndDimonds";
import Stake from "./Stake";
import ExchangeTool from "./Tools";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import withAuth from "../../withAuth";

const ExchangeRate = () => {
    const [data, setData] = useState([]);

    const exchaneRateData = async () => {


        await AuthService.getMethod(ENDPOINT.exchange_rate.listing, true)
            .then((res) => {
                setData(res.data.data)
            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

    };

    useEffect(() => {
        exchaneRateData();
    }, []);
    return (
        <>
            <PageTitle title={"Exchange"} />

            <section>
                <Tabs
                    defaultActiveKey="ToolsEx"
                    transition={false}
                >
                    <Tab eventKey="ToolsEx" title="Tools">
                        <ExchangeTool/>
                    </Tab>
                    <Tab eventKey="Coinsex" title="Coins">
                        <Coins data={data.filter(data => ["coin"].includes(data.type?.toLowerCase()))} refresh={()=>exchaneRateData}/>
                    </Tab>
                    <Tab eventKey="Stake" title="Stake">
                        <Stake data={data.filter(data => ["stake"].includes(data.type?.toLowerCase()))} refresh={()=>exchaneRateData}/>
                    </Tab>
                    <Tab eventKey="Gemsex" title="Gems & Diamonds">
                        <GemsAndDimonds data={data.filter(data => ["gem","diamond"].includes(data.type?.toLowerCase()))} refresh={()=>exchaneRateData}/>
                    </Tab>
                    <Tab eventKey="Expex" title="Exp">
                        <EXP data={data.filter(data => ["exp"].includes(data.type?.toLowerCase()))} refresh={()=>exchaneRateData}/>
                    </Tab>
                </Tabs>
            </section>
        </>
    );
};

export default withAuth(ExchangeRate);
