import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import PageTitle from "../../Components/Pagetitle";
import {Col, Row, Card, Button, Tab, Tabs} from "react-bootstrap";
import Cards from "../../Components/Cards/Cards";
import MapWinners from "./DashboardTabs/mapWinners";
import AddNewSubAdmins from "./AddNewSubAdmins/AddNewSubAdmins";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import CurrentOnlinePopUp from "../Maps/CurrentOnlinePopUp";
import withAuth from "../../withAuth";




const Dashboard = () => {
    const [data, setData] = useState([]);
    const [record, setRecord] = useState([]);

    const mapsData = async () => {



        await AuthService.getMethod(ENDPOINT.maps.dashboard, true)
            .then((res) => {
                setData(res.data?.data);
                setRecord(res.data);
            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

    };

    useEffect(() => {
        mapsData();
    }, [
    ]);

    const [modalShow, setModalShow] = useState(false);
    const [usersModalShow, setUsersModalShow] = React.useState(false);
    const [winnerPopupShow, setWinnerPopupShow] = React.useState(false);
    const [users, setUsers] = React.useState("");
    const usersPopup = async (users) => {

        setUsersModalShow(true)
        setUsers(users)


    };
    const navigate = useNavigate();
    const AllSubAdmins = () => {
        navigate('/dashboard/all-sub-admins');
    };
  return (
    <>
        <PageTitle title={"Dashboard"} />
        <section>
            <Row>
                <Col md={3} sm={6} className="mb-lg-0 mb-3">
                    <Cards
                        icon={"icon-download"}
                        title={record.downloadCounts}
                        text={"Total Downloads"}
                        bg={"purple"}
                    />
                </Col>
                <Col md={3} sm={6} className='mb-lg-0 mb-3'>
                    <Cards
                        icon={"fas fa-users"}
                        title={record.totalUsers}
                        text={"Online Players"}
                        bg={"success"}
                    />
                </Col>
                <Col md={6}>
                    <div className={"text-md-end text-start "}>
                        <Button className={"m-1"} onClick={AllSubAdmins}>SEE ALL SUB ADMIN</Button>
                        <Button className={"m-1"} onClick={() => setModalShow(true)}>ADD SUB ADMIN</Button>
                    </div>
                </Col>
            </Row>
        </section>
        <section>
            <Tabs
                defaultActiveKey="MapWinners"
                className=""
            >
                <Tab eventKey="MapWinners" title="Map Winners">
                    <MapWinners data={data} usersPopup={usersPopup}/>
                </Tab>
            </Tabs>

        </section>

        <AddNewSubAdmins
            show={modalShow}
            onHide={() => setModalShow(false)}
        />
        { usersModalShow && <CurrentOnlinePopUp show={usersModalShow} players={users} onConfirm={() => setUsersModalShow(false)} />}

    </>
  );
};

export default withAuth(Dashboard);
