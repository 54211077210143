import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import DataTable from "react-data-table-component";
import BanUserMadal from "Pages/User/BanUser";
import {useNavigate} from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Audio from "Audio/SoundHelix-Song-1.mp3";
import Search from "../../Components/Search";
import Delete from "../Music/Delete";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import {toast} from "react-toastify";
import withAuth from "../../withAuth";

const Music = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [data, setData] = useState([]);

    const [durations, setDurations] = useState(new Map());
    const [id, setId] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);

    const [defaultData, setDefaultData] = useState([]);
    const navigate = useNavigate();

    const updateData = (object) => {
        setData(object)

    }
    const AddMusic = () => {
        navigate('/music/add-music');
    };
    const audioData = async () => {
        await AuthService.getMethod(ENDPOINT.music.listing, true)
            .then((res) => {
                setData(res.data);
                setDefaultData(res.data)
                fetchDurations(res.data);

            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };
    const updateMusicData = async (id, MusicData) => {

        ENDPOINT.music.edit.id = id;

        return await AuthService.patchMethod(ENDPOINT.music.edit.url + ENDPOINT.music.edit.id, true, MusicData)
            .then((res) => {
                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 200) {

                    toast("Music updated successfully!")
                    audioData()

                }
            })
            .catch((err) => {


                console.log('errors')
                toast(err.response.data.errors[0])
            });
    };


    const getDuration = (url) => {
// Create a non-dom allocated Audio element
        var au = document.createElement('audio');
        var duration
// Define the URL of the MP3 audio file
        au.src = url;

// Once the metadata has been loaded, display the duration in the console
        au.addEventListener('loadedmetadata', function () {
            // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
            duration = au.duration;

            // example 12.3234 seconds
            console.log("The duration of the song is of: " + duration + " seconds");
            durations.set(url, secondsToTime(duration))

            // Alternatively, just display the integer value with
            // parseInt(duration)
            // 12 seconds
        }, false);
        console.log("The  of the song is of: " + duration + " seconds");

        // return duration


    };
    const deleteRouteList = async (id) => {


        ENDPOINT.music.delete.id = id;
        await AuthService.deleteMethod(ENDPOINT.music.delete.url + ENDPOINT.music.delete.id, true)
            .then((res) => {
                audioData();
                // console.log(res.data);

                toast('Music deleted successfully!')

            })
            .catch((err) => {
                // console.log(" ~ file: index.js:38 ~ deleteRouteList ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

        setModalShow(false)


    };
    const deleteRouteList2 = async (id) => {

        setModalShow(true)
        setId(id)


    };
    function secondsToTime(e) {
        const m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return m + ':' + s;
        //return `${h}:${m}:${s}`;
    }

    const fetchDurations = (record) => {
        if (record) {
            for (const row of record) {
                getDuration(row.music?.path);
            }
        }
        setRefresh(refresh + 1)

    };
    useEffect(() => {
        audioData();
    }, []);
    useEffect(() => {
        if (refresh < 3) {
            audioData();
        }
    }, [refresh]);


    useEffect(() => {
        // Fetch the durations of the audio files here using the getDuration function
        if (data) {
            fetchDurations(data);
        }
    }, [data]);


    const columns = [
        {
            name: "Title",
            selector: (row) => <Row className="audio-background">
                <AudioPlayer style={{width: '600px'}}
                             customVolumeControls={[]}
                             customAdditionalControls={[]}
                             showDownloadProgress={false}
                             showJumpControls={false}
                             autoPlay
                             src={row.music?.path}
                             onPlay={e => console.log("onPlay")}
                             header={row.title}

                />
            </Row>,
            grow: 2,


        },
        {
            name: "InGame",
            selector: (row) => row.in_game,
            cell: row => (
                <div className="drop-down-btn">
                    <Dropdown>
                        <Dropdown.Toggle variant="light" className="drop-down-btn">
                            {row.in_game}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => updateMusicData(row.id, {in_game: 'Main Theme'})}>Main
                                Theme</Dropdown.Item>
                            <Dropdown.Item onClick={() => updateMusicData(row.id, {in_game: 'Last 2 Minutes'})}>Last 2
                                Minutes</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
        },
        {
            name: "Duration",
            selector: (row) => {
                return durations.get(row.music?.path) ? durations.get(row.music?.path) : "N/A";
            },
        },

        {
            name: "Date Posted",
            selector: (row) => new Date(row.createdDate).toLocaleString("en-US", {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),
        },

        {
            name: "Status",
            selector: (row) => row.status,
            cell: row => (
                <div className="drop-down-btn">
                    <Dropdown>
                        <Dropdown.Toggle variant="publish" id="dropdown-basic" className="drop-down-btn">
                            {row.status}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {row.status !== 'Published' && <Dropdown.Item
                                onClick={() => updateMusicData(row.id, {status: 'Published'})}>Published</Dropdown.Item>}
                            {row.status !== 'Unpublished' && <Dropdown.Item
                                onClick={() => updateMusicData(row.id, {status: 'Unpublished'})}>Unpublished</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            ),
        },

        {
            name: <strong>Action</strong>,
            selector: row => row.action,
            cell: row => (
                <Button variant={"actionBtn text-danger"} onClick={() => {
                    deleteRouteList2(row.id)
                }}> <i class="far fa-trash"></i></Button>
            ),
        },


    ];

    return (
        <>
            <PageTitle title="Music"/>

            <section>
                <div className={'tab-pane'}>
                    <Row className="searchbox align-items-center justify-content-between">
                        <Col md={3} sm={6} className='my-2'>
                            <Search placeholder="Search Music" data={data} default={defaultData}
                                    searchColumns={['title', 'createdDate']} result={updateData}/>
                        </Col>
                        <Col md={3} sm={3} xs={6}>
                            <Button onClick={AddMusic} className='btn  btn-delete  mx-2 d-block float-end'>ADD
                                MUSIC</Button>
                        </Col>
                    </Row>

                    <DataTable
                        columns={columns}
                        data={data}

                        striped
                    />
                </div>
            </section>

            <BanUserMadal show={modalShow} onHide={() => setModalShow(false)}/>
            {modalShow &&
                <Delete show={modalShow} onHide={() => setModalShow(false)} refreshRecord={() => deleteRouteList(id)}/>}


        </>
    )
};

export default withAuth(Music);
