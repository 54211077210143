import {Direction} from "react-data-table-component";
import {useCallback, useEffect, useRef, useState} from "react";
import Grid, {useEditable, useSelection,useSizer, Cell as DefaultCell} from "react-konva-grid";
import {Image, Layer, Rect, Transformer,Text} from "react-konva";
import {toast} from "react-toastify";
import classes from "../index.module.scss"
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import {useNavigate} from "react-router-dom";

export const EditableGrid: React.FC = (props) => {
    const width = 980;
    const height = 700;
    const navigate = useNavigate();

    const [zoomLevel, setZoomLevel] = useState(100);

    const [mapData, setMapData] = useState({});
    const [data, setData] = useState(new Map());
    const [layerHistory, setLayerHistory] = useState(new Map());
    const [row, setRow] = useState(20);
    const [col, setCol] = useState(20);
    const [color, setColor] = useState("");
    const [isPrize, setIsPrize] = useState(false);
    const handleSubmit = async (draft) => {
        props.setLoading(true)
        // convert the map to a 2D array
        const size = mapData.map_size.split("x");
        const numRows = size[0];
        const numCols = size[1];
        const tableArray = [];
        for (let row = 0; row < numRows; row++) {
            let rowArray = [];
            for (let col = 0; col < numCols; col++) {
                rowArray.push(data.get(row + "," + col));
            }
            tableArray.push(rowArray);
        }

        // console.log(tableArray);
        mapData.mapAllowedTools = []
        // mapData.map_layer_data = Array.from(data, ([key, value]) => ({value}))
        mapData.map_layer_data = tableArray
        ENDPOINT.maps.edit.id = mapData.id;

        return await AuthService.patchMethod(ENDPOINT.maps.edit.url + ENDPOINT.maps.edit.id, true, mapData)
            .then((res) => {
                props.setLoading(false)
                if (res.status === 422){

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                }else if(res.status === 200) {



                    if (draft === 'true') {
                        toast("Maps updated successfully!")

                        setTimeout(() => {

                            navigate('/maps');

                        }, 1000);
                    }else if(draft === 'false'){
                        props.onSubmit(res.data)
                    }
                }
            })
            .catch((err) => {


                toast(err.response.data.errors[0])
            });
    }

    function undoSelections() {
        if (layerHistory) {
            const keysArray = Array.from(layerHistory.keys());
            const lastKey = keysArray.slice(-1)[0];

            const keysToUpdate = layerHistory.get(lastKey);
            if (keysToUpdate) {

                var i = 0;
                keysToUpdate.forEach(({row, col}) => {


                    const asset = data.get(`${row},${col}`)[0].assets;
                    if (i === 0){
                        props.addToConsole('You removed '+asset.name+' heatmap')

                    }
                    asset.id = "grey";
                    asset.name = "grey";
                    asset.version = "#BFC1BF";
                    i++
                });
                layerHistory.delete(lastKey)
            }


        }
    }
    function getRandomCellInSubset(rows, cols, minRow, minCol) {
        const row = Math.floor(Math.random() * (rows - minRow + 1)) + minRow;
        const col = Math.floor(Math.random() * (cols - minCol + 1)) + minCol;
        return { row, col };
    }
    useEffect(() => {

        if (props.data) {
            setMapData(props.data)
        }

    }, [props.data])
    useEffect(() => {

        if (props.undo) {
            undoSelections()
        }

    }, [props.undo])
    useEffect(() => {

        if (props.draft) {
            handleSubmit(props.draft)
        }

    }, [props.draft])
    useEffect(() => {

        if (props.zoomLevel) {
            setZoomLevel(props.zoomLevel)
        }

    }, [props.zoomLevel])
    useEffect(() => {

        const tableArray = mapData.map_layer_data;
        const size = mapData?.map_size?.split("x");
        const numRows = mapData?.map_size && size[0];
        const numCols = mapData?.map_size && size[1];
        setRow(numRows)
        setCol(numCols)
        console.log('tableArray')
        console.log(tableArray)
        if (mapData?.map_size && tableArray && tableArray.length > 0) {
            for (let i = 0; i < numRows * numCols * 4; i++) {
                // let mapRow = Math.floor(i / (4 * numCols));
                // let mapCol = Math.floor((i / 4) % numCols);
                for (let mapRow = 0; mapRow < numRows; mapRow++) {
                    for (let mapCol = 0; mapCol < numCols; mapCol++) {
                        // console.log('tableArrayData')

                        // console.log(tableArray[mapRow].value)

                        data.set(mapRow + "," + mapCol, tableArray[mapRow][mapCol]);
                    }
                }
            }
        }


    }, [mapData])



    useEffect(() => {
        if (props.color) {
            setColor(props.color)
        }
    }, [props.color]);

    const xAxisProps = {
        x: 0,
        y: height,
        points: [0, 0, width, 0],
        stroke: 'black'
    };

    const tickSpacing = 50;
    for (let i = 0; i <= width; i += tickSpacing) {
        xAxisProps.points.push(i, -5, i, 5);
    }

    const xAxisLabelProps = {
        x: width / 2,
        y: height + 15,
        text: 'X Axis',
        fontSize: 14,
        align: 'center'
    };

    const SelectEditor: React.FC<EditorProps> = (props) => {
        const {
            position,
            onSubmit,
            value,
            cell,
            nextFocusableCell,
            onBlur,
        } = props;
        return (
            <div
                style={{
                    position: "absolute",
                    left: position.x,
                    top: position.y,
                    width: position.width,
                    height: position.height,
                }}
            >
                <select
                    style={{width: "100%"}}
                    autoFocus
                    value={value}
                    onBlur={onBlur}
                    value={value}
                    onChange={(e) => {
                        onSubmit(
                            e.target.value,
                            cell,
                            nextFocusableCell(cell, Direction.Down)
                        );
                    }}
                >
                    <option>Yay Select</option>
                    <option>This can be any React Component</option>
                </select>
            </div>
        );
    };
    const [selectedCell, setSelectedCell] = useState(null);

    const Resizable = ({scrollTop, scrollLeft, isSelected, onSelect, color}) => {
        const trRef = useRef(null);
        const shapeRef = useRef();
        useEffect(() => {
            if (!isSelected) return;
            trRef.current.attachTo(shapeRef.current);
            trRef.current.getLayer().batchDraw();
        }, [isSelected]);
        const [dimensions, setDimensions] = useState({
            x: 100,
            y: 100,
            width: 100,
            height: 100,
        });
        const x = dimensions.x - scrollLeft;
        const y = dimensions.y - scrollTop;
        return (
            <>
                <Rect
                    onMouseDown={onSelect}
                    ref={shapeRef}
                    draggable
                    onDragEnd={(e) => {
                        setDimensions((prev) => {
                            return {
                                ...prev,
                                x: e.target.x() + scrollLeft,
                                y: e.target.y() + scrollTop,
                            };
                        });
                    }}
                    onTransformEnd={(e) => {
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);
                        setDimensions({
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(node.height() * scaleY),
                        });
                    }}
                    fill={color}
                    stroke="#000"
                    strokeWidth={1}
                    x={x}
                    y={y}
                    width={dimensions.width}
                    height={dimensions.height}
                />
                {selectedCell && (
                    <Image
                        x={selectedCell.col * 100}
                        y={selectedCell.row * 100}
                        width={100}
                        height={100}
                        image={"https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80"}
                    />
                )}
                {isSelected && (
                    <Transformer
                        ref={trRef}
                        rotateEnabled={false}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                                return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </>
        );
    };


    const App = () => {

        const rowCount = row;
        const columnCount = col;
        const gridRef = useRef(null);
        const getCellValue = useCallback(
            ({rowIndex, columnIndex}) => data.get(rowIndex+","+ columnIndex),
            [data]
        );
        const {
            activeCell,
            selections,
            setActiveCell,
            ...selectionProps
        } = useSelection({
            gridRef,
            rowCount,
            columnCount,
            onFill: (activeCell, fillSelection) => {

                // if (!fillSelection) return;
                const {bounds} = fillSelection;


                let array = [];
                for (let i = bounds.top; i <= bounds.bottom; i++) {
                    for (let j = bounds.left; j <= bounds.right; j++) {
                        if (color) {
                            var uniqueId = Math.random();
                            data.get(i + "," + j)[0].uniqueId = uniqueId.toString();
                            data.get(i + "," + j)[0].assets.id = color;
                            data.get(i + "," + j)[0].assets.name = color;
                            data.get(i + "," + j)[0].assets.version = color === 'red' ? "rgba(255, 25, 25, 0.3)" : "rgba(75, 149, 255, 0.3)";
                            array.push({row:i,col:j})
                        }

                    }
                }
                if (array.length > 0){

                    layerHistory.set(layerHistory.size === 0?0:layerHistory.size+1,array)
                    console.log('ayerHistory.keys()')
                    console.log(layerHistory.keys())

                }
                if (color === 'red' ){
                    if (selectedCell) {
                        data.get(selectedCell.row + "," + selectedCell.col)[0].assets.id = 'red';
                        data.get(selectedCell.row + "," + selectedCell.col)[0].assets.name = 'red';
                        data.get(selectedCell.row + "," + selectedCell.col)[0].assets.version = 'rgba(255, 25, 25, 0.3)';
                        setSelectedCell(null)
                    }
                    var prize = getRandomCellInSubset(bounds.bottom,bounds.right,bounds.top,bounds.left);
                    setSelectedCell({ row:prize.row, col:prize.col });

                    data.get(prize.row + "," + prize.col)[0].assets.id = 'Prize';
                    data.get(prize.row + "," + prize.col)[0].assets.name = 'Prize';
                    data.get(prize.row + "," + prize.col)[0].assets.version = 'rgba(255, 221, 16, 0.5)';
                    props.addToConsole('You added a red heatmap with major and minor prize')
                    setIsPrize(true)
                    setColor('')
                }else{
                    // setSelectedCell(null)

                    props.addToConsole('You added a blue heatmap')

                }

                // setData(data)
                // setData((prev) => ({...prev, ...changes}));
            },
        });
        // const { getTextMetrics, ...autoSizerProps } = useSizer({
        //     gridRef,
        //     getValue: getCellValue,
        //     resizeStrategy: "lazy",
        //     rowCount,
        //     autoResize: false,
        // });
        const [isSelected, setIsSelected] = useState(false);
        const handleMouseDown = (e) => {
            // Handle it better, maybe using `ids`
            console.log('mouse mouse')
            console.log(e)
            const clickedOnEmpty = e.target.attrs.draggable !== true;
            if (clickedOnEmpty) setIsSelected(false);
        };
        const {editorComponent, isEditInProgress, ...editableProps} = useEditable(
            {
                gridRef,
                getValue: getCellValue,
                selections,
                getEditor: ({rowIndex, columnIndex}) => {

                    if (isPrize && !color){
                        var asset =data.get(rowIndex + "," + columnIndex)[0].assets
                        if (asset.name === 'red'){
                            if (selectedCell) {
                                data.get(selectedCell.row + "," + selectedCell.col)[0].assets.name = 'red';
                                data.get(selectedCell.row + "," + selectedCell.col)[0].assets.version = 'rgba(255, 25, 25, 0.3)';
                                setSelectedCell(null)
                            }
                            setSelectedCell({ row:rowIndex, col:columnIndex });

                            data.get(rowIndex + "," + columnIndex)[0].assets.name = 'Prize';
                            data.get(rowIndex + "," + columnIndex)[0].assets.version = 'rgba(255, 221, 16, 0.5)';
                            props.addToConsole('You have changed prize location')

                        }else{
                            toast('Please select within red heatmap')
                            return undefined;
                        }
                    }
                    // if (rowIndex == 1 && columnIndex === 1) {
                    //     return SelectEditor;
                    // }
                    // return undefined;
                },
                activeCell,
                onDelete: (activeCell, selections) => {
                    if (selections.length) {
                        const newValues = selections.reduce((acc, {bounds: sel}) => {
                            for (let i = sel.top; i <= sel.bottom; i++) {
                                for (let j = sel.left; j <= sel.right; j++) {
                                    acc[[i, j]] = "";
                                }
                            }
                            return acc;
                        }, {});
                        setData((prev) => ({...prev, ...newValues}));
                        const selectionBounds = selections[0].bounds;

                        gridRef.current.resetAfterIndices(
                            {
                                columnIndex: selectionBounds.left,
                                rowIndex: selectionBounds.top,
                            },
                            true
                        );
                    } else if (activeCell) {
                        setData((prev) => {
                            return {
                                ...prev,
                                [[activeCell.rowIndex, activeCell.columnIndex]]: "",
                            };
                        });
                        gridRef.current.resetAfterIndices(activeCell);
                    }
                },
                onBeforeEdit: ({rowIndex, columnIndex}) => {
                    // if (rowIndex === 2 && columnIndex === 3) return false;
                    return true;
                },
                onSubmit: (value, {rowIndex, columnIndex}, nextActiveCell) => {
                    // setData((prev) => ({...prev, [[rowIndex, columnIndex]]: value}));
                    gridRef.current.resizeColumns([columnIndex]);

                    /* Select the next cell */
                    if (nextActiveCell) {
                        setActiveCell(nextActiveCell);
                    }
                },
            }
        );
        return (

            <div className={classes.mapContainer} id={'layerFour'}>

                <Grid
                   width={width}
                    height={height}
                    columnCount={col}
                    rowCount={row}
                    ref={gridRef}
                    activeCell={activeCell}
                    selections={selections}
                    columnWidth={(index) => {
                        return zoomLevel;
                    }}
                    stageProps={{
                        onMouseDown: "",
                    }}

                    showFillHandle={!isEditInProgress}
                    itemRenderer={(props) => (
                        // console.log(props)
                        <DefaultCell
                            strokeWidth={0}
                            fill={data && data.get(props.rowIndex +","+ props.columnIndex) && data.get(props.rowIndex +","+ props.columnIndex)[0].assets?.version}
                            // value={data.get(props.rowIndex +","+ props.columnIndex) && data.get(props.rowIndex +","+ props.columnIndex)[0].assets?.name === 'Prize' ? }

                            // value={[props.rowIndex, props.columnIndex]}
                            align="left"

                            {...props}
                        />

                    )}
                    rowHeight={(index) => {
                        return zoomLevel;
                    }}
                    {...selectionProps}
                    {...editableProps}

                    onKeyDown={(...args) => {
                        selectionProps.onKeyDown(...args);
                        editableProps.onKeyDown(...args);
                    }}
                    onMouseDown={(...args) => {
                        selectionProps.onMouseDown(...args);
                        editableProps.onMouseDown(...args);
                    }}
                >
                    {({scrollTop, scrollLeft}) => {
                        return (

                            <Layer>

                                <Rect x={0} y={0} width={width} height={height} stroke='black' strokeWidth={1} />
                                { /* Add x-axis component */ }
                                <Text {...xAxisProps} />
                                <Text {...xAxisLabelProps} />


                        { /* Add y-axis component */ }
                                {/*{isBlue &&*/}
                                {/*    <Resizable*/}
                                {/*        scrollTop={scrollTop}*/}
                                {/*        scrollLeft={scrollLeft}*/}
                                {/*        isSelected={isSelected}*/}
                                {/*        onSelect={() => {*/}
                                {/*            setIsSelected(true);*/}
                                {/*        }}*/}
                                {/*        color={'lightblue'}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*{isRed &&*/}
                                {/*    <ResizableRed*/}
                                {/*        scrollTop={scrollTop}*/}
                                {/*        scrollLeft={scrollLeft}*/}
                                {/*        isSelected={isSelected}*/}
                                {/*        onSelect={() => {*/}
                                {/*            setIsSelected(true);*/}
                                {/*        }}*/}
                                {/*        color={'#FF1919'}*/}

                                {/*    />*/}
                                {/*}*/}
                            </Layer>
                        );
                    }}
                </Grid>

                {/*{editorComponent}*/}
            </div>
        );
    };

    return <App/>;
};
