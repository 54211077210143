import React, {useEffect, useState} from "react";
import { Col, Form, Row } from "react-bootstrap";
import SupportSideBar from "Components/SupportSection/SupportSideBarChat";
import MsgPreview from "Components/SupportSection/MsgPreviewChat";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import {toast} from "react-toastify";
const EndUserSupport = () => {
    const [inbox, setInbox] = useState([]);
    const [message, setMessage] = useState([]);
    const [rowUser, setRowUser] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [selectedChat, setSelectedChat] = useState("");

    const supportTickets  = async () => {
        await AuthService.getMethod(ENDPOINT.support.chats, true,)
            .then(async (res) => {
                setInbox(res.data)
                if (res.data?.data.length > 0) {
                    setSelectedChat(res.data.data[0].id)
                    await ticketMessages(res.data.data[0].id, res.data.data[0])
                }else{
                    setIsLoader(true)

                }
            })
            .catch((err) => {
                setIsLoader(true)
                toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };
    const ticketMessages = async (id, row)=> {
        setSelectedChat(id)
        const url = (ENDPOINT.support.ticket_messages).replace(':id',id);
        await AuthService.getMethod(url, true,)
            .then((res) => {
                setMessage(res.data)
                setRowUser(row)
                setIsLoader(true)
            })
            .catch((err) => {
                toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };

    useEffect( () => {

        supportTickets()
    }, []);

    useEffect( () => {
        //console.log('Selected Chat', selectedChat)
    }, [selectedChat]);

    if (!isLoader) {
        return (
            <div className='loader'>
                <h3>Loading...</h3>
            </div>
        );
    }
  return (
    <>
    <Row>
    <Col md={12}>
       <div class="support-block">
           <SupportSideBar inbox={inbox} ticketMessages={ticketMessages} selectedChat={selectedChat}/>
           {rowUser && <MsgPreview message={message} rowUser={rowUser} refresh={supportTickets}/> }
       </div>
       </Col>
     </Row>
    </>
  )
}

export default EndUserSupport