import React from 'react'
import logo from "../../Images/logo.svg"
import { Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Faqs() {
  return (
    <div id="wrapper">
      <section className="main-banner ">
        <div class="container">
          <div className="logo">
            <img src={logo} alt="" srcset="" />
          </div>
        </div>
      </section>

      <main id="main">
        <div class="container">
          <section>
            <div className="text-box">
              <h1>Frequently Ask Questions</h1>
              <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Is Prizeminer Compatible with Android or iOS?</Accordion.Header>
        <Accordion.Body>
        Prizeminer is currently in the development stage and will be compatible and available for Android and iOS devices once pushed to live.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How do I get in touch with someone if there is something wrong with my profile?</Accordion.Header>
        <Accordion.Body>
        Please get in touch with one of our representatives using the feedback and support information provided below.
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
    <p className='mt-3'>For any inquiries or assistance, feel free to reach out to us at <Link to="mailto:mohd@convrtx.com">mohd@convrtx.com</Link> Your satisfaction is our priority!</p>   
            </div>
          </section>
        </div>
      </main>
    </div>
  )
}

export default Faqs
