import PageTitle from 'Components/Pagetitle'
import React, {useCallback, useState} from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import upload from "Images/arrowUp.png";
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import {useDropzone} from "react-dropzone";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import withAuth from "../../../withAuth";

const AddMusic = () => {
    const navigate = useNavigate();

    const AddMusic = () => {
        navigate('/music');
    };
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({});
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFormData((prevalue) => {
            return {
                ...prevalue,   // Spread Operator
                [name]: value
            }
        })
    }
    const onDrop = useCallback(acceptedFiles => {

        // eslint-disable-next-line no-mixed-operators
        if (acceptedFiles[0] && acceptedFiles[0].type === "audio/wav" || acceptedFiles[0].type === "audio/mp3" || acceptedFiles[0].type === "audio/AIFF" || acceptedFiles[0].type === "audio/OGG") {
            setFile(acceptedFiles[0])
        }else{
            toast('File type not supported')
        }
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const uploadSingleFile = (e: ChangeEvent<HTMLInputElement>) => {
        let acceptedFiles = e.target.files
        // if (e.target.files) {
        //     setFile(e.target.files[0]);
        // }
        if (acceptedFiles[0] && acceptedFiles[0].type === "audio/wav" || acceptedFiles[0].type === "audio/mp3" || acceptedFiles[0].type === "audio/AIFF" || acceptedFiles[0].type === "audio/OGG") {
            setFile(acceptedFiles[0])
        }else{
            toast('File type not supported')
        }
    };
    const handleSubmit = async (id) => {

        formData.music_id = id
        return await AuthService.postMethod(ENDPOINT.music.add_music, true, formData)
            .then((res) => {

                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 201) {
                    toast("Music added successfully!")
                    AddMusic()
                }
            })
            .catch((err) => {


                console.log('errors')
                toast(err.response.data.errors[0])
            });
    }

    const saveImage = async () => {
        if (!formData.title){
            toast("Title is required")
            return;
        }
        if (!file) {
            toast("Audio is required")
            return;
        }
        const fileData = new FormData();
        fileData.append("file", file);
        return await AuthService.postMethod(ENDPOINT.files.add_image, true, fileData)
            .then((res) => {
                handleSubmit(res.data.id)
                setFile(null)
                setTimeout(() => {
                    // setAddAdmin(props.onHide);
                    // props.subAdminAllData();
                }, 1000);
            })
            .catch((err) => {
                toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    }
    return (
        <>
            <PageTitle title="Music" />
            <section>
                <Button onClick={AddMusic} variant={"transparent font-18 fw-bold ps-0"}><i className={"fas fa-arrow-left  mx-2"}></i>Add Music</Button>
                <Form className={'p-3'}>
                    <Row>
                        <Col md={4}>
                            <Form.Label>Upload Audio</Form.Label>
                            <div className={'text-muted py-3'}>
                                This will be reflected to your profile and have the ability to <br/> manage.
                            </div>
                            <Form.Group className="mb-3" controlId="formBasicName" {...getRootProps()}>

                                <Form.Control type={"file"} {...getInputProps()} id="fileUpload" className={"position-absolute opacity-0"} accept={"images/*"} name={"audioFile"} onChange={uploadSingleFile} />
                                <label for={"fileUpload"}  className="upload-btn w-100 bg-light">

                                    {file && file.name ?<span>{ file.name}</span> : <>
                                        <span>drag audio/upload</span></>}

                                </label>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Enter title" name={"title"} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicDec" >
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter description..."
                                    name={"description"}
                                    onChange={handleChange}
                                />
                            </Form.Group>


                        </Col>
                        <hr />
                        <Col className=" d-flex justify-content-end m-2">

                            <Button className='btn  btn-delete px-4 ' onClick={()=>saveImage()}>SAVE</Button>
                        </Col>
                    </Row>
                </Form>
            </section>
        </>
    )
}

export default withAuth(AddMusic)