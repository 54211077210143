import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import AuthService from "../../../../services/auth.service";
import {ENDPOINT} from "../../../../config/constants";
import {LOGIN_SUCCESS} from "../../../../actions/types";
import validator from "validator";
import isEmpty from "validator/es/lib/isEmpty";
import Loader from "../../../../Components/Loader";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

const Password = (props) => {
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const handlePassword = (evt) => {

        setPassword(evt.target.value);
    }
    const checkPassword = async (e) => {
        setIsLoading(true);

           await AuthService.postMethod(ENDPOINT.login, false, {
                email: JSON.parse(localStorage.getItem("user")).email,
                password: password,
                fcm_token: "",
            })
                .then((res) => {
                    //console.log(res.status);
                    //console.log(JSON.stringify(res));

                    if(res.status == 200){
                        console.log('res')
                        console.log(res)
                        localStorage.setItem("accessToken", JSON.stringify(res.data.accessToken));
                        localStorage.setItem("refreshToken", JSON.stringify(res.data.refreshToken));
                        localStorage.setItem("user", JSON.stringify(res.data.user));
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: { user: res.data.user },
                        });
                        //
                        // setTimeout(function () {
                            setIsLoading(false);
                            props.allowed()
                        props.setIcon("fas fa-unlock")
                        //     goToDashboard("/dashboard");
                        // }, 1000);
                        setIsLoading(false);

                    }else{
                            toast("Password is incorrect")
                        setIsLoading(false);

                    }


                })
                .catch((err) => {
                        setIsLoading(false);
                        //console.log(err);
                        // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
                    }
                );

    };
    if (isLoading == true)
        return <Loader isLoading={isLoading} />;
    return (
        <div>
            <Modal
                show={props.show}
                size='md'
                centered
            >
                <Modal.Body>
                    <Row className='text-center py-3  '>
                        <Col>
                            <h5 className='mb-4'>Enter Password</h5>
                            {/*<Form>*/}
                                <Form.Group className="mb-3" controlId="subAdminName">
                                    <Form.Control type="Password" placeholder="Enter Password" onChange={handlePassword} />
                                </Form.Group>
                            <Button onClick={props.onHide} variant={"outline-success"}>CANCEL</Button>
                            <Button className='btn  btn-delete ms-2' onClick={checkPassword}>Submit</Button>
                            {/*</Form>*/}

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Password
