import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {useLocation, useNavigate} from "react-router-dom";
import withAuth from "../../../withAuth";

const StakePurchased = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userId, setUserId] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {

        if (location.state) {
            console.log('location.state')
            setUserId(location.state.user_id)
            setData(location.state.data)
        }
    }, []);
    const back = () => {
        navigate('/users/User-Profile',{state:userId});
    };
    const columns = [
        {
            name: 'Date',
            selector: row => new Date(row.createdDate).toLocaleDateString(),
        },

        {
            name: 'Time',
            selector: row =>new Date(row.createdDate).toLocaleTimeString(),
        },

        {
            name: 'Map Name',
            selector: row => row.map?.name,
        },
        {
            name: 'Quantity',
            selector: row => row.qty,
        },



    ];

    return (
        <>
            <PageTitle title="Users" />


            <section>
                <Button onClick={back} variant="transparent font-18 fw-bold ps-0 mb-3" >
                    <i class="fas fa-long-arrow-left mx-2"></i>
                    Stake Purchased Log
                </Button>
                <DataTable
                    // show={props.show}
                    columns={columns}
                    data={data}
                    pagination
                    striped
                />
            </section>
        </>
    );
};

export default withAuth(StakePurchased);
