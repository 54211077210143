import React, {useEffect, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import dimond from 'Images/dimond.png'
import gem from 'Images/gems.png'
import Search from 'Components/Search';
import EditGems from './EditGems';
import EditStake from "../Stake/EditStake";


const GemsAndDimonds = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [row, setRow] = React.useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data]);
    const editRecord = (row) => {
        setRow(row)
        setModalShow(true)
    }
    const updateData = (object) => {
        setData(object)

    }
    const columns = [
        {
            name: "Image",
            selector: (row) => row.type === 'diamond' ?
                <div className="img-box p-3">
                    <img src={dimond} alt="img"/>
                </div> :
                <div className="img-box p-3">
                    <img src={gem} alt="img"/>
                </div>,
        },
        {
            name: "Gem QTY",
            selector: (row) => row.qty,
        },
        {
            name: "Coin Cost",
            selector: (row) => row.coin_value,
        },
        {
            name: "Action",
            selector: (row) => row.Action,
            cell: row => (
                <ul className={"action-List"}>
                    <li><Button  onClick={() => {
                        editRecord(row)
                    }} variant={"actionBtn text-success"}><i className={"fal fa-pen"}></i> </Button></li>
                </ul>
            ),
        },

    ];

    return (
        <>
            <Row className={'searchbox'}>
                <Col md={12} className={"my-2"}> <Search placeholder="Search" data={data} default={props.data}
                                                         searchColumns={['type', 'qty', 'coin_value'
                                                         ]} result={updateData}/> </Col>
            </Row>
            <DataTable
                columns={columns}
                data={data}
                pagination
                striped
            />
            {row && <EditGems show={modalShow} data={row} refresh={props.refresh()}  onHide={() => setModalShow(false)}/>}

        </>
    )

}

export default GemsAndDimonds