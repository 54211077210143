import React, {useEffect, useRef, useState} from 'react'
import user1 from "Images/user1.png";
import userlogo from "Images/userlogo.png";
import {Button, Form} from 'react-bootstrap';
import classes from "./index.module.scss";
import {generateItems, get_url_extension, imageUrl} from "../../../Helper/Helpers";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import {toast} from "react-toastify";
import pdfImg from "Images/pdf.svg";
import socket from "../../../Pages/Support/socket/socket";

const MsgPreview = (props) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [file, setFile] = useState([]);
  const [uploadFile, setUploadFile] = useState();
  const [ticket, setTicket] = useState(null);
  const [msg, setMsg] = useState('');
  const [ticketId, setTicketId] = useState(null);
  const messagesEndRef = useRef(null);
  const emitEvent = async (id)=> {
    if (msg) {
      socket.emit(ENDPOINT.support.emit_message,{
        user_id: '',
        message: msg,
        token: `Bearer ${JSON.parse(localStorage.getItem('accessToken'))}`,
        ticket_id: id
      })
      setMsg('')

    } else if (file.length>0){
      let data = new FormData();
      data.append('file', uploadFile);
      setFile([]);
      setUploadFile(null)
      await uploadAttachment(id, data)
    }
  };

  // socket.on('connect', ()=> {
  //     console.log('Connected');
  // })
  const uploadAttachment = async (id, data)=> {
    const url = (ENDPOINT.support.upload_attachment).replace(':id',id);
    await AuthService.postMethod(url, true,data)
        .then((res) => {

          socket.emit('supportFileTrigger', res.data)
        })
        .catch((err) => {
          toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
        });
  };
  useEffect(() => {
    //messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    if (props.rowUser?.id !== ticketId) {
      // handleMessages([])
      setCurrentItems([])
      socket.emit('support_users', {ticket_id:props.rowUser?.id})
    }
    if ((props.message?.data) && currentItems.length < (props.message?.data).length) {
      const dateFormatted = generateItems(props.message?.data);
      setCurrentItems([...dateFormatted.reverse()])
    }
    socket.on('msgSupport', (data)=>{
      setCurrentItems((prevState) => {
        if (prevState.some((obj) => obj.id === data.data.id)){
          return prevState
        }else {
          return    [...prevState, data.data]
        }
      });
    })
    handleChange(props)

  }, [props, currentItems]);


  useEffect(() => {
    //messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  }, [uploadFile]);

  // useEffect(() => {
  //     messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  //
  //     handleChange(props)
  // }, [currentItems, props]);


  const handleChange = (props) => {
    setTicket(props.rowUser)
    setTicketId(props.rowUser?.id)
  }
  const handleImage = (e) => {
    let ImagesArray = Object.entries(e.target.files).map((e) =>
        URL.createObjectURL(e[1])
    );
    if (e.target.files[0].type === 'application/pdf') {
      setFile(['pdf']);
    }else{
      setFile(ImagesArray);
    }
    setUploadFile(e.target.files[0])
  }
  const _handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      await emitEvent( ticket?.id)
    }
  }
  const _handleSubmit = async (e) => {

      await emitEvent( ticket?.id)

  }
  const handleMessages = (messages) => {
    setCurrentItems(messages)
  }
  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }
 async function changeToComplete(e) {
    const url = (ENDPOINT.support.mark_as_complete).replace(':id',ticketId);
    await AuthService.postMethod(url, true,[])
        .then((res) => {

          props.refresh();
        })
        .catch((err) => {
          toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
        });
  }
  const downloadImage = (url) => {
    var filename = url.substring(url.lastIndexOf('/')+1);
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch(console.error);
  }


  return (

  <div className={classes.msgPreview}>
        <div className={classes.chat_header}>
          <div className={classes.userInfo}>
            {/*<div className={classes.userImg}>*/}
            {/*  <img key={ticket?.id} src={imageUrl(ticket?.user?.picture,user1)} alt="username"/>*/}
            {/*</div>*/}
            <div className={classes.description}>
              <h6>{`${ticket?.user?.username}`}</h6>
            </div>
          </div>
          <div className={classes.description}>
            <h6>Support ID: {ticket?.id}</h6>
            <Form className='custom-form'>
              {['checkbox',].map((type) => (
                  <div key={`default-${type}`} className="mb-3">
                    <Form.Check
                        type={type}
                        id={`default-${type}`}
                        label={ticket?.status == 'completed'?'Completed':'Mark as complete'}
                        // checked={ticket?.status == 'completed'?true:false}
                        onChange={changeToComplete}
                    />
                  </div>
              ))}
            </Form>
          </div>
        </div>

        <div className={classes.mesgs}>
          <div className={classes.msg_history}>
            {currentItems.map((data) => (
                <div key={data?.id}>
            <div className={data?.role==='user'?classes.incoming:classes.outgoing}>
              {data?.role==='user' &&
              <div className={classes.userImg}>
                <img key={ticket?.id} src={imageUrl(data?.user?.picture,userlogo)} alt="username" />
              </div>
              }
              <div className={classes.description}>
                {data.message && data.attachment?.length===0 && <div className={classes.text}>{data?.message}
                  <div className={classes.time}> {new Date(data.createdDate).toLocaleString('en-US', {hour:'numeric', minute: 'numeric', hour12: true })}</div>
                </div>}
                {data.attachment?.length>0 && (get_url_extension(imageUrl(data.attachment[0])) ==='png' || get_url_extension(imageUrl(data.attachment[0])) ==='jpg' || get_url_extension(imageUrl(data.attachment[0])) ==='jpeg') &&
                    <div className={classes.text}>
                      {data?.message}
                      <div>
                        <div className={classes.fileImg}>
                          <img key={data?.id} src={imageUrl(data.attachment[0])} style={{maxHeight: '50px',}} alt="username"/>
                          <Button type={"button"} variant={'btnDownload'} onClick={() => downloadImage(imageUrl(data.attachment[0]))}><i className={'fal fa-download'}></i> </Button>
                        </div>
                      </div>
                      <div className={classes.time}> {new Date(data.createdDate).toLocaleString('en-US', {hour:'numeric', minute: 'numeric', hour12: true })}</div>
                    </div>}
                {data.attachment?.length>0 && (get_url_extension(imageUrl(data.attachment[0])) ==='pdf' || get_url_extension(imageUrl(data.attachment[0])) ==='txt') &&
                    <div className={classes.text}><a className={'btn btn-file'} href={imageUrl(data.attachment[0])} target = "_blank"><img src={pdfImg} width={'28'} alt="img" /> </a>
                      <div className={classes.time}> {new Date(data.createdDate).toLocaleString('en-US', {hour:'numeric', minute: 'numeric', hour12: true })}</div>
                    </div>}
              </div>

            </div>
                </div>

              ))}

            <div className="form-group previewBox">
              {file.length > 0 &&
                  file.map((item, index) => {
                    return (
                        <div className={`preview ${classes.previewImg}`} key={item}>
                          {item === 'pdf' && <img src={pdfImg}  alt="img" />}
                          {item !== 'pdf' && <img src={item}  alt="img" />}
                          <Button
                              type="button"
                              onClick={() => deleteFile(index)}
                          >
                            <i className={"fal fa-times"}></i>
                          </Button>
                        </div>
                    );
                  })}

            </div>
            {/*<div ref={messagesEndRef} />*/}

          </div>

          <div  className={classes.type_msg}>
            <div className={classes.btn_group}>
              <label type="button" htmlFor="inputPicture2" className={classes.btn}>
                <i className="fal fa-paperclip" id="inputAttach"></i>{" "}
                <input
                    id="inputPicture2"
                    type="file"
                    className="d-none"
                    accept=".pdf"
                    onChange={handleImage}
                />
              </label>

              <label htmlFor="inputPicture" type="button" className={classes.btn}>
                <i className="fal fa-image" id="imageUpload"></i>
                <input id="inputPicture" className="d-none" type="file" accept=".jpg,.jpeg,.png"
                       onChange={handleImage}/>
              </label>

            </div>
            <input
                type="text"
                className={classes.form_control}
                value={msg}
                onChange={(e) => setMsg(e.target.value)} onKeyDown={_handleKeyDown}
                placeholder="Write your message here"
            />
            <button className={`${classes.btn} ${classes.btnSend}`} onClick={_handleSubmit}  type="button" >
              <i className="fas fa-paper-plane"></i>
            </button>

          </div>
        </div>
      </div>
  )
}

export default MsgPreview