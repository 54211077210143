import React, {useEffect, useState} from 'react';
import classes from "./index.module.scss";


const ToolList = (props) => {
    const [toolList, setToolList] = useState([]);
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (props.data){
            // console.log(props)
            setUrl(window.location.origin)
            setToolList(props.data)
        }
    }, [
    ]);

    useEffect(() => {
        // console.log('data')
        // console.log(toolList)
    }, [
        toolList
    ]);

    return(
        <>
            <div className="mb-3">

            <ul className={classes.toolList}>
                {toolList && toolList.map((toolList) => (
                    <li>
                        <label className={classes.toolBox}>
                            <input
                                type={"checkbox"}
                                onChange={props.addTool}
                                name={toolList.name}
                                value={toolList.id}
                                id={toolList.id}
                                checked={props.selectedTools && props.selectedTools.some(item => item.tool_id === toolList.id)}
                            />
                            <img src={toolList.imageEntity?.path} alt={toolList.name} />
                        </label>
                    </li>
                ))}
            </ul>
            </div>

            {/*<div className="footer text-center py-3">*/}
            {/*    <button><a href=""><i className="fas fa-arrow-left"></i></a></button>*/}
            {/*    Page 1 of 10*/}
            {/*    <button><a href=""><i className="fas fa-arrow-right"></i></a></button>*/}
            {/*</div>*/}
        </>
    )
}

export default ToolList;