import React, {useEffect, useState} from 'react'
import {Col, Form, Row, Button, Card} from 'react-bootstrap'
import box from 'Images/Frame 50.png'
import TitlePopUp from './TitlePopUp';
import {EditableGridLayerTwoThree} from "./heatmap";
import {EditableGrid} from "../LayerFour/heatmap";
import {toast} from "react-toastify";
import classes from "../index.module.scss";

const LayerThree = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [mapData, setMapData] = useState({});
    const [zoomLevel, setZoomLevel] = useState(100);
    const [undo, setUndo] = useState(0);
    const [draft, setDraft] = useState("");
    const [toolData, setToolData] = useState(null);
    const [formData, setFormData] = useState({});
    const [assetList, setAssetData] = useState(null);
    const [currentAsset, setCurrentAsset] = useState(null);
    const [currentTool, setCurrentTool] = useState({});
    const [consoleList, setConsoleList] = useState([]);
    const [rewardTools, setRewardTools] = useState({});
    const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

    let resizeTimeout;

    window.addEventListener("resize", () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {

            changeSpanSize();
        }, 300);
    });
    const changeSpanSize = () => {
        const myDiv = document.getElementById("myDiv1");
        const currentWidth = myDiv.offsetWidth ?? 980;
        setItems([])
        let Array = []
        const numOfCols = Math.floor(currentWidth / zoomLevel) === 0 ? 10 : Math.floor(currentWidth / zoomLevel)
        // alert(numOfCols)
        for (let i = 0; i < numOfCols; i++) {

            Array.push(i);
        }
        console.log(items)
        setItems(Array)
    }

    const handleUndo = () => {
        consoleList.pop()
        setUndo(undo + 1);
    }
    const cancelRandom = () => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                ['is_random']: false
            }
        })
        setModalShow(false);
    }

    const saveRewardTool = (rewards) => {

        addToConsole("You have added random rewards")
        rewards.layer = 3

        setRewardTools(rewards)
        setModalShow(false)

    }
    const handleZoomIn = () => {
        changeSpanSize()
        setZoomLevel(zoomLevel + 10);
    }
    const addToConsole = (text) => {
        const updatedData = [...consoleList, {text: text}];
        consoleList.push({text: text})
        setConsoleList(updatedData);
    }

    const handleZoomOut = () => {
        changeSpanSize()

        if (zoomLevel - 20 > 0) {

            setZoomLevel(zoomLevel - 10);
        }
    }

    const handleChange = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        // console.log('event')
        // console.log(evt.target)

        if (name === 'is_random') {
            if (evt.target.checked) {
                setModalShow(true)
                value = true
            } else {
                addToConsole("You have removed random rewards")
                value = false
            }
        }
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })

    }
    const handleAssetChange = (evt) => {
        const asset = assetList.filter(data => [evt.target.value].includes(data.id))[0];
        setCurrentTool({})
        setCurrentAsset(asset)

    }
    const handleToolChange = () => {
        const tool = toolData.filter(data => [formData.tool_id].includes(data.id))[0];
        if (!tool) {
            toast('Select Tool to continue')

        }
        if (tool && formData.quantity && parseInt(formData.quantity) > 0) {
            setCurrentTool({tool: tool, qty: formData.quantity})
        } else {
            toast('Quantity is required and must be greater than 0')
        }
    }

    useEffect(() => {

        if (props.data) {
            changeSpanSize()

            setMapData(props.data)
        }

    }, [props.data])

    const saveLayerData = (value) => {
        setDraft(value)
    };
    const next = (data) => {
        // console.log('data log')
        // console.log(data)
        props.onSubmit(data)
        props.nextKey();
    };
    useEffect(() => {

        if (props.toolData) {
            // console.log(props.toolData)

            setToolData(props.toolData)
        }

    }, [props.toolData])
    useEffect(() => {


        // console.log('currentAsset')
        // console.log(currentAsset)


    }, [currentAsset])

    useEffect(() => {

        if (props.assets) {
            // console.log(props.assets)

            setAssetData(props.assets)
        }

    }, [props.assets])

    return (

        <div className='border rounded'>
            <Row className='p-4'>
                <Col md={5}>
                    <Form className='p-3 border rounded mb-3'>
                        <Row className={"inputRow"}>
                            <Col lg={12}>
                                <Form.Label> Substrate </Form.Label>
                                <Form.Select className='me-2' onChange={handleAssetChange}>
                                    <option disabled selected>Select to drag</option>
                                    {assetList && assetList.map((a, index) => (
                                        <option value={a.id}>{a.name + ' ' + a.version}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Button variant={"primary sm"}>DRAG ME</Button>
                        </Row>
                        <Row className={"inputRow"}>
                            <Col lg={8}>
                                <Form.Label> Tool </Form.Label>
                                <Form.Select onChange={handleChange} name={'tool_id'}>
                                    <option disabled selected>Select to add</option>

                                    {toolData && toolData.map((field, index) => (
                                        <option value={field.id}>{field.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col lg={4}>
                                <Form.Label> Qty </Form.Label>
                                <Form.Control name={'quantity'} type="text" onChange={handleChange}/>
                            </Col>
                            <Button className='small-text mx-1 px-4' onClick={() => handleToolChange()}>ADD</Button>
                        </Row>
                        <div className="col-md-4">
                            <Form.Label> Rewards </Form.Label>
                            <div className="form-check">
                                <Form.Check className='fw-bold'
                                            inline
                                            label="Random"
                                            type="checkbox"
                                            id="selectAll"
                                            value={true}
                                            onChange={handleChange}
                                            name={'is_random'}
                                            handleClick={"Check All"}
                                            checked={formData.is_random && formData.is_random === true}

                                />
                            </div>
                        </div>
                    </Form>
                    {formData.is_random &&
                        <Form className='p-3 border rounded'>
                            <Form.Label> Rewards Input </Form.Label>

                            <Row className={"inputRow"}>
                                <Col lg={12}>
                                    <Form.Label> Coins </Form.Label>
                                    <p>{rewardTools.coins}</p>

                                </Col>
                            </Row>
                            <Row className={"inputRow"}>
                                <Col lg={12}>
                                    <Form.Label> Diamonds </Form.Label>
                                    <p>{rewardTools.diamonds}</p>

                                </Col>
                            </Row>
                            <Row className={"inputRow"}>
                                <Col lg={12}>
                                    <Form.Label> Gems Stone </Form.Label>
                                    <p>{rewardTools.gems}</p>

                                </Col>
                            </Row>
                            {rewardTools.mapTools && rewardTools.mapTools.map((a, index) => (
                                <>
                                    <Row className={"inputRow"}>
                                        <Col lg={12}>
                                            <Form.Label> {toolData.filter(data => [a.tool_id].includes(data.id))[0]?.name} </Form.Label>
                                            <p>{a.qty}</p>
                                        </Col>
                                    </Row>
                                </>
                            ))}

                            <Row className={"inputRow"}>
                                <Col lg={12}>
                                    <Form.Label> Location </Form.Label>
                                    <p>Random</p>

                                </Col>
                            </Row>

                        </Form>
                    }
                </Col>
                <Col md={7}>
                    <Row className="align-items-end p-2">
                        <Col md={6}>
                            <Form.Label> Map Sizes </Form.Label>
                            <Form.Select aria-label="Default select example" disabled>
                                <option>{mapData.map_size}</option>

                            </Form.Select>
                        </Col>
                        <Col md={6} className="px-3">
                            <div className="d-flex justify-content-end">
                                <Button onClick={handleUndo}><i className={"fal fa-undo"}></i>UNDO</Button>
                                <span className="zoom-icon mx-1 justify-content-center align-items-center">
                    <a>
                      <i onClick={handleZoomIn} className="fal fa-search-plus"></i>
                    </a>
                  </span>
                                <span className="zoom-icon mx-1  justify-content-center align-items-center">
                    <a>
                      <i onClick={handleZoomOut} className="fal fa-search-minus"></i>
                    </a>
                  </span>
                            </div>
                        </Col>
                        <Col md={12}>

                            <div className={'mapBox'}>
                                {/*<GridCanvas />*/}
                                <div className={'xAxis'}>
                                    {items.map(item =>
                                        <>
                                            <span style={{width: zoomLevel + 'px'}}><b>{item}</b></span>
                                        </>
                                    )}
                                </div>
                                <div className={'yAxis'}>
                                    {items.map(item => {
                                        if (item <= 19) {
                                            return (
                                                <span style={{height: zoomLevel + 'px'}}>
          <b>{item}</b>
        </span>
                                            );
                                        } else {
                                            return null; // Exclude the item from rendering
                                        }
                                    })}
                                </div>

                                <EditableGridLayerTwoThree setLoading={props.setLoading} mapRandomRewards={rewardTools}
                                                           addToConsole={addToConsole} tool={currentTool}
                                                           asset={currentAsset} data={mapData} zoomLevel={zoomLevel}
                                                           defaultColor={"brown"} undo={undo} draft={draft} layer={1}
                                                           layerName={3} onSubmit={next}/>
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Card>

                    <Row className="p-4 user_data_profile">
                        <Col md={12}>
                            <Form.Group className="mb-3" controlId="formBasicDec">
                                <Form.Label>
                                    Console Log
                                </Form.Label>
                            </Form.Group>
                            <div style={{maxHeight: "300px", overflowY: "scroll"}}>
                                {consoleList && consoleList.reverse().map((a, index) => (
                                    <p className="fw-light text-muted">{a.text}</p>
                                ))}
                            </div>

                        </Col>


                    </Row>
                </Card>
                <hr/>
                <Col md={12} className=" d-flex justify-content-end">
                    <Button onClick={() => saveLayerData('true')} variant={"outline-success"} className='me-2'>SAVE AS
                        DRAFT</Button>
                    <Button className='btn  btn-delete ' onClick={() => saveLayerData('false')}>NEXT</Button>
                </Col>
            </Row>
            <TitlePopUp show={modalShow} tools={toolData} onHide={cancelRandom} saveRewards={saveRewardTool}/>
        </div>

    )
}

export default LayerThree