import React from "react";
import classes from "./index.module.scss";

const LogList = (props) => {
    return(
        <ul className={classes.logList}>
            {props.data?.map((data) => (
                <li>{new Date(data.createdDate).toLocaleDateString()} / {new Date(data.createdDate).toLocaleTimeString()}: {data.text}</li>
            ))}
        </ul>
    )
}

export default LogList;