import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
} from "react-bootstrap";
import img from "Images/tutorialImg.png";
import filterImg from "Images/Filter-icon.png";
import DataTable from "react-data-table-component";
import BanUserMadal from "Pages/User/BanUser";
import { useNavigate } from "react-router-dom";
import AddVideoTutorial from "./AddVideoTutorial";
import Delete from "../Tutorial/Delete";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import {toast} from "react-toastify";
import withAuth from "../../withAuth";

const ModalCLose = () => {};

const Tutorial = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = React.useState(0);

  const [durations, setDurations] = useState(new Map());
  const [id, setId] = React.useState("");

  const navigate = useNavigate();
  const AddVideoTutorial = () => {
    navigate("/add-video-tutorial");
  };
  const audioData = async () => {
    await AuthService.getMethod(ENDPOINT.tutorial.listing, true)
        .then((res) => {
          setData(res.data);
          fetchDurations(res.data);

        })
        .catch((err) => {
          //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
          // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
        });
  };
  const updateMusicData = async (id,MusicData) => {

    ENDPOINT.tutorial.edit.id = id;

    return await AuthService.patchMethod(ENDPOINT.tutorial.edit.url + ENDPOINT.tutorial.edit.id, true, MusicData)
        .then((res) => {
          if (res.status === 422){

            for (var i = 0; i < res.data.errors.length; i++) {
              var error = res.data.errors[i];
              for (var key in error) {
                // console.log(key + ": " + error[key]);
                toast(error[key])
              }
            }
          }else if(res.status === 200) {

            toast("Tutorial updated successfully!")
            audioData()

          }
        })
        .catch((err) => {


          console.log('errors')
          toast(err.response.data.errors[0])
        });
  };


  const getDuration = (url) => {
// Create a non-dom allocated Audio element
    var au = document.createElement('video');
    var duration
// Define the URL of the MP3 audio file
    au.src = url;

// Once the metadata has been loaded, display the duration in the console
    au.addEventListener('loadedmetadata', function(){
      // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
      duration = au.duration;

      // example 12.3234 seconds
      console.log("The duration of the song is of: " + duration + " seconds");
      durations.set(url,secondsToTime(duration))

      // Alternatively, just display the integer value with
      // parseInt(duration)
      // 12 seconds
    },false);
    console.log("The  of the song is of: " + duration + " seconds");

    // return duration


  };
  const deleteRouteList = async (id) => {


    ENDPOINT.tutorial.delete.id = id;
    await AuthService.deleteMethod(ENDPOINT.tutorial.delete.url + ENDPOINT.tutorial.delete.id, true)
        .then((res) => {
          audioData();
          // console.log(res.data);

          toast('Tutorial deleted successfully!')

        })
        .catch((err) => {
          // console.log(" ~ file: index.js:38 ~ deleteRouteList ~ err", err)
          // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
        });

    setModalShow(false)


  };
  const deleteRouteList2 = async (id) => {

    setModalShow(true)
    setId(id)


  };
  function secondsToTime(e){
    const  m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');

    return m + ':' + s;
    //return `${h}:${m}:${s}`;
  }
  const fetchDurations = async () => {
    for (const row of data) {
      await getDuration(row.video?.path);
    }
    setRefresh(refresh + 1)

  };
  useEffect(() => {
    audioData();
  }, []);

  useEffect(() => {
    if (refresh < 3) {
      audioData();
    }
  }, [refresh]);


  useEffect(() => {
    // Fetch the durations of the audio files here using the getDuration function
    if (data) {
      fetchDurations(data);
    }
  }, [data]);
  const columns = [
    {
      name: "Video Tutorial ",
      selector: (row) => <div className={"d-flex align-items-center"}>
        <div className="img-box mx-2">
          <iframe src={row.video?.path}>
            {/*<source  src={row.video?.path}/>*/}
          </iframe>
        </div>
        {row.title}
      </div>,
    },
    {
      name: "Duration",
      selector: (row) => durations.get(row.video?.path) ? durations.get(row.video?.path) : "N/A",
    },
    {
      name: "Date Upload",
      selector: (row) => new Date(row.createdDate).toLocaleString("en-US", { month: 'long',day:'numeric',year:'numeric' }),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
          <div className="drop-down-btn">
            <Dropdown>
              <Dropdown.Toggle variant="publish" id="dropdown-basic" className="drop-down-btn">
                {row.status}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {row.status !== 'Published' && <Dropdown.Item onClick={()=>updateMusicData(row.id,{status:'Published'})}>Published</Dropdown.Item>}
                {row.status !== 'Unpublished' && <Dropdown.Item onClick={()=>updateMusicData(row.id,{status:'Unpublished'})}>Unpublished</Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>
          </div>
      ),
    },

    {
      name: <strong>Action</strong>,
      selector: (row) => row.action,
      cell: row => (
          <Button variant={"actionBtn text-danger"} onClick={() => {
            deleteRouteList2(row.id)
          }}> <i class="far fa-trash"></i></Button>
      ),
    },
  ];

  return (
      <>
        <PageTitle title="Tutorial" />
        <section>
          <Row className="mb-3 ">
            <Col>
              <Row className="align-items-center">
                <Col md={12} className=" d-flex justify-content-end">
                  <Button
                      onClick={AddVideoTutorial}
                      className="btn  btn-delete  mx-2 "
                  >
                    ADD VIDEO
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <DataTable
              columns={columns}
              data={data}
              pagination
              striped
          />
        </section>
        {/*<BanUserMadal show={modalShow} onHide={() => setModalShow(false)} />*/}
        {modalShow && <Delete show={modalShow} onHide={() => setModalShow(false)} refreshRecord={() => deleteRouteList(id)}/>}

      </>
  );
};

export default withAuth(Tutorial);
