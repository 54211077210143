import React, {useEffect, useState} from 'react'
import { EditorState,convertToRaw,convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button} from "react-bootstrap";
import classes from "../index.module.scss";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import {toast} from "react-toastify";
const GuideLines = (props) => {
    const [editorState, setEditorState] = useState(() => {
        if (props.data) {
            return EditorState.createWithText(props.data);
        } else {
            return EditorState.createEmpty();
        }
    });
    const [showToolbar, setShowToolbar] = useState(false);
    useEffect(() => {
        if (props.data){
            setEditorState(EditorState.createWithText(props.data))
        }
    }, [props.data]);
    const handleSave = async () => {
        const contentState = editorState.getCurrentContent();
        const rawData = convertToRaw(contentState);
        //const json = JSON.stringify(rawData);
        const keyTextArray = rawData.blocks.map(block => block.text);
        const json = keyTextArray.join('\n');
        //const json = rawData.blocks;
        let formData = {[props.type]: json};
       // console.log('combinedWithNewline',combinedWithNewline);

        return await AuthService.patchMethod(ENDPOINT.admin_guidelines.terms_conditions, true, formData)
            .then((res) => {
                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 200) {
                    setShowToolbar(false)

                    toast('Record updated successfully!')
                }
            })
            .catch((err) => {


                toast(err.response.data.errors[0])

            });

    }
  return (
    <>
            {showToolbar ?
                <>
                <Button className="px-5 editButton" onClick={handleSave}>Save</Button>

                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
                        inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        blockType: {
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        },
                        textAlign: {
                            options: ['left', 'center', 'right', 'justify'],
                        },
                        colorPicker: {
                            colors: [
                                'rgba(0, 0, 0, 1.0)',
                                'rgba(255, 255, 255, 1.0)',
                                'rgba(255, 0, 0, 1.0)',
                                'rgba(0, 255, 0, 1.0)',
                                'rgba(0, 0, 255, 1.0)',
                            ],
                        },
                    }}
                /> </>:
                <>
                <Button className="px-5 editButton" onClick={()=>setShowToolbar(true)}>EDIT</Button>

                <Editor
                    editorState={editorState}
                    readOnly={true}
                    toolbarHidden
                />
                </>
            }

    </>
    )
}

export default GuideLines