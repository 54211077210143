import React, {useEffect, useState} from 'react'
import { Col, Form, Row, Button } from 'react-bootstrap'
import box from 'Images/Frame 53.png'
import TitlePopUp from '../LayerThree/TitlePopUp';
import PopUp from './PopUp';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import ImageGrid from "./heatmap";
import CanvaGrid from "./heatmapV2";


const LayerOne = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [mapData, setMapData] = useState({});
  const [zoomLevel, setZoomLevel] = useState(100);
  const [undo, setUndo] = useState(0);
  const [draft, setDraft] = useState("");
  const [toolData, setToolData] = useState(null);
  const [formData, setFormData] = useState({});
  const [assetList, setAssetData] = useState(null);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [numRows, setNumRows] = useState(20);
  const [numCols, setNumCols] = useState(20);
  const [currentTool, setCurrentTool] = useState({});
  const [consoleList, setConsoleList] = useState([]);
  const [rewardTools, setRewardTools] = useState({});
  const [items, setItems] = useState([1,2,3,4,5,6,7,8,9]);
  let resizeTimeout;

  window.addEventListener("resize", () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {

      changeSpanSize();
    }, 300);  });
  const  changeSpanSize = () =>{
    const myDiv = document.getElementById("layerOne");
    const currentWidth = myDiv?.offsetWidth ?? 980;
    setItems([])
    let  Array = []
    const numOfCols = Math.floor(currentWidth/zoomLevel) === 0 ? 10:Math.floor(currentWidth/zoomLevel)
    // alert(numOfCols)
    for (let i = 0; i < numOfCols; i++) {

      Array.push(i);
    }
    // console.log(items)
    setItems(Array)
  }

  const handleUndo = () => {
    consoleList.pop()
    setUndo(undo+1);
  }

  const handleZoomIn = () => {
    changeSpanSize()
    setZoomLevel(zoomLevel + 10);
  }
  const addToConsole = (text) => {
    const updatedData = [...consoleList, {text:text}];
    consoleList.push({text:text})
    setConsoleList(updatedData);
  }

  const handleZoomOut = () => {

    if(zoomLevel - 20 > 0) {
      changeSpanSize()

      setZoomLevel(zoomLevel - 10);
    }
  }

  const handleAssetChange = (type) => {
    const asset = assetList.filter(data => [formData[type]].includes(data.id))[0];
    setCurrentTool({})
    setCurrentAsset({asset:asset,qty:formData[type+'_type'] ?? 1})

  }
  const handleToolChange = () => {
    const tool = toolData.filter(data => [formData.tool_id].includes(data.id))[0];
    if (!tool){
      toast('Select Tool to continue')

    }
    if (tool && formData.tool_qty && parseInt(formData.tool_qty) > 0) {
      setCurrentTool({tool: tool, qty:formData.tool_qty})
    }else{
      toast('Quantity is required and must be greater than 0')
    }
  }

  useEffect(() => {

    if (props.data) {
      const size = props.data?.map_size?.split("x");
      setNumRows(props.data?.map_size && size[0])
      setNumCols(props.data?.map_size && size[1])
      setMapData(props.data)
    }

  }, [props.data])

  const saveLayerData = (value) => {
    setDraft(value)
    setModalShow(false)
  };
  const handleChange = (evt) => {
    let value = evt.target.value;
    let name = evt.target.name;
    // console.log('event')
    // console.log(evt.target)


    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const next = (data) => {
    // console.log('data log')
    // console.log(data)
    props.onSubmit(data)
    props.nextKey();
  };
  const data = [
   // 'https://i.pinimg.com/564x/f7/75/7d/f7757d5977c6ade5ba352ec583fe8e40.jpg',
   //    'https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8&w=1000&q=80'
   'https://s3-alpha-sig.figma.com/img/13b8/dc96/91373930f25b2cea5b7dccf49f0dda6d?Expires=1678665600&Signature=HeCMHuTRiaK-SWn4e8rWAz~WzthxrJjAwUygcNRJo7cDsnB9XBzCrI7-0ZvUbXwRPoyC7aHb~WiAvE~YHn~XXIdUWh~3sw3ldxP9GMbkrbMbpsytQ9nc1quDX~gtBxAPHeG5gAZ8rfS~4P7oPD67n8o57dogeddCBDT7ZsxByXlmmwNcDXw6nAuK2z5lVAO8ufaT4ikwOokePDBNTnoYi05QrFU9ZiYZp5ebEU05oTcoQw186c9ab8vXfSc2Y0TCf5KaKD2tAi~OAOhbzfIIDv6i-EBvHlytVJvnOc0mW6XLjiR9g8SgwUq7ZlImJDtA2bdSWal5bwFz0lLcCMp3zA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'
    ];


  useEffect(() => {

    if (props.toolData) {

      setToolData(props.toolData)
    }

  }, [props.toolData])
  useEffect(() => {


    // console.log(currentAsset)



  }, [currentAsset])

  useEffect(() => {

    if (props.assets) {

      setAssetData(props.assets)
    }

  }, [props.assets])
  return (

      <div className='border p-4'>
        <Row>
          <Col md={5} className='border rounded p-3'>
            <Form>
              <Row className={"inputRow"}>
                <Col lg={12}>
                  <Form.Label> <strong>Substrate</strong></Form.Label>
                  <Form.Select name={'substrate'} onChange={handleChange}>
                    <option disabled selected>Select to drag</option>
                    {assetList && assetList.filter(data => ["substrates"].includes(data.asset_type)).map((a, index) => (
                        <option value={a.id}>{a.name+' '+a.version}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Button onClick={()=>handleAssetChange('substrate')}>ADD</Button>
              </Row>
              <Row className={"inputRow"}>
                <Col lg={8}>
                  <Form.Label><strong>Infrastructure</strong> </Form.Label>
                  <Form.Select name={'infrastructure'} onChange={handleChange}>
                    <option disabled selected>Select to drag</option>
                    {assetList && assetList.filter(data => ["infrastructure"].includes(data.asset_type)).map((a, index) => (
                        <option value={a.id}>{a.name+' '+a.version}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col lg={4}>
                  <Form.Label> <strong>Qty</strong> </Form.Label>
                  <Form.Control type="text" name={'infrastructure_type'} onChange={handleChange}/>
                </Col>
                <Button onClick={()=>handleAssetChange('infrastructure')}>ADD</Button>
              </Row>
              <Row className={"inputRow"}>
                <Col md={8}>
                  <Form.Label><strong>Tools </strong> </Form.Label>
                  <Form.Select aria-label="Default select example" className='me-2' name={'tool_id'} onChange={handleChange}>
                    {toolData && toolData.map((field, index) => (
                        <option value={field.id}>{field.name}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="exampleForm.ControlInput1" className='me-2'>
                    <Form.Label><strong>Qty</strong> </Form.Label>
                    <Form.Control type="text" name={'tool_qty'} onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Button onClick={handleToolChange} >ADD</Button>
              </Row>
              <Row className={"inputRow"}>
                <Col md={8}>
                  <Form.Label><strong>Equipment </strong> </Form.Label>
                  <Form.Select aria-label="Default select example" className='me-2' name={'equipment'} onChange={handleChange}>
                    <option disabled selected>Select to drag</option>
                    {assetList && assetList.filter(data => ["equipment"].includes(data.asset_type)).map((a, index) => (
                        <option value={a.id}>{a.name+' '+a.version}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="exampleForm.ControlInput1" className='me-2'>
                    <Form.Label><strong>Qty</strong> </Form.Label>
                    <Form.Control type="text" name={'equipment_type'} onChange={handleChange}/>
                  </Form.Group>
                </Col>
                <Button onClick={()=>handleAssetChange('equipment')} >ADD</Button>
              </Row>
              <Row className={"inputRow"}>
                <Col md={8}>
                  <Form.Label><strong>Animals</strong> </Form.Label>
                  <Form.Select aria-label="Default select example" className='me-2' name={'animals'} onChange={handleChange}>
                    <option disabled selected>Select to drag</option>
                    {assetList && assetList.filter(data => ["animals"].includes(data.asset_type)).map((a, index) => (
                        <option value={a.id}>{a.name+' '+a.version}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="exampleForm.ControlInput1" className='me-2'>
                    <Form.Label><strong>Qty</strong> </Form.Label>
                    <Form.Control type="text" name={'animals_type'} onChange={handleChange}/>
                  </Form.Group>
                </Col>
                <Button  onClick={()=>handleAssetChange('animals')}>ADD</Button>
              </Row>
              <Row className={"inputRow"}>
                 <Col md={8}>
                 <Form.Label><strong>Flora</strong> </Form.Label>
                  <Form.Select aria-label="Default select example" className='me-2' name={'landscape'} onChange={handleChange}>
                    <option disabled selected>Select to drag</option>
                    {assetList && assetList.filter(data => ["landscape"].includes(data.asset_type)).map((a, index) => (
                        <option value={a.id}>{a.name+' '+a.version}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={4}>
                    <Form.Label><strong>Qty</strong> </Form.Label>
                    <Form.Control type="text"  name={'landscape_type'} onChange={handleChange} />
                </Col>
                <Button onClick={()=>handleAssetChange('landscape')}>ADD</Button>
              </Row>
            </Form>
          </Col>
          <Col md={7}>
            <Row>
              <Col md={6}>
                <Form.Label>Map Sizes</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option selected>{mapData.map_size}</option>

                </Form.Select>
              </Col>
              <Col md={6} className={"d-flex align-items-center justify-content-end"}>
                  <Button variant={"primary m-1"} onClick={handleUndo}><i className={"fal fa-undo"}></i> UNDO</Button>
                  <Button variant={"icon m-1 font-18"} onClick={handleZoomIn}><i className="fal fa-search-plus"></i></Button>
                  <Button variant={"icon m-1 font-18"} onClick={handleZoomOut}><i className="fal fa-search-minus"></i></Button>
              </Col>
              <Col md={12}>
                  {/*<ImageGrid imageUrls={data} cellSize={zoomLevel} addToConsole={addToConsole} selectedTool={currentTool} asset={currentAsset} data={mapData}  undo={undo} draft={draft} onSubmit={next} />*/}
                <div className={'mapBox'}>
                  {/*<GridCanvas />*/}
                  <div className={'xAxis'}>
                    {items.map(item =>
                        <>
                          <span  style={{ width: zoomLevel+'px' }}><b>{item}</b></span>
                        </>
                    )}
                  </div>
                  <div className={'yAxis'}>
                    {items.map(item =>
                        <>
                          <span  style={{ height: zoomLevel+'px' }}><b>{item}</b></span>
                        </>
                    )}
                  </div>
                  { mapData && <CanvaGrid  setLoading={props.setLoading} width={numCols} height={numRows} cellSize={zoomLevel} images={data} addToConsole={addToConsole} refresh={consoleList} selectedTool={currentTool} asset={currentAsset} data={mapData}  undo={undo} draft={draft} onSubmit={next}/> }
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={12} className=" p-4 mt-3  border rounded mt-5">
            <h5>Console Log</h5>
            <div style={{maxHeight: "300px", overflowY:"scroll"}}>
              {consoleList && consoleList.reverse().map((a, index) => (
                  <p className="fw-light text-muted">{a.text}</p>
              ))}
            </div>
          </Col>
        </Row>

        <hr />
        <div className="col-md-12 d-flex justify-content-end">
          <Button onClick={()=>saveLayerData('true')} variant={"outline-success"}>SAVE AS DRAFT</Button>
          <Button onClick={() => setModalShow(true)} variant="primary" type="submit" className='btn  btn-delete ms-2'>COMPLETE</Button>
        </div>
        <PopUp show={modalShow} mapData={mapData} onHide={() => setModalShow(false)} draft={saveLayerData} />
      </div>

  )
}

export default LayerOne