import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import { Button, Card, Col, Nav, Row, Tab } from "react-bootstrap";
import Faqs from "./Faqs";
import PrivacyPolicy from "./Privacy Policy";
import TermAndCondition from "./TermAndCondition";
import classes from "./index.module.scss";
import Guideline from "./TermAndCondition";
import {convertToRaw} from "draft-js";
import {ENDPOINT} from "../../config/constants";
import AuthService from "../../services/auth.service";
import {toast} from "react-toastify";
import withAuth from "../../withAuth";

const GuideLines = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    getGuideLines()
  }, []);
  const getGuideLines = async () => {


    return await AuthService.getMethod(ENDPOINT.admin_guidelines.terms_conditions, true)
        .then((res) => {
          if (res.status === 422) {

            for (var i = 0; i < res.data.errors.length; i++) {
              var error = res.data.errors[i];
              for (var key in error) {
                // console.log(key + ": " + error[key]);
                toast(error[key])
              }
            }
          } else if (res.status === 200) {
            setData(res.data)
          }
        })
        .catch((err) => {


          toast(err.response.data.errors[0])

        });

  }
  return (
    <>
      <PageTitle title={"GuideLines"} />
      <section>
        <div className={` ${classes.editorBox}`}>
          <Card style={{clear: "both"}} className={`medium-border`}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Term">
              <Row>
                <Col md={3}>
                  <Nav variant="pills" className="flex-column vertical-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="Term">Terms & Condition</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Policy">Privacy Policy</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Faq">FAQs</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="Term">
                      <Guideline type={'terms_and_conditions'} data={data.terms_and_conditions ?? ''} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Policy">
                      <Guideline type={'privacy_policy'} data={data.privacy_policy ?? ''} />
                    </Tab.Pane>

                    <Tab.Pane eventKey="Faq">
                      <Guideline type={'faqs'} data={data.faqs ?? ''} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
        </div>
      </section>
    </>
  );
};

export default withAuth(GuideLines);
