import Search from 'Components/Search'
import React, {useEffect, useState} from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import img from "Images/coin-img.png";
import EditCoin from './EditCoin';

const Coins = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [row, setRow] = React.useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (props.data){
            setData(props.data)
        }
    }, [props.data]);
  const editRecord = (row) => {
      setRow(row)
      setModalShow(true)
  }
    const updateData = (object) => {
        setData(object)

    }
  const columns = [
    {
      name: "Image",
      selector: (row) => row.Images,
      cell: (row) =>(
          <div className="img-box p-3">
            <img src={img} alt="img" />
          </div>
      ),
    },
    {
      name: "QTY",
      selector: (row) => row.qty,
    },
    {
      name: "Pound Equivalent",
      selector: (row) => '£'+row.coin_value,
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      cell: row => (
          <ul className={"action-List"}>
            <li><Button  onClick={() => {
                editRecord(row)
            }} variant={"actionBtn text-success"}><i className={"fal fa-pen"}></i> </Button></li>
          </ul>
      ),
    },

  ];

  return (
      <>
        <Row className={'searchbox'}>
            <Col md={12} className={"my-2"}> <Search placeholder="Search" data={data}  default={props.data} searchColumns={['type','qty','coin_value'
            ]} result={updateData}/> </Col>
        </Row>
        <DataTable
            columns={columns}
            data={data}
            pagination
            striped
        />
          {row && <EditCoin show={modalShow} data={row} refresh={props.refresh()}  onHide={() => setModalShow(false)}/>}
      </>
  )

}

export default Coins