import React, {useEffect, useState} from 'react'
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import {toast} from "react-toastify";

const BanFinalStep = (props) => {
    const [data, setData] = useState({});
    const blockUser = async () => {

        let dateParts = data.date.toLocaleDateString().split('/');
        let timeParts = data.time.split(':');

// Parse the date and time strings as separate values
        let dateTime = new Date(dateParts[2], dateParts[1]-1, dateParts[0], timeParts[0], timeParts[1]);

// Format the date as a string in a different format with leading zeros
        let formattedDate = dateTime.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        let formattedTime = dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        let formattedDateTimeStr = `${formattedDate}, ${formattedTime}`;

// Set the formatted date and time string in the form data
        let formData = { ban_until_date: formattedDateTimeStr, id: props.id.toString() };


        ENDPOINT.users_route.edit.id = props.id.toString()
        await AuthService.patchMethod(ENDPOINT.users_route.edit.url + ENDPOINT.users_route.edit.id, true,formData)
            .then((res) => {
                toast("User Blocked until "+formattedDateTimeStr)
                props.onHide()
            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

    };
    useEffect(() => {
        setData(props);
    }, [props
    ]);
  return (
    <Modal
    show={props.show}
    size='md'
    centered
>
    <Modal.Body>
        <Row className=' py-3 text-center '>
            <Col>
                <h5 className='mb-4 '>Are you sure?</h5>
                <p>By banning this account, the end user can resume playing the game on {data.date && data.date.toLocaleString("en-US", {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                })} at {data && data.time}</p>
             
                <Button onClick={props.onHide} variant={"outline-success"} className="me-3 px-5">CANCEL</Button>
                <Button className='btn  btn-delete px-5' variant={'danger'} onClick={blockUser}>BAN ACCOUNT</Button>
            </Col>
        </Row>
    </Modal.Body>
</Modal>
  )
}

export default BanFinalStep