import PageTitle from 'Components/Pagetitle'
import { Tab, Tabs } from "react-bootstrap";
import React, {useEffect, useState} from 'react'
import CurrentMap from 'Pages/Maps/currentMap';
import ExpiredMap from 'Pages/Maps/ExpiredMap';
import Draft from 'Pages/Maps/Draft';
import MapInformation from './MapInfo';
import PrizeInformation from './PrizeInfo';
import LayerFour from './LayerFour';
import LayerThree from './LayerThree';
import LayerTwo from './LayerTwo';
import LayerOne from './LayerOne';
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import Loader from "../../Components/Loader";
import withAuth from "../../withAuth";

const BuildMap = () => {
    const location = useLocation();
    const [key, setKey] = useState('MapInformation');
    const [mapData, setMapData] = useState({});
    const [toolData, setToolData] = useState(null);
    const [assetData, setAssetData] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isAllowed, setIsAllowed] = useState(true);
    const [allowPrize, setAllowPrize] = useState(true);
    const [layerFourIconClass, setLayerFourIconClass] = useState("fas fa-lock");

    const changeKey = (key) => {
        setKey(key)
    }

 const setMapDataFunc = (formData) => {

        setMapData(formData)

 }
const setLoading = (action) => {

    setIsLoading(action)

 }
 const setIcon = (action) => {

    setLayerFourIconClass(action)

 }

    const assetsRouteGetData = async () => {

        await AuthService.getMethod(ENDPOINT.assets.listing, true)
            .then((res) => {
                setAssetData(res.data)
            })
            .catch((err) => {

            });
    };

    const toolsData = async () => {


        await AuthService.getMethod(ENDPOINT.tools.listing, true)
            .then((res) => {
                toast(res.statusCode)
                if (res.statusCode === 401 && res.message === 'Unauthorized'){
                    toast('check')
                    navigate('/');

                }
                setToolData(res.data)
            })
            .catch((err) => {

            });

    };
    const getMapData = async (id) => {


        ENDPOINT.maps.edit.id = id.toString();

        return await AuthService.getMethod(ENDPOINT.maps.edit.url + ENDPOINT.maps.edit.id, true)
            .then((res) => {
                toast(res.statusCode)
                if (res.statusCode === 401 && res.message === 'Unauthorized'){
                    toast('check')
                    navigate('/');

                }
                // console.log('res.data')
                // console.log(res.data)

                setMapData(res.data)
            })
            .catch((err) => {

            });

    };
    const determineNextKey = () => {
        if (allowPrize) {
            return 'LayerFour';

        } else {
            return 'PrizeInformation';
        }
    };

 useEffect(() => {
        if (location.state.mapData){

            getMapData(location.state.mapData.id)
        }
    },[])
useEffect(() => {
    if (mapData.map_size && mapData.map_size !== '50x100'){

        setIsAllowed(false)
        setAllowPrize(true)
    }
    if (mapData && mapData.player_selected_prize===true) {
        setAllowPrize(false)
    }
    },[mapData])


    useEffect(() => {
        toolsData();
        assetsRouteGetData()
        // player_selected_prize
    }, [
    ]);
    if (isLoading == true)
        return <Loader isLoading={isLoading} />;
    return (
        <>
            <PageTitle title={"Building a Map"} />
            <section>
                <Tabs
                    defaultActiveKey={key}
                    className={'tabradius'}
                    activeKey={key}
                    onSelect={(key) => setKey(key)}

                >
                    <Tab  eventKey="MapInformation" title="Map Information ">
                        <MapInformation setLoading={setLoading} toolData={toolData} data={mapData} onSubmit={setMapDataFunc} nextKey={() => changeKey(determineNextKey())}/>
                    </Tab>
                    <Tab eventKey="PrizeInformation"  title="Prize Information" disabled={allowPrize}>
                        <PrizeInformation setLoading={setLoading} toolData={toolData}  data={mapData} onSubmit={setMapDataFunc} nextKey={()=>changeKey('LayerFour')}/>
                    </Tab>
                    <Tab eventKey="LayerFour" disabled={isAllowed} title={<span>Layer Four <i className={layerFourIconClass} /> </span>}>
                        <LayerFour setIcon={setIcon} setLoading={setLoading} toolData={toolData} data={mapData} onSubmit={setMapDataFunc} nextKey={()=>changeKey('LayerThree')}/>
                    </Tab>
                    <Tab eventKey="LayerThree" disabled={isAllowed} title="Layer Three">
                        <LayerThree setLoading={setLoading} assets={assetData?.filter(data => ["substrates"].includes(data.asset_type))} toolData={toolData}  data={mapData} onSubmit={setMapDataFunc} nextKey={()=>changeKey('LayerTwo')}/>
                    </Tab>
                    <Tab eventKey="LayerTwo" disabled={isAllowed} title="Layer Two">
                        <LayerTwo setLoading={setLoading} assets={assetData?.filter(data => ["substrates"].includes(data.asset_type))} toolData={toolData} data={mapData} onSubmit={setMapDataFunc} nextKey={()=>changeKey('LayerOne')}/>
                    </Tab>
                    <Tab eventKey="LayerOne" disabled={isAllowed} title="Layer One">
                        <LayerOne setLoading={setLoading} toolData={toolData} data={mapData} assets={assetData} onSubmit={setMapDataFunc}/>
                    </Tab>
                </Tabs>
            </section>
        </>

    )
}

export default withAuth(BuildMap)