import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import img from "Images/adsIMG.png";
import filterImg from "Images/Filter-icon.png";
import DataTable from "react-data-table-component";
import BanUserMadal from "Pages/User/BanUser";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import {toast} from "react-toastify";
import Delete from "./Delete";
import withAuth from "../../withAuth";




const ModalCLose = (() => {


})

const Ads = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [id, setId] = React.useState("");

    const deleteRouteList = async (id) => {


        ENDPOINT.ads.delete.id = id;
        await AuthService.deleteMethod(ENDPOINT.ads.delete.url + ENDPOINT.ads.delete.id, true)
            .then((res) => {
                audioData();
                // console.log(res.data);

                toast('Ad deleted successfully!')

            })
            .catch((err) => {
                // console.log(" ~ file: index.js:38 ~ deleteRouteList ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

        setModalShow(false)


    };
    const deleteRouteList2 = async (id) => {

        setModalShow(true)
        setId(id)


    };
    const AddMusic = () => {
        navigate('/ads/add-exchange');
    };
    const audioData = async () => {
        await AuthService.getMethod(ENDPOINT.ads.listing, true)
            .then((res) => {
                setData(res.data);

            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };
    useEffect(() => {
        audioData();
    }, []);

    const columns = [
        {
            name: "Advertisement Video",
            selector: (row) => <div className={"d-flex align-items-center"}>
                <div className="img-box mx-2">
                    {/*<iframe src={row.link}>*/}
                    {/*</iframe>*/}

                    <video width="150" height="75" controls>
                        <source src={row.link} type="video/mp4"/>
                        <source src={row.link} type="video/ogg"/>

                    </video>
                </div>
            </div>,
        },
        {
            name: "Ads From",
            selector: (row) => row.ad_from,
        },
        {
            name: "Gem Value",
            selector: (row) => row.gems,
        },



        {
            name: <strong>Action</strong>,
            selector: row => row.action,
            cell: row => (
                <Button variant={"actionBtn text-danger"} onClick={() => {
                    deleteRouteList2(row.id)
                }}> <i class="far fa-trash"></i></Button>
            ),
        },




    ];

    return (
        <>
            <PageTitle title="Ads Exchange" />

            <section>
                <Row className="mb-3 ">
                    <Col>
                        <Row className="align-items-center">
                            <Col lg={2}>
                                <Form.Group className="d-flex" controlId="exampleForm.ControlInput1">
                                    <Form.Label> <strong>Default Gem Reward</strong> </Form.Label>

                                </Form.Group>
                            </Col>
                            <Col md={4} className='mb-md-0 mb-3'>
                                <Form.Control type="text" />
                            </Col>
                            <Col md={2} xs={4}>
                                <Button className='btn  btn-delete '>SAVE</Button>
                            </Col>
                            <Col lg={4} md={6} xs={8} className=" d-flex justify-content-end">
                                <Button onClick={AddMusic} className='btn  btn-delete  mx-2 '>ADD ADVERTISEMENT</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <DataTable
                    columns={columns}
                    data={data}
                    striped
                />
            </section>

            {modalShow && <Delete show={modalShow} onHide={() => setModalShow(false)} refreshRecord={() => deleteRouteList(id)}/>}




        </>
    )
};

export default withAuth(Ads);
