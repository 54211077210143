import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import {toast} from "react-toastify";
const GiveItemModal = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const handleSubmit = async () => {
 return await AuthService.postMethod(ENDPOINT.users_route.addFreeTool, true, {tool_id:props.tool?.id,user_id:props.user?.id,coins:parseInt(props.coins)??null,qty:1,is_free:true})
          .then((res) => {
            if (res.status === 422) {

              for (var i = 0; i < res.data.errors.length; i++) {
                var error = res.data.errors[i];
                for (var key in error) {
                  // console.log(key + ": " + error[key]);
                  toast(error[key])
                }
              }

            } else if (res.status === 201) {
              toast('Tool gifted successfully!')
                setTimeout(() => {

                    props.refresh()

                }, 1000);
                props.onHide()



            }

          })
          .catch((err) => {


            toast(err.response.data.errors[0])

          });
    }


  return (
    <Modal show={props.show} size="md" centered>
      <Modal.Body>
        <Row className="text-center py-3  ">
          <Col>
            <h5 className="mb-4">
              Are you sure you want to send <br /> {props.coins && props.coins+' coins,'} {props.tool?.name} to {props.user?.username}?
            </h5>
            <Button
              onClick={props.onHide}
              variant={"outline-success"}
              className="me-3 px-5"
            >
              No
            </Button>
            <Button
              className="btn  btn-delete px-5"
              onClick={handleSubmit}
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default GiveItemModal;
