import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Form, Tab, Tabs} from "react-bootstrap";
import ToolList from "../../../Components/ToolList";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import ToolTable from "../../Tool/table/table";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Loader from "../../../Components/Loader";
import DatePicker from "react-datepicker";

const MapInformation = (props) => {

    const [formData, setFormData] = useState({});
    const [toolData, setToolData] = useState(null);
    const [tableData, setTableData] = useState(new Map());

    const [selectedToolList, setSelectedToolList] = useState([]);
    const navigate = useNavigate();
    const saveTools = (obj) => {

        if (obj) {
            setSelectedToolList(prevSpecialRules => {
                // Check if there is an existing special rule for the asset type
                const existingSpecialRuleIndex = prevSpecialRules.findIndex(
                    item => item.tool_id === obj.id
                );

                if (existingSpecialRuleIndex !== -1) {
                    // If there is an existing special rule, update its "is_checked" value
                    const updatedSpecialRule = {
                        ...prevSpecialRules[existingSpecialRuleIndex],
                        tool_id: obj.id,
                    };

                    return [
                        ...prevSpecialRules.slice(0, existingSpecialRuleIndex),
                        updatedSpecialRule,
                        ...prevSpecialRules.slice(existingSpecialRuleIndex + 1)
                    ];
                } else {
                    // If there is no existing special rule, add a new one
                    return prevSpecialRules.concat({
                        tool_id: obj.id
                    });
                }
            });

        }
    }

    const handleCheckboxChange = (evt) => {
        let updatedData = [...selectedToolList];
        if (evt.target.checked) {
            updatedData = [...updatedData, {tool_id: evt.target.value}];

            selectedToolList.push({
                tool_id: evt.target.value,

            })

        } else {
            updatedData = updatedData.filter(item => item.tool_id !== evt.target.value)
        }
        setSelectedToolList(updatedData)

    }

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        if (name === 'map_size'){
            createMapData(value)
        }

        // if (name === 'start_date'){
        //     if (value > formData.end_date){
        //         toast('Start date cannot be greater than end date')
        //         return ;
        //     }
        // }
        // if (name === 'end_date'){
        //     if (value < formData.start_date){
        //         toast('End date cannot be less than end date')
        //         return ;
        //     }
        // }
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })

    }

    const selectAllTools = (evt) => {
        let updatedData = [...selectedToolList];
        if (evt.target.checked) {

            setSelectedToolList([])
            for(const tool of toolData){
                updatedData = [...updatedData, {tool_id:tool.id}];
                    selectedToolList.push({
                        tool_id: tool.id,

                    })
            }

            setSelectedToolList(updatedData)
        }else{
            setSelectedToolList([])
        }


    }
    const createMapData = (mapSize) => {
        const size = mapSize.split("x");
        const numRows = size[0];
        const numCols = size[1];
        const numLayers = 4;

        for (let row = 0; row < numRows; row++) {
            for (let col = 0; col < numCols; col++) {
                let layerData = [];
                for (let layer = 0; layer < numLayers; layer++) {
                    if (layer === 0) {
                        layerData.push({
                            assets: {
                                "id": "grey",
                                "createdDate": "2023-01-31T08:00:48.699Z",
                                "updatedDate": "2023-01-31T08:00:48.699Z",
                                "name": "grey",
                                "version": '#BFC1BF',
                                "asset_type": "substrates",
                                "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                                "strength": 2,
                                "covered_area": "1x1",
                                "experience_gained": 10,
                                "coin_gained": 1,
                                "stake": true,
                                "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                                "assetActions": [


                                    {


                                        "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "SMASH"


                                    },


                                    {


                                        "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "DIG"


                                    }


                                ],
                                "imageEntity": {


                                    "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                                    "createdDate": "2023-01-31T07:59:40.238Z",


                                    "updatedDate": "2023-01-31T07:59:40.238Z",


                                    "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                                    "size": 166810,


                                    "mimetype": "image/jpeg",


                                    "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                                    "metaData": null


                                },
                                "assetAllowedLayers": [


                                    {


                                        "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                        "createdDate": "2023-02-02T08:17:58.793Z",


                                        "updatedDate": "2023-02-02T08:17:58.793Z",


                                        "layer": 1


                                    }


                                ]


                            },
                            uniqueId: ""

                        });
                    }
                    else if (layer === 1) {
                        layerData.push({
                            assets: {
                                "id": "029c82b8-1a68-476a-9fd1-5468910542fb",
                                "createdDate": "2023-01-31T08:00:48.699Z",
                                "updatedDate": "2023-01-31T08:00:48.699Z",
                                "name": "brown",
                                "version": '#BFC1BF',
                                "color":"#221813",
                                "asset_type": "substrates",
                                "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                                "strength": 2,
                                "covered_area": "1x1",
                                "experience_gained": 10,
                                "coin_gained": 1,
                                "stake": true,
                                "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                                "assetActions": [


                                    {


                                        "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "SMASH"


                                    },


                                    {


                                        "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "DIG"


                                    }


                                ],
                                "imageEntity": {


                                    "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                                    "createdDate": "2023-01-31T07:59:40.238Z",


                                    "updatedDate": "2023-01-31T07:59:40.238Z",


                                    "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                                    "size": 166810,


                                    "mimetype": "image/jpeg",


                                    "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                                    "metaData": null


                                },
                                "assetAllowedLayers": [


                                    {


                                        "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                        "createdDate": "2023-02-02T08:17:58.793Z",


                                        "updatedDate": "2023-02-02T08:17:58.793Z",


                                        "layer": 1


                                    }


                                ]


                            },
                            uniqueId: ""

                        });
                    }
                    else if (layer === 2) {
                        layerData.push({
                            assets: {
                                "id": "029c82b8-1a68-476a-9fd1-5468910542fb",
                                "createdDate": "2023-01-31T08:00:48.699Z",
                                "updatedDate": "2023-01-31T08:00:48.699Z",
                                "name": "light brown",
                                "version": '#BFC1BF',
                                "color":"#8A613C",
                                "asset_type": "substrates",
                                "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                                "strength": 2,
                                "covered_area": "1x1",
                                "experience_gained": 10,
                                "coin_gained": 1,
                                "stake": true,
                                "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                                "assetActions": [


                                    {


                                        "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "SMASH"


                                    },


                                    {


                                        "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "DIG"


                                    }


                                ],
                                "imageEntity": {


                                    "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                                    "createdDate": "2023-01-31T07:59:40.238Z",


                                    "updatedDate": "2023-01-31T07:59:40.238Z",


                                    "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                                    "size": 166810,


                                    "mimetype": "image/jpeg",


                                    "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                                    "metaData": null


                                },
                                "assetAllowedLayers": [


                                    {


                                        "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                        "createdDate": "2023-02-02T08:17:58.793Z",


                                        "updatedDate": "2023-02-02T08:17:58.793Z",


                                        "layer": 1


                                    }


                                ]


                            },
                            uniqueId: ""

                        });
                    }
                    else{
                        layerData.push({
                            assets: {
                                "id": "029c82b8-1a68-476a-9fd1-5468910542fb",
                                "createdDate": "2023-01-31T08:00:48.699Z",
                                "updatedDate": "2023-01-31T08:00:48.699Z",
                                "name": "green",
                                "version": 'dummy',
                                "color":"#8A613C",
                                "asset_type": "substrates",
                                "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                                "strength": 2,
                                "covered_area": "1x1",
                                "experience_gained": 10,
                                "coin_gained": 1,
                                "stake": true,
                                "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                                "assetActions": [


                                    {


                                        "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "SMASH"


                                    },


                                    {


                                        "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                        "createdDate": "2023-02-02T08:17:58.784Z",


                                        "updatedDate": "2023-02-02T08:17:58.784Z",


                                        "action": "DIG"


                                    }


                                ],
                                "imageEntity": {


                                    "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                                    "createdDate": "2023-01-31T07:59:40.238Z",


                                    "updatedDate": "2023-01-31T07:59:40.238Z",


                                    "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                                    "size": 166810,


                                    "mimetype": "image/jpeg",


                                    "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                                    "metaData": null


                                },
                                "assetAllowedLayers": [


                                    {


                                        "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                        "createdDate": "2023-02-02T08:17:58.793Z",


                                        "updatedDate": "2023-02-02T08:17:58.793Z",


                                        "layer": 1


                                    }


                                ]


                            },
                            uniqueId: ""

                        });

                    }
                }
                tableData.set(row + "," + col, layerData);
            }
        }

    }

    const handleSubmit = async (draft = true) => {
        if (!draft) {
            if (!formData.start_date || !formData.end_date || !formData.start_time || !formData.end_time || !formData.timezone || formData.timezone === "0" || !formData.age_restriction) {
                toast('Fill all fields to continue!')
                return;
            }

        }

        if (!formData.age_restriction){
            formData.age_restriction = '0-5';
        }
        // convert the map to a 2D array
        const size = formData.map_size.split("x");
        const numRows = size[0];
        const numCols = size[1];
        const tableArray = [];
        for (let row = 0; row < numRows; row++) {
            let rowArray = [];
            for (let col = 0; col < numCols; col++) {
                rowArray.push(tableData.get(row + "," + col));
            }
            tableArray.push(rowArray);
        }

        formData.mapAllowedTools = selectedToolList
        if (formData.mapAllowedTools.length ===0 ) {
            toast('Please add atleast one tool and also stake')
            return;
        }else{
            props.setLoading(true)
            formData.map_size = formData.map_size
            formData.required_level_to_join = formData.required_level_to_join && parseInt(formData.required_level_to_join)
            formData.map_layer_data = tableData.size !== 0 ? tableArray : formData.map_layer_data
            ENDPOINT.maps.edit.id = formData.id;

            return await AuthService.patchMethod(ENDPOINT.maps.edit.url + ENDPOINT.maps.edit.id, true, formData)
                .then((res) => {

                    if (res.status === 422){
                        props.setLoading(false)

                        for (var i = 0; i < res.data.errors.length; i++) {
                            var error = res.data.errors[i];
                            for (var key in error) {
                                // console.log(key + ": " + error[key]);
                                toast(error[key])
                            }
                        }

                    }else if(res.status === 200) {



                        if (draft) {
                            toast("Maps updated successfully!")

                            setTimeout(() => {

                                navigate('/maps');

                            }, 1000);
                        }else{
                            console.log('Reaches in Else STatement')
                            props.onSubmit(res.data)
                            props.nextKey();
                        }
                    }
                    props.setLoading(false)

                })
                .catch((err) => {


                    // console.log('errors')
                    toast(err.response.data.errors[0])
                });
        }

    }


    useEffect(() => {

        if (props.data) {
            console.log(props.data,'props.data Map Info')
            setFormData(props.data)
        }

    }, [props.data])

    useEffect(() => {

        if (props.toolData) {
            // console.log(props.toolData)

            setToolData(props.toolData)
        }

    }, [props.toolData])
    useEffect(()=>{

        if(formData) {
            if (formData.map_size === '50x100' || formData.map_size === ''){
                formData.map_size = '20x20'
                setFormData(formData)
                createMapData('20x20')
            }

            formData.mapAllowedTools?.forEach(data => saveTools(data.tool))
        }
    },[formData])

    // useEffect(()=>{
    //     console.log('localStorage.getItem("user").id')
    //     console.log(localStorage.getItem("user").id)
    // },[])
    const isNumberKey = (evt) =>{
        let value = evt.target.value;
        let name = evt.target.name;
        const re = /^[0-9\b]+$/;
        toast(re.test(evt.target.value.toString()))
        if (re.test(evt.target.value.toString())) {
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }else if (evt.target.value === ''){
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }

    }

    const handleKeyDown = (e) => {
        // Prevent manual input by disabling keyboard events
        e.preventDefault();
    };
    return (
        <div>
            <section>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={6} className={"mb-3"}>
                                <Form.Label>Required level to join</Form.Label>
                                <Form.Control type="text" placeholder="" name={'required_level_to_join'}
                                             value={formData.required_level_to_join ?? 0} onChange={isNumberKey}/>
                            </Col>
                            <Col md={6} className={"mb-3"}>
                                <Form.Label>Age Restriction</Form.Label>
                                <Form.Select
                                    className="form-select"
                                    id="inputGroupSelect01"
                                    name="age_restriction"
                                    onChange={handleChange}
                                    value={formData.age_restriction}
                                >

                                    <option value={"0-5"}>Less than 5 y/o</option>
                                    <option value="12-17">12+ years old</option>
                                    <option value="18-100">18+ years old</option>
                                    <option value="0-100">All Ages</option>
                                </Form.Select>

                            </Col>
                            <Col md={12} className={"mb-3"}>
                                <Form.Label>Map Name</Form.Label>
                                <Form.Control type="text" placeholder="Ex. Snowy Map" name={'name'}
                                              onChange={handleChange} value={formData.name}/>
                            </Col>
                            <Col md={12} className={"mb-3"}>
                                <Form.Label>Map Description</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    placeholder="Enter your map description..."
                                    style={{height: '250'}}
                                    onChange={handleChange}
                                    name={'description'}
                                    value={formData.description}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <DatePicker
                                    selected={formData.start_date !== undefined && formData.start_date !== null ? new Date(formData.start_date) : ""}
                                    onKeyDown={handleKeyDown}
                                    onChange={(date) => {
                                        if (formData.end_date && date > formData.end_date) {
                                            toast('Start date cannot be greater than end date')
                                            return;
                                        }
                                        setFormData((prevValue) => ({
                                            ...prevValue,
                                            start_date: new Intl.DateTimeFormat('en-US',).format(date),
                                        }));
                                    }}
                                    minDate={new Date()}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label>End Date</Form.Label>
                                <DatePicker
                                    selected={formData.end_date !== undefined && formData.end_date !== null ? new Date(formData.end_date) : ""}
                                    onKeyDown={handleKeyDown}
                                    onChange={(date) => {
                                        if (formData.start_date && date < formData.start_date) {
                                            toast('End date cannot be less than start date')

                                            return;
                                        }
                                        setFormData((prevValue) => ({
                                            ...prevValue,
                                            end_date: new Intl.DateTimeFormat('en-US',).format(date),
                                        }));
                                    }}
                                    minDate={new Date()}
                                />
                            </Col>

                            <Col md={12} className={"mb-3"}>
                                <Form.Label>Timezone</Form.Label>
                                <Form.Select name={'timezone'} value={formData.timezone ?? ''} onChange={handleChange}>
                                    <option>Select</option>
                                    <option value="Pacific/Midway">(UTC-11:00) Midway Island</option>
                                    <option value="Pacific/Pago_Pago">(UTC-11:00) Pago Pago</option>
                                    <option value="Pacific/Honolulu">(UTC-10:00) Hawaii Time</option>
                                    <option value="America/Anchorage">(UTC-09:00) Alaska Time</option>
                                    <option value="America/Los_Angeles">(UTC-08:00) Pacific Time (US & Canada)</option>
                                    <option value="America/Denver">(UTC-07:00) Mountain Time (US & Canada)</option>
                                    <option value="America/Phoenix">(UTC-07:00) Arizona Time</option>
                                    <option value="America/Chicago">(UTC-06:00) Central Time (US & Canada)</option>
                                    <option value="America/New_York">(UTC-05:00) Eastern Time (US & Canada)</option>
                                    <option value="America/Caracas">(UTC-04:30) Caracas</option>
                                    <option value="America/Halifax">(UTC-04:00) Atlantic Time (Canada)</option>
                                    <option value="America/Santo_Domingo">(UTC-04:00) Santo Domingo</option>
                                    <option value="America/La_Paz">(UTC-04:00) La Paz</option>
                                    <option value="America/Argentina/Buenos_Aires">(UTC-03:00) Buenos Aires</option>
                                    <option value="America/Santiago">(UTC-03:00) Santiago</option>
                                    <option value="America/Noronha">(UTC-02:00) Fernando de Noronha</option>
                                    <option value="Atlantic/Azores">(UTC-01:00) Azores</option>
                                    <option value="Europe/London">(UTC+00:00) London</option>
                                    <option value="Europe/Paris">(UTC+01:00) Paris</option>
                                    <option value="Europe/Moscow">(UTC+03:00) Moscow</option>
                                    <option value="Asia/Dubai">(UTC+04:00) Dubai</option>
                                    <option value="Asia/Karachi">(UTC+05:00) Islamabad, Karachi</option>
                                    <option value="Asia/Kolkata">(UTC+05:30) India Standard Time</option>
                                    <option value="Asia/Kathmandu">(UTC+05:45) Kathmandu</option>
                                    <option value="Asia/Dhaka">(UTC+06:00) Dhaka</option>
                                    <option value="Asia/Bangkok">(UTC+07:00) Bangkok</option>
                                    <option value="Asia/Hong_Kong">(UTC+08:00) Hong Kong</option>
                                    <option value="Asia/Tokyo">(UTC+09:00) Tokyo</option>
                                    <option value="Australia/Sydney">(UTC+10:00) Sydney</option>
                                    <option value="Pacific/Auckland">(UTC+12:00) Auckland</option>


                                </Form.Select>
                            </Col>
                            <Col md={6} className={"mb-3"}>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control type="time" placeholder="1" name={'start_time'} onChange={handleChange}
                                              value={formData.start_time}/>
                            </Col>
                            <Col md={6} className={"mb-3"}>
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="time" placeholder="1" name={'end_time'} onChange={handleChange}
                                              value={formData.end_time}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-md-6">
                        <Form.Group>
                            <Form.Label>Map Sizes</Form.Label>
                            <Form.Select class="form-select mapSize"  name='map_size'
                                         onChange={handleChange} value={formData ? formData.map_size : ''} >
                                <option disabled selected>Select</option>
                                <option value={'4x4'}>4 x 4</option>
                                <option value={'20x20'}>20 x 20</option>
                                <option value={'20x30'}>20 x 30</option>
                                <option value={'20x40'}>20 x 40</option>
                                <option value={'20x50'}>20 x 50</option>
                                <option value={'20x60'}>20 x 60</option>
                                <option value={'20x70'}>20 x 70</option>
                                <option value={'20x80'}>20 x 80</option>
                                <option value={'20x90'}>20 x 90</option>
                                <option value={'20x100'}>20 x 100</option>
                            </Form.Select>
                        </Form.Group>
                        <div className={"d-flex justify-content-between py-3"}>
                            <div className={"fw-bold"}>Tools Available</div>
                            <Form.Check className='fw-bold'
                                        inline
                                        label="Apply all tools"
                                        type="checkbox"
                                        id="selectAll"
                                        onChange={selectAllTools}
                                        handleClick={"Check All"}
                                        checked={toolData && selectedToolList.length === toolData.length}
                            />
                        </div>
                        {toolData &&
                        <Tabs
                            defaultActiveKey="profile"
                            className="tabs-sm full-width"
                        >
                            <Tab eventKey="profile" title="ALL">

                                <ToolList data={toolData} addTool={handleCheckboxChange} selectedTools={selectedToolList} />

                            </Tab>
                            <Tab  eventKey="DIG" title="DIG">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["dig"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>
                            </Tab>
                            <Tab eventKey="SMASH" title="SMASH">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["smash"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="CUT" title="CUT">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["cut"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="EXCAUATE" title="EXCAUATE">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["excavate"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="COLLECT" title="COLLECT">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["collect"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="PUMP" title="PUMP">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["pump"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="SHOOING" title="SHOOING">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["shoo"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="DESTROY" title="DESTROY">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["destroy"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="FREEZE" title="FREEZE">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["freeze"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="DROP" title="DROP">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["drop"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>

                            </Tab>
                            <Tab eventKey="ACTIVATE" title="ACTIVATE">
                                <ToolList addTool={handleCheckboxChange} data={toolData.filter(data => ["activate"].includes(data.tool_type?.toLowerCase()))} selectedTools={selectedToolList}/>
                            </Tab>
                        </Tabs>
                        }
                    </Col>
                </Row>
                <hr/>
                <div className="col-md-12 d-flex justify-content-end">
                    <Button onClick={()=>handleSubmit(true)} variant={"outline-success"} className='me-2'>SAVE AS DRAFT</Button>
                    <Button className='btn  btn-delete ' onClick={()=>handleSubmit(false)}>NEXT</Button>
                </div>

            </section>
        </div>
    )
}

export default MapInformation