import React, {useEffect, useState} from 'react'
import { Button, Form, Modal } from "react-bootstrap";
import AuthService from "../../../../../services/auth.service";
import {ENDPOINT} from "../../../../../config/constants";
import {toast} from "react-toastify";
import SelectDropDown from "../../../../../Components/SelectDropDown";
const EditDigs = (props) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [defaultTool, setDefaultTool] = useState({});
  const [formData, setFormData] = useState({coin_value:0});
  useEffect(() => {
    if (props.tools) {
      setData(props.tools);

    }
  }, [props.tools]);
  useEffect(() => {
    if (props.default) {
      setFormData({coin_value:0})
      setFormData((prevalue) => {
        return {
          ...prevalue,   // Spread Operator
          ['tool_type']: props.default?.tool_type
        }
      })

      setDefaultTool({ value: props.default?.id, label: props.default?.name , image: props.default?.imageEntity?.path})
      handleSelect({value:props.default?.id})
    }
  }, [props.default]);

  useEffect(() => {
    setSelected([]);

    if (data) {

      const filteredData = data.slice().filter((d) => d.tool_type == formData.tool_type);

      const mappedData = filteredData.map((d) => ({
        value: d.id,
        label: d.name,
        image: d.imageEntity?.path,
      }));
      setSelected(mappedData);
    }
  }, [formData.tool_type]);
  useEffect(() => {
    setFormData((prevalue) => {
      return {
        ...prevalue,   // Spread Operator
        ['coin_value']: props.default?.toolExchangeRate?.coin_value ?? 0
      }
    })

    }, [defaultTool]);

  const isNumberKey = (evt) =>{
    let value = evt.target.value;
    let name = evt.target.name;
    const re = /^[0-9]*\.?[0-9]*$/;
    if (re.test(evt.target.value))
      setFormData((prevalue) => {
        return {
          ...prevalue,   // Spread Operator
          [name]: value
        }
      })

  }
  const handleChange = (event) => {

    let value = event.target.value;
    let name = event.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,   // Spread Operator
        [name]: value
      }
    })
  }
  const handleSelect = (value) => {


    setFormData((prevalue) => {
      return {
        ...prevalue,   // Spread Operator
        ['tool_id']: value.value
      }
    })
    setFormData((prevalue) => {
      return {
        ...prevalue,   // Spread Operator
        ['coin_value']: data.filter(data => data.id === value.value)[0]?.toolExchangeRate?.coin_value ?? 0
      }
    })
  }

  const handleSubmit = async () => {
    formData.coin_value = parseInt(formData.coin_value)
    return await AuthService.patchMethod(ENDPOINT.tool_exchange_rate.add_tool_exchange_rate, true, formData)
        .then((res) => {
          if (res.status === 422){

            for (var i = 0; i < res.data.errors.length; i++) {
              var error = res.data.errors[i];
              for (var key in error) {
                // console.log(key + ": " + error[key]);
                toast(error[key])
              }
            }
          }else if(res.status === 200) {
            props.refresh()
            toast('Tool exchange rate updated successfully!')



          }
        })
        .catch((err) => {

          // console.log('err')
          // console.log(err)
          // toast(err.response.data.errors[0])

        });
  }

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newOptions = selected.map((item) => ({ value: item.value, label: item.label , image: item.image}));
    setOptions(newOptions);
  }, [selected]);
  return (
      <Modal {...props} size="md" centered>
        <Modal.Header className={"border-0 justify-content-center"}>
          <Modal.Title>
            Edit Exchange
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select aria-label="Default select example" value={formData.tool_type} name={'tool_type'} onChange={handleChange}>
                <option value={""} disabled>-Select-</option>
                <option value={'DIG'}>Dig</option>
                <option  value={'SMASH'}>smash</option>
                <option  value={'CUT'}>cut</option>
                <option  value={'EXCAVATE'}>excavate</option>
                <option  value={'COLLECT'}>collect</option>
                <option  value={'PUMP'}>pump</option>
                <option  value={'SHOO'}>shoo</option>
                <option  value={'DESTROY'}>destroy</option>
                <option  value={'ACTIVATE'}>activate</option>
                <option  value={'DROP'}>drop</option>
                <option  value={'FREEZE'}>freeze</option>

              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Choose a Tool</Form.Label>
              {/*<Form.Select aria-label="Default select example" name={'tool_id'} onChange={handleChange}>*/}
                {options && <SelectDropDown
                    options={options}
                    onChange={(value) => handleSelect(value)}
                    selected={defaultTool}
                />}

              {/*</Form.Select>*/}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Coin Value</Form.Label>
              <Form.Control type="text" placeholder="1" disabled={!formData.tool_id} name={'coin_value'} value={formData.coin_value} onChange={isNumberKey}/>
            </Form.Group>

            <Modal.Footer className={"border-0 justify-content-center"}>
              <Button onClick={props.onHide} variant={"outline-success"}>
                CANCEL
              </Button>
              <Button onClick={handleSubmit}>SAVE CHANGES</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
  )
}

export default EditDigs