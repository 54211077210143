import PageTitle from "Components/Pagetitle";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import GiveItemModal from "../GiveItem";
import Search from "Components/Search";
import LogList from "../../../Components/LogList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import withAuth from "../../../withAuth";


const UserProfile = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [toolData, setToolData] = useState([]);
    const [selectedTool, setSelectedTool] = useState(null);
    const [logData, setLogData] = useState([]);
  const [formData, setFormData] = useState({});

  const location = useLocation();
    const navigate = useNavigate();
    const getUserData = async (id) => {
        ENDPOINT.users_route.getById.id = id.toString();
        return await AuthService.getMethod(ENDPOINT.users_route.getById.url + ENDPOINT.users_route.getById.id, true)
            .then((res) => {
                toast(res.statusCode)
                if (res.statusCode === 401 && res.message === 'Unauthorized') {
                    toast('check')
                    navigate('/');
                }
                setData(res.data)
            })
            .catch((err) => {

            });
    };
    const toolsData = async () => {


        await AuthService.getMethod(ENDPOINT.tools.listing, true)
            .then((res) => {

               setToolData(res.data)


            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    }


        const back = () => {
            navigate('/users');
        };
  const isNumberKey = (evt) =>{
    let value = evt.target.value;
    let name = evt.target.name;
    const re = /^[0-9\b]+$/;
    if (re.test(evt.target.value.toString())) {
      setFormData((prevalue) => {
        return {
          ...prevalue,   // Spread Operator
          [name]: value
        }
      })
    }else if (evt.target.value === ''){
      setFormData((prevalue) => {
        return {
          ...prevalue,   // Spread Operator
          [name]: value
        }
      })
    }

  }

  useEffect(() => {

            if (location.state) {
                toolsData()
                getUserData(location.state)
            }
        }, []);
        useEffect(() => {

            setLogData(data.userLogs)
        }, [data]);

        useEffect(() => {

            // console.log('startDate')
            // console.log(new Date(startDate).toLocaleDateString())
            let filterList = data.userLogs?.filter(data => {
                const createdDate = new Date(data.createdDate);
                const createdDateString = createdDate.toLocaleDateString();
                const startDateString = new Date(startDate).toLocaleDateString();
                return createdDateString === startDateString;
            });
            setLogData(filterList)
        }, [startDate]);
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === 'tool'){
        setSelectedTool(toolData.filter(data => value === data.id)[0])
    }
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }
  const updateData = (object) => {
    setLogData(object)

  }
  const sendGifts = () => {
    if (!formData.tool && !formData.coins ){
        toast('Select either tool or add coins to send')
        return;
    }
    setModalShow(true)
  }
        return (
            <>
                <PageTitle title="Users"/>
                <div className="d-flex align-items-center py-2 ms-3">
                    <Button onClick={back} variant={"transparent font-18 fw-bold ps-0"}><i
                        className={"fas fa-arrow-left  mx-2"}></i> User Profile</Button>
                </div>
                <section>
                    <Row>
                        <Col md={6}>
                            <Card className={'user_data_profile'}>
                                <Card.Body>
                                    <Row>
                                        <Col md={2} className="mb-2">
                                            <span>Name:</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.username ?? "N/A"}</p>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <span>Email:</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.email ?? "N/A"}</p>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <span>Age:</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.age ?? "N/A"}</p>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <span>Level:</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.level ?? 0}</p>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <span>Coins:</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.coins ?? 0}</p>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <span>Gems:</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.gems ?? 0}</p>
                                        </Col>
                                        <Col md={2} className="mb-2">
                                            <span>Diamonds</span>
                                        </Col>
                                        <Col md={10}>
                                            <p>{data.level ?? 0}</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className={'user_data_profile'}>
                                <Card.Body>
                                    <Row>
                                        <Col md={3} className="mb-2">
                                            <span>Current Map:</span>
                                        </Col>

                                        <Col md={9}>
                                            <p>{data.userMaps?.map((effect, index) => index !== (data.userMaps.length - 1) ? effect.map?.name + "," : effect.map?.name)}</p>
                                        </Col>

                                        <Col md={3} className="mb-2">
                                            <span>Total Stake Purchased:</span>
                                        </Col>

                                        <Col md={9}>
                                            <a href="#" onClick={() => navigate('/Stake_Purchased_Log',{state:{data:data.UsersPurchasedStakes,user_id:data.id}})}>{data.UsersPurchasedStakes?.reduce((acc, user) => acc + user.qty, 0)}</a>
                                        </Col>

                                        <Col md={3}>
                                            <span>Total Items Purchased:</span>
                                        </Col>

                                        <Col md={9}>
                                            <a href="#" onClick={() => navigate('/TotalPurchased',{state:{data:data.UsersPurchasedItems.filter(data => [false].includes(data.is_free)),user_id:data.id}})}>{data.UsersPurchasedItems?.filter(data => [false].includes(data.is_free)).length}</a>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className={'user_data_profile'}>
                                <Card.Title>Play Thru</Card.Title>
                                <Card.Body>
                                    <Row>
                                        <Col md={3} className="mb-2">
                                            <span>Free Map:</span>
                                        </Col>

                                        <Col md={9}>
                                            <p>{data.userMaps?.filter(data => ["free to play"].includes(data.map.map_type)).length}</p>
                                        </Col>

                                        <Col md={3} className="mb-2">
                                            <span>Pay-to-Play:</span>
                                        </Col>

                                        <Col md={9}>
                                            <p>{data.userMaps?.filter(data => ["pay to play"].includes(data.map.map_type)).length}</p>
                                        </Col>

                                        <Col md={3} className="mb-2">
                                          <span>Proximity Map:</span>
                                        </Col>

                                        <Col md={9}>
                                            <p>{data.userMaps?.filter(data => ["Proximity"].includes(data.map.game_mode)).length}</p>
                                        </Col>

                                        <Col md={3} className="mb-2">
                                            <span>Charity Map:</span>

                                        </Col>

                                        <Col md={9}>
                                            <p>{data.userMaps?.filter(data => ["charity map"].includes(data.map.map_type)).length}</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className={'user_data_profile'}>
                                <Card.Title>Give Items</Card.Title>
                                <Card.Body>
                                    <Form.Group className="mb-3" controlId="formBasicDec">
                                        <Form.Label>
                                            <strong>Tools</strong>
                                        </Form.Label>

                                        <Form.Select aria-label="Default select example" name={"tool"} onChange={handleChange}>
                                            <option disabled selected>Select</option>
                                          {toolData?.map((field, index) => (
                                              <option value={field.id}>{field.name}</option>
                                          ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicDec">
                                        <Form.Label>
                                            <strong>Coin</strong>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="50" value={formData.coins ?? ''} name={'coins'} onChange={isNumberKey}/>
                                    </Form.Group>
                                    <Button className={" d-block m-auto"} onClick={sendGifts}>GIVE
                                        ITEMS</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className={'user_data_profile'}>
                                <Card.Title>Game Logs</Card.Title>
                                <div className={"searchbox"}>
                                    <Row>
                                        <Col md={5}>
                                            <Row>
                                                <Col lg={7}>
                                                    <Search placeholder="Search" data={logData}  default={data.userLogs} searchColumns={['text','createdDate']} result={updateData}/>
                                                </Col>
                                                <Col lg={5} className={'px-0'}>
                                                    <div className={'btnData'}>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </div>
                                <Card.Body>
                                    <h5 className={"mb-3"}>Console Log</h5>
                                    <LogList data={logData}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <GiveItemModal user={data} tool={selectedTool} coins={formData?.coins} show={modalShow} onHide={() => setModalShow(false)} refresh={()=>getUserData(location.state)}/>
                </section>

            </>

        );
    };

    export default withAuth(UserProfile);
