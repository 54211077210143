import { Button } from "react-bootstrap";
import React from "react";
import DataTable from "react-data-table-component";
import dig1 from "Images/dig1.png";
import dig2 from "Images/dig2.png";
import dig3 from "Images/dig3.png";
import dig4 from "Images/dig4.png";
import EditDigs from "./EditDig";

const ExchangeDigs = (props) => {
  const [modalShow, setModalShow] = React.useState(false)
  const [defaultTool, setDefaultTool] = React.useState(null)
  const updateData = () => {
    props.refresh()
    setModalShow(false)

  }
  const setDefault = (tool) => {
    setDefaultTool(tool)
   setModalShow(true)
  }
  const columns = [
    {
      name: "Image",
      selector: (row) => <img src={row.imageEntity?.path} height={'50px'} width={'50px'} />,
    },
    {
      name: "Tool Name",
      selector: (row) => row.name,
    },
    {
      name: "Quantity",
      selector: (row) => row.toolExchangeRate?.qty ?? "N/A",
    },
    {
      name: "Coins Value",
      selector: (row) => row.toolExchangeRate?.coin_value ?? "N/A",
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      cell: (row) => (
          <ul className={"action-List"}>
            <li>
              <Button onClick={()=>setDefault(row)} variant={"actionBtn text-success"}>
                <i className={"fal fa-pen"}></i>{" "}
              </Button>
            </li>
          </ul>
      ),
    },
  ];

  return (
      <>
        <DataTable
            columns={columns}
            data={props.data}
            pagination
            striped
        />
        { modalShow && <EditDigs
            show={modalShow}
            onHide={() => setModalShow(false)} default={defaultTool} tools={props.default} refresh={updateData}/>}
      </>
  )

};

export default ExchangeDigs;
