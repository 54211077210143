import React, {useEffect, useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {toast} from "react-toastify";
import AuthService from "../../../../services/auth.service";
import {ENDPOINT} from "../../../../config/constants";

const EditCoin = (props) => {
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (props.data){
      setFormData(props.data)
    }
  }, [props.data]);
  const isNumberKey = (evt) =>{
    let value = evt.target.value;
    let name = evt.target.name;
    const re = /^[0-9\b]+$/;
    toast(re.test(evt.target.value.toString()))
    if (re.test(evt.target.value.toString())) {
      setFormData((prevalue) => {
        return {
          ...prevalue,   // Spread Operator
          [name]: value
        }
      })
    }else if (evt.target.value === ''){
      setFormData((prevalue) => {
        return {
          ...prevalue,   // Spread Operator
          [name]: value
        }
      })
    }

  }
  const handleSubmit = async () => {
    formData.coin_value = parseInt(formData.coin_value)
    formData.qty = parseInt(formData.qty)
    ENDPOINT.exchange_rate.edit.id = formData.id
    return await AuthService.patchMethod(ENDPOINT.exchange_rate.edit.url+ENDPOINT.exchange_rate.edit.id, true, formData)
        .then((res) => {
          if (res.status === 422){

            for (var i = 0; i < res.data.errors.length; i++) {
              var error = res.data.errors[i];
              for (var key in error) {
                // console.log(key + ": " + error[key]);
                toast(error[key])
              }
            }
          }else if(res.status === 200) {
            props.onHide()
            props.refresh()
            toast('Exchange rate updated successfully!')



          }
        })
        .catch((err) => {


          toast(err.response.data.errors[0])

        });
  }

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header className={"border-0 justify-content-center"}>
        <Modal.Title className={"mb-0 fw-bold"}>
        Edit Exchange
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Quantity</Form.Label>
            <Form.Control type="text" value={formData.qty} name={'qty'} onChange={isNumberKey} placeholder="5" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Pound Value</Form.Label>
            <Form.Control type="text" value={formData.coin_value} name={'coin_value'} onChange={isNumberKey} placeholder="1" />
          </Form.Group>

          <Modal.Footer className={"border-0 justify-content-center"}>
            <Button onClick={props.onHide} variant={"outline-success"}>
              CANCEL
            </Button>
            <Button onClick={handleSubmit}>SAVE CHANGES</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCoin;
