import React, {useEffect, useRef, useState} from 'react';
import classes from "../index.module.scss";
import {toast} from "react-toastify";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import {useNavigate} from "react-router-dom";

function CanvaGrid({width, height, cellSize, images, data, draft, undo, asset, addToConsole, selectedTool, onSubmit,setLoading}) {
    const canvasRef = useRef(null);
    const mousePosRef = useRef({x: 0, y: 0});
    const containerRef = useRef(null);
    const [mapData, setMapData] = useState({});
    const [layerData, setData] = useState(new Map());
    const [layerHistory, setLayerHistory] = useState(new Map());
    const [assetRecord, setAssetRecord] = useState({});
    const [tool, setTool] = useState({});
    const [rewardTool, setRewardTool] = useState([]);
    const [mapRandomRewards, setMapRandomRewards] = useState({coins: 0, gems: 0, diamonds: 0, layer: 1, mapTools: []});
    const [dragging, setDragging] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const testImage = 'https://api.prizeminer.appscorridor.com//api/v1/files/3ce0f9b0-b636-4520-9203-b4f4203348f3.png';
    const testImage2 = 'http://localhost:5000/api/v1/files/1c278d03-e717-4108-bd63-36b495e208c5.png';
    const componentRef = useRef(null);
    const navigate = useNavigate();

    const handleSubmit = async (draft) => {
        setLoading(true)
        //convert the map to a 2D array
        const size = mapData.map_size.split("x");
        const numRows = size[0];
        const numCols = size[1];
        const tableArray = [];
        for (let row = 0; row < numRows; row++) {
            let rowArray = [];
            for (let col = 0; col < numCols; col++) {
                rowArray.push(layerData.get(row + "," + col));
            }
            tableArray.push(rowArray);
        }

        // console.log(tableArray);
        let mapRewards = {};
        let mapRewardsArray =  [];
        // console.log('mapRandomRewards')
        // console.log(mapRandomRewards)

        mapRewards.coins = mapRandomRewards?.coins ?? 0;
        mapRewards.gems = mapRandomRewards?.gems ??0;
        mapRewards.diamonds = mapRandomRewards?.diamonds ??0;
        mapRewards.is_random = mapRandomRewards?.is_random ?? false;
        mapRewards.layer = 1;
        mapRewards.mapTools = mapRandomRewards.mapTools?mapRandomRewards.mapTools.concat(rewardTool) : rewardTool
        if(mapData.mapRewards){
            mapRewardsArray = mapData.mapRewards.filter(item => item.layer !== 1)
        }
        mapRewardsArray.push(mapRewards)
        mapData.mapAllowedTools = []
        mapData.map_layer_data = tableArray
        mapData.mapRewards = mapRewardsArray
        mapData.status = draft === 'publish' ? 'published' : mapData.status
        ENDPOINT.maps.edit.id = mapData.id;

        return await AuthService.patchMethod(ENDPOINT.maps.edit.url + ENDPOINT.maps.edit.id, true, mapData)
            .then((res) => {
               setLoading(false)
                // console.log('res')
                // console.log(res)
                if (res.status === 422) {

                    for (var i = 0; i < res.data.errors.length; i++) {
                        var error = res.data.errors[i];
                        for (var key in error) {
                            // console.log(key + ": " + error[key]);
                            toast(error[key])
                        }
                    }
                } else if (res.status === 200) {


                    if (draft === 'later') {
                        toast("Maps is scheduled successfully!")
                    }else if(draft === 'publish'){
                        toast("Maps is published successfully!")

                    }else{
                        toast("Maps is saved as draft successfully!")

                    }
                        setTimeout(() => {

                            navigate('/maps');

                        }, 1000);

                }
            })
            .catch((err) => {


                // console.log('errors')
                // console.log(err)
                toast(err.response.data.errors[0])
            });
    }

    function undoSelections() {
        if (layerHistory) {
            const keysArray = Array.from(layerHistory.keys());
            const lastKey = keysArray.slice(-1)[0];

            const keysToUpdate = layerHistory.get(lastKey);

            if (keysToUpdate) {
                keysToUpdate.forEach(({row, col}) => {
                    if (layerData.get(`${row},${col}`)[3]?.assets?.name.includes("(Tool)")) {
                        rewardTool.pop()
                    }
                    layerData.get(`${row},${col}`)[3].assets = {
                        "id": "8dad80fa-6d87-495a-ab9a-b558a8732b76",
                        "createdDate": "2023-01-31T08:00:48.699Z",
                        "updatedDate": "2023-01-31T08:00:48.699Z",
                        "name": "green",
                        "version": '#BFC1BF',
                        "color": "#221813",
                        "asset_type": "substrates",
                        "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                        "strength": 2,
                        "covered_area": "1x1",
                        "experience_gained": 10,
                        "coin_gained": 1,
                        "stake": true,
                        "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                        "assetActions": [


                            {


                                "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                                "createdDate": "2023-02-02T08:17:58.784Z",


                                "updatedDate": "2023-02-02T08:17:58.784Z",


                                "action": "SMASH"


                            },


                            {


                                "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                                "createdDate": "2023-02-02T08:17:58.784Z",


                                "updatedDate": "2023-02-02T08:17:58.784Z",


                                "action": "DIG"


                            }


                        ],
                        "imageEntity": {


                            "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                            "createdDate": "2023-01-31T07:59:40.238Z",


                            "updatedDate": "2023-01-31T07:59:40.238Z",


                            "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                            "size": 166810,


                            "mimetype": "image/jpeg",


                            "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                            "metaData": null


                        },
                        "assetAllowedLayers": [


                            {


                                "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                                "createdDate": "2023-02-02T08:17:58.793Z",


                                "updatedDate": "2023-02-02T08:17:58.793Z",


                                "layer": 1


                            }


                        ]


                    };
                });
                layerHistory.delete(lastKey)
            }


        }
    }
    useEffect(() => {

        if (draft) {
            handleSubmit(draft)
        }

    }, [draft])
    useEffect(() => {

        if (data) {
            setMapData(data)
        }

    }, [data])
    useEffect(() => {

        if (selectedTool) {
            setTool(selectedTool)
        }

    }, [selectedTool])
    useEffect(() => {

        if (undo) {
            undoSelections()
        }

    }, [undo])
    // useEffect(() => {
    //
    //     if (draft) {
    //         handleSubmit(draft)
    //     }
    //
    // }, [draft])
    useEffect(() => {

        if (asset) {
            setAssetRecord(asset)
        }

    }, [asset])
    useEffect(() => {

        const tableArray = mapData.map_layer_data;
        const size = mapData?.map_size?.split("x");
        const numRows = mapData?.map_size && size[0];
        const numCols = mapData?.map_size && size[1];
        //
        // console.log('tableArray')
        // console.log(tableArray)
        if (mapData?.map_size && tableArray && tableArray.length > 0) {
            for (let i = 0; i < numRows * numCols * 4; i++) {
                // let mapRow = Math.floor(i / (4 * numCols));
                // let mapCol = Math.floor((i / 4) % numCols);
                for (let mapRow = 0; mapRow < numRows; mapRow++) {
                    for (let mapCol = 0; mapCol < numCols; mapCol++) {
                        // console.log('tableArrayData')

                        // console.log(tableArray[mapRow].value)

                        layerData.set(mapRow + "," + mapCol, tableArray[mapRow][mapCol]);
                    }
                }
            }
        }
        // console.log('map grid')
        // console.log(data)

    }, [mapData])

    function draw() {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.width = width * cellSize;
        canvas.height = height * cellSize;
        context.clearRect(0, 0, canvas.width, canvas.height);
            let checkId = "";
        for (let x = 0; x < height; x++) {
            for (let y = 0; y < width; y++) {
                const image = images[0];
                if (layerData.get(x + "," + y) && layerData.get(x + "," + y)[3]?.assets?.name?.includes("(Tool)")) {

                    new Promise((resolve) => {
                        const img = new Image();
                        img.onload = () => {
                            const imgWidth = cellSize - 5;
                            const imgHeight = cellSize - 5;
                            const xPos = (y * cellSize) + (cellSize - imgWidth) / 2;
                            const yPos = (x * cellSize) + (cellSize - imgHeight) / 2;
                            context.drawImage(img,xPos, yPos, imgWidth, imgHeight);
                            resolve();
                        };


                        img.src = testImage;
                    });

                    context.fillStyle = '#84B045';

                    context.fillRect(y * cellSize, x * cellSize, cellSize, cellSize);
                } else if (layerData.get(x + "," + y) && !layerData.get(x + "," + y)[3]?.assets?.name?.includes("(Tool)") && layerData.get(x + "," + y)[3]?.assets?.name !== 'green') {

                    if (layerData.get(x + "," + y)[3]?.main) {
                        const size = layerData.get(x + "," + y)[3]?.assets.covered_area.split("x");
                        const numRows = layerData.get(x + "," + y)[3]?.assets.covered_area && size[0];
                        const numCols = layerData.get(x + "," + y)[3]?.assets.covered_area && size[1];
                        if (layerData.get(x + "," + y)[3]?.assets?.imageEntity?.path) {
                            new Promise((resolve) => {
                                const img = new Image();
                                img.onload = () => {
                                    context.drawImage(img, y * cellSize, x * cellSize, cellSize * numRows, cellSize * numCols);
                                    // context.drawImage(img, y * cellSize, x * cellSize, cellSize  , cellSize);
                                    resolve();

                                };
                                // context.drawImage(img, y * cellSize, x * cellSize, cellSize * numCols, cellSize * numRows);

                                img.src = layerData.get(x + "," + y)[3]?.assets?.imageEntity?.path;
                                // img.src = testImage2;
                            });
                        }
                    }
                        context.fillStyle = '#84B045';

                        context.fillRect(y * cellSize, x * cellSize, cellSize, cellSize);
                        checkId = layerData.get(x + "," + y)[3]?.uniqueId



                } else {
                    // If there is no image for this cell, draw a background color

                    context.fillStyle = '#84B045';

                    context.fillRect(y * cellSize, x * cellSize, cellSize, cellSize);

                    // Draw stroke lines


                }

                context.strokeStyle = '#96CD49';
                context.lineWidth = 1;
                context.strokeRect(y * cellSize, x * cellSize, cellSize, cellSize);
            }
        }
    }

    function loadImage(url) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.src = url;
        });
    }

    function handleMouseDown() {
        setDragging(true);
    }

    function updateOrPush(arr, newRow, newCol) {
        let foundDuplicate = false;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].row === newRow && arr[i].col === newCol) {
                // If a duplicate is found, update the existing object
                arr[i] = { row: newRow, col: newCol };
                foundDuplicate = true;
                break;
            }
        }

        // If no duplicate was found, push a new object to the array
        if (!foundDuplicate) {
            arr.push({ row: newRow, col: newCol });
        }
    }
    function handleMouseUp() {
        setDragging(false);
    }

    function handleMouseMove(event) {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        mousePosRef.current = {x: event.clientX - rect.left, y: event.clientY - rect.top};

        if (dragging) {
            const newX = Math.floor(mousePosRef.current.x / cellSize);
            const newY = Math.floor(mousePosRef.current.y / cellSize);

            if (newX !== selectedCell.x || newY !== selectedCell.y) {
                setSelectedCell({x: newX, y: newY});
            }
        }
    }
    function range(start, end) {
        return Array.from({length: end - start + 1}, (_, i) => start + i);
    }
    function handleClick(event) {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const y = Math.floor((event.clientX - rect.left) / cellSize);
        const x = Math.floor((event.clientY - rect.top) / cellSize);
        let array = [];
        var uniqueId = Math.random();

        if (tool?.tool) {
            // console.log('tool')
            layerData.get(x + "," + y)[3].uniqueId = uniqueId.toString();
            layerData.get(x + "," + y)[3].assets = {
                "id": "8dad80fa-6d87-495a-ab9a-b558a8732b76",
                "createdDate": "2023-01-31T08:00:48.699Z",
                "updatedDate": "2023-01-31T08:00:48.699Z",
                "name": tool.tool.name + '(Tool)',
                "version": '#BFC1BF',
                "color": "#221813",
                "asset_type": "substrates",
                "description": "A healthy lawn can increase a home's value by up to 20%.\n",
                "strength": 2,
                "covered_area": "1x1",
                "experience_gained": 10,
                "coin_gained": 1,
                "stake": true,
                "image": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",
                "assetActions": [


                    {


                        "id": "6d36e985-6584-432d-9c2c-d4a50b8dd863",


                        "createdDate": "2023-02-02T08:17:58.784Z",


                        "updatedDate": "2023-02-02T08:17:58.784Z",


                        "action": "SMASH"


                    },


                    {


                        "id": "6c80a7b4-eac1-46ba-b8a4-1a845c083533",


                        "createdDate": "2023-02-02T08:17:58.784Z",


                        "updatedDate": "2023-02-02T08:17:58.784Z",


                        "action": "DIG"


                    }


                ],
                "imageEntity": {


                    "id": "4d790fae-a367-4a86-8fc9-645a2ccd35ac",


                    "createdDate": "2023-01-31T07:59:40.238Z",


                    "updatedDate": "2023-01-31T07:59:40.238Z",


                    "path": "https://api.prizeminer.appscorridor.com//api/v1/files/762ef2c1-e736-4894-ac3a-66845daa11e5.jpg",


                    "size": 166810,


                    "mimetype": "image/jpeg",


                    "fileName": "green-false-grass-texture-background-useful-as-164832875.jpg",


                    "metaData": null


                },
                "assetAllowedLayers": [


                    {


                        "id": "c2363ef8-27ce-4db5-a43a-a7f65b7ae9f9",


                        "createdDate": "2023-02-02T08:17:58.793Z",


                        "updatedDate": "2023-02-02T08:17:58.793Z",


                        "layer": 1


                    }


                ]


            };


            rewardTool.push({
                "tool_id": tool.tool.id,
                "qty": tool.qty,
                "coordinates": x + "x" + y
            })
            setRewardTool(rewardTool)
            addToConsole('You have added tool ' + tool.tool.name)

            // console.log(rewardTool)
            array.push({row: x, col: y})

        } else if (assetRecord?.asset) {
            const size = assetRecord?.asset.covered_area.split("x");
            const numRows = assetRecord?.asset.covered_area && size[0];
            const numCols = assetRecord?.asset.covered_area && size[1];

            let latestXCoordinate = x
            let latestYCoordinate = y
            for (let a = 0; a < assetRecord?.qty; a++) {
                uniqueId = Math.random();
                let loopCount = 0;
                for (let i of range(latestXCoordinate, (parseInt(latestXCoordinate) + parseInt(numRows) - 1))) {
                    for (let j of range(latestYCoordinate, (parseInt(latestYCoordinate) + parseInt(numCols) - 1))) {

                        // console.log("i + + j")
                        // console.log(i + "," + j)
                        if (layerData.get(i + "," + j)) {
                            layerData.get(i + "," + j)[3].uniqueId = uniqueId.toString();
                            layerData.get(i + "," + j)[3].assets = assetRecord?.asset
                            layerData.get(i + "," + j)[3].main = loopCount === 0 ? true : false
                            // if (loopCount !== 0){
                            //     layerData.get(i + "," + j)[3].assets.imageEntity.path = ""
                            // }
                            array.push({row: i, col: j});
                            loopCount++
                        }
                    }
                }

                latestXCoordinate = array.reverse()[0]?.row + 1
                latestYCoordinate = array.reverse()[0]?.col
            }
            // console.log('check unique ids')
            // array.map(index =>
            // // console.log(layerData.get(index.row + "," + index.col)[3].uniqueId)
            // )
            addToConsole('You have added ' + assetRecord.asset.name)

        }
        if (array.length > 0) {
            // console.log('layerHistory new')
            // console.log(layerHistory.size)
            // console.log(array)

            layerHistory.set(layerHistory.size === 0 ? 0 : layerHistory.size + 1, array)
        }
        // setSelectedCell(array.reverse()[0]?.row);
        // componentRef.current && componentRef.current.forceUpdate();

    }

    function handleCellClick(event, cell) {
        event.preventDefault();
        if (cell) {
            setSelectedCell(cell);
        } else {
            setSelectedCell(null);
        }
    }

    useEffect(() => {
        draw();
    }, [images, selectedCell]);

    return (
        <div className={classes.mapContainer}  id={'layerOne'} ref={containerRef}>
            <div className="konvajs-content p-0" role="presentation" >
            <canvas
                ref={canvasRef}
                width={width * cellSize}
                height={height * cellSize}
                // onMouseDown={handleMouseDown}
                // onMouseUp={handleMouseUp}
                // onMouseMove={handleMouseMove}
                onClick={handleClick}

            />
            </div>
        </div>
    );
}

export default CanvaGrid;