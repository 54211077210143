import PageTitle from "../../Components/Pagetitle";
import { Row,  Tab, Tabs, Col } from "react-bootstrap";
import CurrentMap from './currentMap';
import ExpiredMap from './ExpiredMap';

import Draft from './Draft';
import MapList from "./currentMap";
import AuthService from "../../services/auth.service";
import {ENDPOINT} from "../../config/constants";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import DeleteMaps from "./Delete";
import CurrentOnlinePopUp from "./CurrentOnlinePopUp";
import WinnerPopUp from "./WinnerPopUp";
import withAuth from "../../withAuth";




const Maps = () => {
    const [data, setData] = useState(null);
    const [DelModalShow, setDelModalShow] = React.useState(false);
    const [id, setId] = React.useState("");
    const [usersModalShow, setUsersModalShow] = React.useState(false);
    const [winnerPopupShow, setWinnerPopupShow] = React.useState(false);
    const [users, setUsers] = React.useState("");
    const [showWinner, setShowWinner] = React.useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [status, setStatus] = useState('published');
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const deleteRouteList2 = async (id) => {

        setDelModalShow(true)
        setId(id)


    };

    const usersPopup = async (users) => {

        setUsersModalShow(true)
        setUsers(users)


    };
    const winner = async (map) => {
        console.log('map')
        console.log(map)
        setWinnerPopupShow(true)
        setShowWinner(map)


    };
    const deleteRouteList = async (id) => {


        ENDPOINT.maps.delete.id = id;
        await AuthService.deleteMethod(ENDPOINT.maps.delete.url + ENDPOINT.maps.delete.id, true)
            .then((res) => {
                mapsData();
                // console.log(res.data);
                toast('Map deleted successfully!')
            })
            .catch((err) => {
                // console.log(" ~ file: index.js:38 ~ deleteRouteList ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });

        setDelModalShow(false)


    };
    const mapsData = async () => {
        const url = `${ENDPOINT.maps.listing}?page=${currentPage}&status=${status}&pageSize=${pageSize}`
        await AuthService.getMethod(url, true)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };
    const totalRecords = async () => {
        const url = `${ENDPOINT.maps.totalRecords}?status=${status}`
        await AuthService.getMethod(url, true)
            .then((res) => {
                setTotalRows((res.data).length);
            })
            .catch((err) => {
                //console.log(" ~ file: index.js:24 ~ assetsRouteGetData ~ err", err)
                // swal("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };
    useEffect(() => {
        mapsData();
    }, [currentPage,status]);
    useEffect(() => {
        totalRecords();
    }, [status]);
    const handleTabChange = (key) => {
        setCurrentPage(1);
        setStatus(key);
    };

    return (
        <>
            <PageTitle title="Map" />

            <section>
                <Tabs
                    defaultActiveKey="published"
                    transition={false}
                    onSelect={handleTabChange}
                >
                    <Tab eventKey="published" title="Current Map">
                        <MapList data={data?.filter(item => ["published"].includes(item.status))} usersPopup={usersPopup} winner={winner} onPageChange={(newPage) => setCurrentPage(newPage)} totalRows={totalRows} />
                    </Tab>
                    <Tab eventKey="expired" title="Expired Map">
                        <MapList data={data?.filter(item => ["expired"].includes(item.status))} usersPopup={usersPopup} onPageChange={(newPage) => setCurrentPage(newPage)} totalRows={totalRows} />
                    </Tab>
                    <Tab eventKey="draft" title="Drafts">
                        <MapList data={data?.filter(item => ["draft"].includes(item.status))} draft={true} deleteRouteList={deleteRouteList2} onPageChange={(newPage) => setCurrentPage(newPage)} totalRows={totalRows} />
                    </Tab>
                </Tabs>
                {DelModalShow && <DeleteMaps show={DelModalShow} id={id} onConfirm={() => setDelModalShow(false)} refreshRecord={() => deleteRouteList(id)} />}
                { usersModalShow && <CurrentOnlinePopUp show={usersModalShow} players={users} onConfirm={() => setUsersModalShow(false)} />}
                {winnerPopupShow && <WinnerPopUp  show={winnerPopupShow} winner={showWinner}/>}
            </section>


        </>
    )
}

export default withAuth(Maps);