import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import PageTitle from "../../../Components/Pagetitle";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useDropzone} from "react-dropzone";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import withAuth from "../../../withAuth";
import Loader from "../../../Components/Loader";
const AddPrize = () => {
    const [formData, setFormData] = useState({});
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const back = () => {
        navigate('/prize-info');
    };
    const onDrop = useCallback(acceptedFiles => {

        // eslint-disable-next-line no-mixed-operators
        if (acceptedFiles[0] && acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpeg" || acceptedFiles[0].type === "image/JPEG" || acceptedFiles[0].type === "image/svg") {
            setFile(acceptedFiles[0])
            const reader = new FileReader();

            reader.onload = () => {
                setPreviewUrl(reader.result);
            };

            reader.readAsDataURL(acceptedFiles[0]);
        }else{
            toast('File type not supported')
        }
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const uploadSingleFile = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
            const reader = new FileReader();

            reader.onload = () => {
                setPreviewUrl(reader.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleSubmit = async (id=null) => {


        if (formData.id) {

            formData.image = id ?? formData.image
            ENDPOINT.prize_info.edit.id = formData.id;

            return await AuthService.patchMethod(ENDPOINT.prize_info.edit.url + ENDPOINT.prize_info.edit.id, true, formData)
                .then((res) => {

                    if (res.status === 422) {

                        for (var i = 0; i < res.data.errors.length; i++) {
                            var error = res.data.errors[i];
                            for (var key in error) {
                                // console.log(key + ": " + error[key]);
                                toast(error[key])
                            }
                        }
                    } else if (res.status === 201 || res.status === 200) {
                        toast("Prize updated successfully!")
                        back()
                    }
                })
                .catch((err) => {


                    console.log('errors')
                    toast(err.response.data.errors[0])
                });
        }else{
            formData.image = id ?? formData.image

            return await AuthService.postMethod(ENDPOINT.prize_info.add_prize, true, formData)
                .then((res) => {
                    if (res.status === 422) {

                        for (var i = 0; i < res.data.errors.length; i++) {
                            var error = res.data.errors[i];
                            for (var key in error) {
                                // console.log(key + ": " + error[key]);
                                toast(error[key])
                            }
                        }
                    } else if (res.status === 201 || res.status === 200) {
                        toast("Prize added successfully!")
                        back()
                    }
                })
                .catch((err) => {


                    console.log('errors')
                    toast(err.response.data.errors[0])
                });
        }
    }
    const saveImage = async () => {
        if (!file && !formData.image) {
            toast("Image is required")
            return;
        }
        if (!formData.real_prize_name || !formData.description || !formData.age_required || !formData.required_level || !formData.price ){
            toast("Please fill all fields to continue!")
            return ;
        }
        setIsLoading(true)

        const fileData = new FormData();
        fileData.append("file", file);
        return await AuthService.postMethod(ENDPOINT.files.add_image, true, fileData)
            .then((res) => {
                handleSubmit(res.data.id)
                setFile(null)
                setTimeout(() => {
                    // setAddAdmin(props.onHide);
                    // props.subAdminAllData();
                }, 1000);
            })
            .catch((err) => {
                toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    }

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFormData((prevalue) => {
            return {
                ...prevalue,   // Spread Operator
                [name]: value
            }
        })
    }

    useEffect(()=>{
        if (location.state && location.state.row){
            setFormData(location.state.row)
        }
    },[])

    if (isLoading == true)
        return <Loader isLoading={isLoading} />;
  return (
      <>
          <PageTitle title="Prize Information" />
          <section>
              <Button onClick={back} variant={"transparent font-18 fw-bold ps-0"}><i className={"fas fa-arrow-left  mx-2"}></i>{formData.id?'Edit':'Add'} Prize</Button>
              <Form className={'pt-4'}>
                  <Card className="p-3">
                      <Row className="mb-3">
                          <Col md={3}>
                              <Form.Group className="mb-3" controlId="formBasicName" {...getRootProps()}>

                                  <Form.Control type={"file"} {...getInputProps()} id="fileUpload" className={"position-absolute opacity-0"} accept={"images/*"} name={"audioFile"} onChange={uploadSingleFile} />
                                  <label for={"fileUpload"}  className="upload-btn w-100 bg-light">

                                      {file && file.name ?<span>{ file.name}</span> : <>
                                          <span>drag image/upload</span></>}
                                      {previewUrl ? <img  className="upload-btn w-100" src={previewUrl} alt="Preview" />:<img  className="upload-btn w-100" src={formData?.imageEntity?.path ?? ''} alt="Preview" />}


                                  </label>
                              </Form.Group>
                          </Col>

                          <Col md={6}>

                              <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>
                                      Prize Name
                                  </Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder="Ex. Trip to singapore"
                                      name={'real_prize_name'}
                                      value={formData.real_prize_name ?? ''}
                                      onChange={handleChange}
                                  />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="formBasicDec">
                                  <Form.Label>
                                      Prize Description
                                  </Form.Label>
                                  <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="Enter your prize description..."
                                      name={'description'}
                                      value={formData.description ?? ''}

                                      onChange={handleChange}
                                  />
                              </Form.Group>
                              <Row>
                                  <Col md={3}>
                                      <Form.Group className="mb-5" controlId="formBasicDec">
                                          <Form.Label>
                                              Required Level To Play For
                                          </Form.Label>
                                          <Form.Control type="number" min={0} placeholder="1"
                                                        name={'required_level'}
                                                        value={formData.required_level ?? ''}

                                                        onChange={handleChange}
                                          />

                                      </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                      <Form.Group className="mb-5" controlId="formBasicDec">
                                          <Form.Label>
                                              Price
                                          </Form.Label>
                                          <Form.Control type="input" placeholder="Price"
                                                        name={'price'}
                                                        value={formData.price ?? 0}

                                                        onChange={handleChange}
                                          />

                                      </Form.Group>
                                  </Col>

                                  <Col md={6}>
                                      <Form.Group className="mb-3" controlId="formBasicDec">
                                          <Form.Label>
                                              Age Restriction
                                          </Form.Label>

                                          <Form.Select aria-label="Default select example"
                                              name={'age_required'}
                                              onChange={handleChange} value={formData.age_required}>
                                              <option selected disabled>-Select-</option>
                                              <option value={"0-5"}>Less than 5 y/o</option>
                                              <option value="12-17">12+ years old</option>
                                              <option value="18-100">18+ years old</option>
                                              <option value="0-100">All Ages</option>
                                          </Form.Select>
                                      </Form.Group>
                                  </Col>
                              </Row>
                          </Col>
                      </Row>
                      <Row className="mb-3 border-top">
                          <Col md={8}></Col>
                          <Col md={4}><Button variant="primary"  onClick={saveImage} className="float-end mt-3">
                              {formData.id?'Save Changes':'PUBLISH'}
                          </Button></Col>
                      </Row>
                  </Card>
              </Form>
          </section>
      </>
  );
};

export default withAuth(AddPrize);
