import React, {useEffect, useState} from "react";
import {Modal, Button, Form} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {ENDPOINT} from "../../../config/constants";
import AuthService from "../../../services/auth.service";
import {toast} from "react-toastify";

const AddNewSubAdmins = (props) =>{
    const [formData, setFormData] = useState({});

    const handleSubmit = async () => {


        if (formData.id) {
            console.log('formData')
            console.log(formData)
            ENDPOINT.sub_admin.edit_user.id = formData.id;
            return await AuthService.patchMethod(ENDPOINT.sub_admin.edit_user.url + ENDPOINT.sub_admin.edit_user.id, true, formData)
                .then((res) => {

                    if (res.status === 422) {

                        for (var i = 0; i < res.data.errors.length; i++) {
                            var error = res.data.errors[i];
                            for (var key in error) {
                                // console.log(key + ": " + error[key]);
                                toast(error[key])
                            }
                        }
                    } else if (res.status === 200) {
                        setFormData({})
                        toast("Sub-admin updated successfully!")
                        props.onHide()
                    }

                })
                .catch((err) => {


                    console.log('errors')
                    toast(err.response.data.errors[0])
                });
        }else{

            return await AuthService.postMethod(ENDPOINT.sub_admin.add_user, true, formData)
                .then((res) => {

                    if (res.status === 422) {

                        for (var i = 0; i < res.data.errors.length; i++) {
                            var error = res.data.errors[i];
                            for (var key in error) {
                                // console.log(key + ": " + error[key]);
                                toast(error[key])
                            }
                        }
                    } else if (res.status === 201) {
                        setFormData({})

                        toast("Sub-admin added successfully!")
                        props.onHide()

                    }
                })
                .catch((err) => {


                    console.log('errors')
                    toast(err.response.data.errors[0])
                });
        }
    }
    const isNumberKey = (evt) =>{
        let value = evt.target.value;
        let name = evt.target.name;
        const re = /^[0-9\b]+$/;
        toast(re.test(evt.target.value.toString()))
        if (re.test(evt.target.value.toString())) {
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }else if (evt.target.value === ''){
            setFormData((prevalue) => {
                return {
                    ...prevalue,   // Spread Operator
                    [name]: value
                }
            })
        }

    }
    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFormData((prevalue) => {
            return {
                ...prevalue,   // Spread Operator
                [name]: value
            }
        })
    }

    useEffect(()=>{
        if (props.data){
            console.log(props.data)
            setFormData(props.data)
        }
    },[props.data])
    return(
        <Modal
            {...props}
            size="md"
            centered
        >
            <Modal.Header className={"border-0"}>
                <Modal.Title className={"mb-0 fw-normal"}>
                    Add Sub Admin
                </Modal.Title>
                <div>
                    <Link to={"/dashboard/all-sub-admins"} className={"text-green"} onClick={props.onHide}>Sub Admin’s List</Link>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="subAdminName">
                        <Form.Label>Sub Admin Name</Form.Label>
                        <Form.Control type="text" name={'username'} onChange={handleChange} value={formData.username} placeholder="Enter here" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contactNo">
                        <Form.Label>Contact No.</Form.Label>
                        <Form.Control type="text" name={'phoneNo'} onChange={isNumberKey} value={formData.phoneNo ?? ''} placeholder="+1 234 345 390" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name={'email'} onChange={handleChange} value={formData.email} placeholder="subadmin@prizeminer.com" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="temporaryPassword">
                        <Form.Label>Temporary Password</Form.Label>
                        <Form.Control type="text" placeholder="1232jikijLLL" value={formData.temporary_password} name={'temporary_password'} onChange={handleChange} />
                    </Form.Group>
                    <Modal.Footer className={"border-0 justify-content-center"}>
                        <Button onClick={props.onHide} variant={"outline-success"}>CANCEL</Button>
                        <Button onClick={handleSubmit}>ADD</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>

        </Modal>
    )
}

export default AddNewSubAdmins;