import React, {memo, useEffect, useRef, useState} from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";

import {EditableGrid} from "./heatmap";
import Password from "./Password";
import {toast} from "react-toastify";
const LayerFour = (props) => {

  const [color, setColor] = useState(false);
  const [mapData, setMapData] = useState({});
    const [zoomLevel, setZoomLevel] = useState(100);
    const [undo, setUndo] = useState(0);
    const [draft, setDraft] = useState("");
    const [modalShow, setModalShow] = useState("");
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [isAllowed, setIsAllowed] = useState(false);
  const [consoleList, setConsoleList] = useState([]);
  const [items, setItems] = useState([1,2,3,4,5,6,7,8,9]);

  document.addEventListener("DOMContentLoaded", () => {
    (function() {

    let resizeTimeout;



  window.addEventListener("resize", () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(() => {

      changeSpanSize();
    }, 300);  });
    })();

  });
  const  changeSpanSize = () =>{
    const myDiv = document.getElementById("layerFour");
    const currentWidth = myDiv?.offsetWidth ?? 980;
    setItems([])
    let  Array = []
    const numOfCols = Math.floor(currentWidth/zoomLevel) === 0 ? 10:Math.floor(currentWidth/zoomLevel)
    // alert(numOfCols)
    for (let i = 0; i < numOfCols; i++) {

      Array.push(i);
    }
    setItems(Array)
  }

  const handleUndo = () => {
      setUndo(undo+1);
  }
const handleZoomIn = () => {
  changeSpanSize()
    setZoomLevel(zoomLevel + 10);
  }

  const handleZoomOut = () => {
    changeSpanSize()

    if(zoomLevel - 20 > 0) {

      setZoomLevel(zoomLevel - 10);
    }
  }
  useEffect(() => {

    if (props.data) {
      // console.log('props.data layer 4')
      // console.log(props.data)
      setMapData(props.data)
    }

  }, [props.data])
  const changeDragStatus = (color) => {
    setColor(color)
  };
  const saveLayerData = (value) => {
    setDraft(value)
  };
const next = (data) => {
  // console.log('data log')
  // console.log(data)
  props.onSubmit(data)
  props.nextKey();
  };
  const addToConsole = (text) => {
    const updatedData = [...consoleList, {text:text}];
    consoleList.push({text:text})
    setConsoleList(updatedData);
  }

  return (

    <>
      {!isAllowed &&
          <>
      <Row className="d-flex align-items-center ">
        <Col className="text-center center-element">
          {
            currentUser.role.name === 'superadmin' ?
              <>
          <p>This layer is locked</p>
          <p>Enter password to unlock</p>
          <Button onClick={() => setModalShow(true)} type={"submit"}>
            ENTER PASSWORD
          </Button>
              </>
              :<><p>Only Super Admin can access this layer</p></>}
        </Col>
      </Row>
        <Password setIcon={props.setIcon} show={modalShow} allowed={() => setIsAllowed(true)} onHide={() => setModalShow(false)} />
          </>
      }
      {isAllowed &&
      <div className="border rounded">
        <Row className="p-4">
          <Col md={5}>
            <Row>
              <Col sm={12} className={"mb-3"}>
                <Form className="p-3 border rounded">
                  <Row>
                    <Col lg={12}>
                      <h6 className="mb-5">
                        Direction: Drag the heatmap to your preferred game’s
                        base layer
                      </h6>
                    </Col>
                  </Row>
                  <Row className={"inputRow mb-4 color-text"}>
                    <Col lg={12}>
                      <h5 className="mb-3">Blue Heat</h5>
                    </Col>
                    <Button variant={"primary sm"}  onClick={()=>changeDragStatus('blue')}>
                      drag me
                    </Button>
                  </Row>
                  <Row className={"inputRow color-text red-color"}>
                    <Col lg={12}>
                      <h5 className="mb-3">Red Heat</h5>
                    </Col>
                    <Button variant={"primary sm"}  onClick={()=>changeDragStatus('red')}>
                      drag me
                    </Button>
                  </Row>
                </Form>
              </Col>
              <Col sm={12}>
                <Form className="p-3 border rounded">
                  <Row className={"inputRow mb-4 color-text yellow-color"}>
                    <Col lg={12}>
                      <h5 className="mb-3">Prize Information</h5>
                      <p>
                        Prizes will automatically be added once you put a red
                        heatmap on the base.
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <Row className="align-items-end p-2">
              <Col md={6}>
                <Form.Label> Map Sizes </Form.Label>
                <Form.Select aria-label="Default select example" disabled>
                  <option>{mapData.map_size}</option>

                </Form.Select>
              </Col>
              <Col md={6} className="px-3">
                <div className="d-flex justify-content-end">
                  <Button onClick={handleUndo}><i className={"fal fa-undo"}></i>UNDO</Button>
                  <span className="zoom-icon mx-1 justify-content-center align-items-center">
                    <a  >
                      <i onClick={handleZoomIn} className="fal fa-search-plus"></i>
                    </a>
                  </span>
                  <span className="zoom-icon mx-1  justify-content-center align-items-center">
                    <a >
                      <i onClick={handleZoomOut} className="fal fa-search-minus"></i>
                    </a>
                  </span>
                </div>
              </Col>
              <Col md={12}>

                <div className={'mapBox'}>
                  {/*<GridCanvas />*/}
                  <div className={'xAxis'}>
                    {items.map(item =>
                        <>
                          <span  style={{ width: zoomLevel+'px' }}><b>{item}</b></span>
                        </>
                    )}
                  </div>
                  <div className={'yAxis'}>
                    {items.map(item => {
                      if (item <= 19) {
                        return (
                            <span style={{height: zoomLevel + 'px'}}>
          <b>{item}</b>
        </span>
                        );
                      } else {
                        return null; // Exclude the item from rendering
                      }
                    })}
                  </div>
                  {/*<GridCanvas />*/}
                 <EditableGrid setLoading={props.setLoading} addToConsole={addToConsole} color={color} data={mapData} zoomLevel={zoomLevel} undo={undo} draft={draft} onSubmit={next}/>
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Card>
              <Row className="p-4 user_data_profile">
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formBasicDec">
                    <Form.Label>Console Log</Form.Label>
                  </Form.Group>
                  <div style={{maxHeight: "300px", overflowY: "scroll"}}>
                    {consoleList && consoleList.reverse().map((a, index) => (
                        <p className="fw-light text-muted">{a.text}</p>
                    ))}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <hr />
          <Col md={12} className=" d-flex justify-content-end">
            <Button onClick={()=>saveLayerData('true')} variant={"outline-success"} className='me-2'>SAVE AS DRAFT</Button>
            <Button className='btn  btn-delete ' onClick={()=>saveLayerData('false')}>NEXT</Button>
          </Col>
        </Row>
      </div>

      }
    </>
  );
};

export default LayerFour;
