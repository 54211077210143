import CurrentOnlinePopUp from "Pages/Maps/CurrentOnlinePopUp";
import React, {useEffect, useState} from "react";
import { Button} from "react-bootstrap";

import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import {ENDPOINT} from "../../../config/constants";
import {toast} from "react-toastify";

const MapWinners = (props) => {
    const navigate = useNavigate();
    const supportTickets  = async (id,name) => {
        let data =
        {
            "subject": "Start Chat",
            "message": "Hi "+name,
            "user_id": id,
            "is_msg":true
        }

        await AuthService.postMethod(ENDPOINT.support.tickets, true,data)
            .then(async (res) => {
                navigate('/support');

            })
            .catch((err) => {
                toast("Error", `${AuthService.errorMessageHandler(err)}`, "error");
            });
    };


    const [modalShow, setModalShow] = React.useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data){
            setData(props.data)
        }
    }, [props.data]);
    const columns = [
        {
            name: <strong>Map Name</strong>,
            selector: row => row.name,

        },
        {
            name: <strong>Map Type</strong>,
            selector: row => row.map_type,

        },
        {
            name: <strong>Start Date</strong>,
            selector: row => row.start_date,

        },
        {
            name: <strong>End Date</strong>,
            selector: row => row.end_date,

        },
        {
            name: <strong>Current Players Online</strong>,
            selector: row => row.current_players_online,

            cell:row=>(<div className="CurrentPlayersOnline" onClick={() => {
                props.usersPopup(row.mapUsers)
            }}>
                <span className="dot"></span>{row.mapUsers?.length ?? 0}
            </div>),
        },
        {
            name: <strong>Stake Purchased</strong>,
            //selector: row => row.mapUsers?.reduce((acc, user) => acc + user.stake, 0),
            selector: row => row.boughtStakes,

        },

        {
            name: <strong>Winner</strong>,
            selector: row => row.user?.username,

        },
        {
            name: <strong>Action</strong>,
            selector: row => row.action,
            cell: row => (
                <Button variant="dropdownToggle" onClick={()=>supportTickets(row.user?.id,row.user?.username)}><i className={"fas fa-comment-dots fa-fw"}></i></Button>
            ),
        },



    ];
    return(
        <>
            <DataTable
                columns={columns}
                data={data}
                pagination
                striped
            />

            <CurrentOnlinePopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default MapWinners;