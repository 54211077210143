import React, {useEffect, useState} from 'react'
import user1 from "Images/user1.png";
import user2 from "Images/user2.png";
import user3 from "Images/user3.png";
import classes from "./index.module.scss";
import {imageUrl, timeSince} from "../../../Helper/Helpers";
import pdfImg from "Images/pdf.svg";

const SupportSideBar = (props) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setSelected(props.selectedChat)
    setCurrentItems(props.inbox?.data)
  },[props]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentItems(
        (props.inbox?.data).filter(
            (content) =>
                (content.user?.username).toLowerCase().includes(event.target.value.toLowerCase().trim())
        )
    );
  };
  return (
    <div className={classes.messageListSidebar}>
    <ul>
      {currentItems && currentItems.map((data) => (
      <li  onClick={props.ticketMessages.bind(null,data.id,data)} key={data.id} className={`${selected === data.id ? `${classes.active}` : ''}`}>
        <div className={classes.userImg}>
          <img src={imageUrl(data.user?.picture,user1)} style={{borderRadius: "50%", width:50, height:50}} alt="username" />
        </div>
        <div className={classes.description}>
          <h6>{`${data.user?.username}`}</h6>
          {data.message && <div className="text-muted">
            <time>{timeSince(Date.parse(data.message?.createdDate))}</time>
            {/*<div className={classes.text}>*/}
            {/*    {data.message?.message}*/}
            {/*</div>*/}
          </div>}

          {data.unread_message_count>0 && <span className={classes.counter}>{data.unread_message_count}</span>}
         </div>
      </li>
          ))}

    </ul>
  </div>
  )
}

export default SupportSideBar