import React, {useEffect, useState} from 'react';
import classes from "./index.module.scss";
import img from "Images/searchIcon.svg";
import data from "bootstrap/js/src/dom/data";

const Search = (props) => {
    const [searchText, setSearchText] = useState("");
        const search = (event) => {
          setSearchText(event.target.value)
        }
    useEffect(() => {
        const filteredData = props.data?.filter((item) => {
            return props.searchColumns.some((column) => {
                let columnValue = "";
                if (column.includes(".")) {
                    columnValue = item[column.split(".")[0]]?.[column.split(".")[1]] || "";
                } else {
                    columnValue = item[column] || "";
                }
                if (typeof columnValue === "object" && columnValue !== null) {
                    // handle related data
                    const relatedValue = columnValue.name || "";
                    return relatedValue.toString().toLowerCase().includes(searchText.toLowerCase());
                } else {
                    // handle regular data
                    return columnValue.toString().toLowerCase().includes(searchText.toLowerCase());
                }
            });
        });
        if (searchText !== "") {
            console.log('filteredData')
            console.log(filteredData)
            props.result(filteredData);
        } else {
            props.result(props.default);
        }
    }, [searchText]);
  return (
    <div className={classes.searcBox}>
      <input
        type="search"
        placeholder={props.placeholder}
        onChange={search}
      />
  </div>
  )
}

export default Search